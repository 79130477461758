import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { AuthApiUser } from '../user/types'
import { ApplicationState } from './reducer'
import { Application, AuthApiApplication } from './types'

export const getState = (state: RootState): ApplicationState =>
  state.application as ApplicationState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const getCoreApiApplications = (
  state: RootState,
): Application[] | null => getData(state).coreApiApplications

export const getAuthApiApplications = (
  state: RootState,
): AuthApiApplication[] | null =>
  getData(state).authApiApplications?.results || null

export const getAuthApiApplicationsByUser = (
  state: RootState,
  user: AuthApiUser,
): AuthApiApplication[] | null => {
  const authApiApplications = getAuthApiApplications(state)
  if (authApiApplications === null) {
    return null
  }
  return authApiApplications.filter(
    (application) => application.ownedByUserId === user.id,
  )
}
