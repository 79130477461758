import React, { useCallback, useEffect, useState } from 'react'

import { SearchBox } from '@pluggyai/ui'
import { Menu, MenuItemProps } from 'semantic-ui-react'

import { Applications } from '../Applications'
import { Page } from '../Page'
import { Teams } from '../Teams'
import { Users } from '../Users'
import { Props } from './ApplicationsPage.types'

type MenuItem = 'Applications' | 'Teams' | 'Users'

const menuItems: MenuItem[] = ['Teams', 'Users', 'Applications']

function getCurrentTab(activeItem: MenuItem) {
  if (activeItem === 'Applications') {
    return <Applications />
  }
  if (activeItem === 'Users') {
    return <Users />
  }
  return <Teams />
}

const ApplicationsPage = ({
  onFetchTeams,
  onFetchUsers,
  onFetchAuthApiApplications,
}: Props) => {
  const [activeItem, setActiveItem] = useState<MenuItem>(menuItems[0])
  const [searchValue, setSearchValue] = useState<string>('')
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  const handleItemClick = useCallback(
    (_e, { name }: MenuItemProps) => setActiveItem(name as MenuItem),
    [],
  )

  const searchField = shouldFetch ? searchValue : undefined

  // Teams
  useEffect(() => {
    onFetchTeams(searchField)
  }, [onFetchTeams, searchField])

  // Applications
  useEffect(() => {
    // TODO improve this fetch logic when list becomes too large?
    //  ie. paginate?
    onFetchAuthApiApplications(searchField)
  }, [onFetchAuthApiApplications, searchField])

  // Users
  useEffect(() => {
    // TODO improve this fetch logic when list becomes too large?
    //  ie. paginate?
    onFetchUsers(searchField)
  }, [onFetchUsers, searchField])

  // Have a backoff while typing to avoid calling multiple times the api
  const timeOutRef = React.useRef<number | null>(null)
  const handleInputChange = useCallback(
    (event) => {
      setSearchValue(event.target.value.trim())
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current)
      }
      timeOutRef.current = window.setTimeout(() => {
        setShouldFetch(true)
      }, 500)
    },
    [setSearchValue, setShouldFetch],
  )

  return (
    <Page className="ApplicationsPage">
      <SearchBox
        placeholder={'Search for a Team, User or Application...'}
        size="medium"
        value={searchValue}
        onChange={handleInputChange}
      />
      <Menu pointing secondary>
        {menuItems.map((menuItem) => (
          <Menu.Item
            key={menuItem}
            name={menuItem}
            active={activeItem === menuItem}
            onClick={handleItemClick}
          />
        ))}
      </Menu>
      {getCurrentTab(activeItem)}
    </Page>
  )
}

export default React.memo(ApplicationsPage)
