import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { FETCH_USERS_REQUEST } from '../../modules/user/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/user/selectors'
import Users from './Users'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Users.types'

const mapState = (state: RootState): MapStateProps => ({
  users: getData(state)?.results || [],
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), FETCH_USERS_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(Users)
