import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@pluggyai/ui'
import { Form } from 'semantic-ui-react'

import { TeamFields } from '../../../modules/team/types'
import { Props } from './TeamCreateForm.types'
import { TeamMembersEmailsInput } from './TeamMembersEmailsInput'

const TeamCreateForm = ({
  onSubmit,
  values,
  onFieldChange,
  errors,
  isLoading,
  isEditAllowed = true,
  fields,
  className,
}: Props) => {
  const { t } = useTranslation()

  const isFieldIncluded = useCallback(
    (field: keyof TeamFields) => !fields || fields.includes(field),
    [fields],
  )

  return (
    <Form className={className || 'TeamCreateForm'} onSubmit={onSubmit}>
      {isFieldIncluded('name') && (
        <Input
          label={t('team.form.field.name.label')}
          type="text"
          onChange={({ value }) => onFieldChange('name', value)}
          value={values.name}
          error={errors.name}
          disabled={isLoading || !isEditAllowed}
        />
      )}
      {isFieldIncluded('imageUrl') && (
        <Input
          label={t('team.form.field.imageUrl.label')}
          type="text"
          onChange={({ value }) => onFieldChange('imageUrl', value)}
          value={values.imageUrl}
          error={errors.imageUrl}
          disabled={isLoading || !isEditAllowed}
        />
      )}
      {isFieldIncluded('cnpj') && (
        <Input
          label={t('team.form.field.cnpj.label')}
          type="text"
          onChange={({ value }) => {
            const cnpjClean = value
              .replace(/[!@#$%^&*()_+,;a-zA-Z\s./-]/g, '')
              .slice(0, 14)
            onFieldChange('cnpj', cnpjClean)
          }}
          value={values.cnpj}
          error={errors.cnpj}
          disabled={isLoading}
          assistiveText={t('team.form.field.cnpj.assistiveText')}
        />
      )}
      {isFieldIncluded('members') && (
        <TeamMembersEmailsInput
          value={values.members}
          errors={errors.members}
          isSubmitting={isLoading}
          onFieldChange={onFieldChange}
        />
      )}
      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default React.memo(TeamCreateForm)
