import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { MoveApplicationToProductionState } from './reducer'

export const getState = (state: RootState): MoveApplicationToProductionState =>
  state.moveApplicationToProduction

export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)
