import { connect } from 'react-redux'

import {
  fetchAccessRequest,
  grantAccessRequest,
  revokeAccessRequest,
} from '../../../modules/access/actions'
import { getData as getAccess } from '../../../modules/access/selectors'
import { Access } from '../../../modules/access/types'
import { RootState } from '../../../modules/reducer'
import ApplicationItem from './ApplicationItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ApplicationItem.types'

const mapState = (
  state: RootState,
  { application }: OwnProps,
): MapStateProps => {
  const access: Access[] = Object.values(getAccess(state)).filter(
    (a) => a.clientId === application.id,
  )
  return {
    access,
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onGrantAccess: (email: string, clientId: string) =>
    dispatch(grantAccessRequest(email, clientId)),
  onFetchAccess: (clientId: string) => dispatch(fetchAccessRequest(clientId)),
  onRevokeAccess: (id: number) => dispatch(revokeAccessRequest(id)),
})

export default connect(mapState, mapDispatch)(ApplicationItem)
