import React from 'react'

import { AuthApiApplication } from '../../modules/application/types'
import { ListSection } from '../ListSection'
import { Props } from './Applications.types'
import { ApplicationItem } from './Item'

const Applications = ({
  isLoadingApplications,
  authApiApplications,
}: Props) => {
  return (
    <>
      {authApiApplications && (
        <ListSection
          data={authApiApplications}
          isLoading={isLoadingApplications && authApiApplications.length === 0}
          title={'Application'}
          renderValue={(application: AuthApiApplication) => (
            <ApplicationItem key={application.id} application={application} />
          )}
        />
      )}
    </>
  )
}

export default React.memo(Applications)
