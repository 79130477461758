import React, { useEffect } from 'react'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'semantic-ui-react'

import { locations } from '../../modules/routing/locations'
import { Props } from './Callback.types'

const Callback = ({
  rootLocation,
  isLoading,
  error,
  onAuth0Login,
  onAuth0Logout,
  auth,
}: Props) => {
  const history = useHistory()
  const {
    user: auth0User,
    error: auth0Error,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0()

  useEffect(() => {
    if (auth0User) {
      onAuth0Login(getIdTokenClaims, getAccessTokenSilently)
    }
  }, [onAuth0Login, auth0User, getIdTokenClaims, getAccessTokenSilently])

  if (error || auth0Error) {
    return (
      <div>
        <p>Login error: {error || auth0Error?.message}</p>
        <Button onClick={() => history.replace(locations.root())}>
          Go back
        </Button>
        {/* TODO check if this second button makes sense (I think there wouldn't be any account saved at all in this case?) */}
        <Button onClick={() => onAuth0Logout()}>Try another account</Button>
      </div>
    )
  }

  const { accessToken, apiKey } = auth || {}
  const isFullyAuthenticated = Boolean(accessToken) && Boolean(apiKey)

  if (!auth0User || !isFullyAuthenticated || isLoading) {
    return <div>Loading...</div>
  }

  // No error, user is present, fully authenticated, not loading -> Redirect to root location
  return <Redirect to={rootLocation} />
}

export default React.memo(Callback)
