import React, { useState, useCallback } from 'react'

import { Modal, Form, Button } from 'semantic-ui-react'

import { Props } from './GrantAccessModal.types'

import './GrantAccessModal.css'

const GrantAccessModal = (props: Props) => {
  const { trigger, onGrant, clientId } = props

  const [email, setEmail] = useState<string | undefined>('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const handleOnClick = useCallback((event: React.FormEvent<HTMLElement>) => {
    event.stopPropagation()
  }, [])

  const handleOnOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  const handleOnClose = useCallback(() => {
    setEmail(undefined)
    setIsModalOpen(false)
  }, [setEmail, setIsModalOpen])

  const handleOnSubmit = useCallback(() => {
    if (!email) {
      return
    }
    onGrant(email, clientId)
    handleOnClose()
  }, [email, handleOnClose, onGrant, clientId])

  return (
    <Modal
      trigger={trigger}
      open={isModalOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      className={'GrantAccessModal'}
      onClick={handleOnClick}
      size="mini"
      closeIcon
    >
      <Modal.Header>
        <span className="title">Add GSuite Email access</span>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={handleOnSubmit}>
            <Form.Field key={'email'}>
              <input
                required
                type={'text'}
                value={email || ''}
                onChange={(event) => setEmail(event.currentTarget.value)}
                placeholder={"Type the client's email"}
              />
            </Form.Field>

            <Button primary type="submit">
              Grant Access
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default React.memo(GrantAccessModal)
