import { AxiosResponse } from 'axios'

import {
  ApplicationShareLink,
  CreateApplicationShareLinkRequest,
  UpdateApplicationShareLinkRequest,
} from '../../../modules/application/types'
import { replaceUrlParams } from '../utils'
import { BaseService } from './BaseService'

export class ApplicationShareLinkService extends BaseService {
  async getApplicationsShareLinks(): Promise<
    AxiosResponse<ApplicationShareLink[]>
  > {
    return await this.getServiceInstance().get(
      this.urls.GET_APPLICATIONS_SHARE_LINKS,
    )
  }

  async createApplicationShareLink(
    applicationId: string,
    createApplicationShareLinkFields: CreateApplicationShareLinkRequest,
  ): Promise<AxiosResponse<ApplicationShareLink>> {
    const createApplicationShareLinkUrl = replaceUrlParams(
      this.urls.POST_APPLICATION_SHARE_LINK,
      { id: applicationId },
    )

    return await this.getServiceInstance().post(
      createApplicationShareLinkUrl,
      createApplicationShareLinkFields,
    )
  }

  async updateApplicationShareLink(
    applicationId: string,
    updateApplicationShareLinkFields: UpdateApplicationShareLinkRequest,
  ): Promise<AxiosResponse<ApplicationShareLink>> {
    const createApplicationShareLinkUrl = replaceUrlParams(
      this.urls.PATCH_APPLICATION_SHARE_LINK,
      { id: applicationId },
    )

    return await this.getServiceInstance().patch(
      createApplicationShareLinkUrl,
      updateApplicationShareLinkFields,
    )
  }
}
