import React from 'react'

import { Alert } from '@pluggyai/ui'
import { Grid, Header, Loader } from 'semantic-ui-react'

import { Section } from '../Section'
import { Props } from './ListSection.types'

import './ListSection.css'

// disabling eslint error as we need this generic to pass it to Props, for the data element.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ListSection = <T extends unknown>(props: Props<T>) => {
  const {
    className,
    data,
    isLoading,
    error,
    title,
    renderValue,
    NewComponentButton,
    SearchComponent,
    EmptyPlaceholder,
  } = props

  const hasData = data.length > 0
  const titlePluralized = `${data.length} ${
    data.length !== 1 ? `${title}s` : title
  }`

  return (
    <Section className={`ListSection ${className || ''}`}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <div className="list-header-container">
              <Header as="h2">{titlePluralized}</Header>
              {NewComponentButton}
              {hasData && SearchComponent}
            </div>
            {error !== null && error !== undefined && (
              <Alert size={'medium'} type={'error'} message={error} />
            )}
            {isLoading ? (
              <div className={'list-section-loader'}>
                <Loader size="big" active />
              </div>
            ) : (
              <div className={'list-content'}>
                {hasData
                  ? data.map((item, i) => renderValue(item, i))
                  : !error &&
                    (EmptyPlaceholder ? (
                      <div className={'list-item'}>{EmptyPlaceholder}</div>
                    ) : (
                      <div className="list-empty">
                        <div>
                          {`You don't have any ${title.toLowerCase()} yet, try creating one`}
                        </div>
                        {NewComponentButton}
                      </div>
                    ))}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default React.memo(ListSection) as typeof ListSection
