import { AxiosResponse } from 'axios'

import {
  Customization,
  UpdateCustomizationValues,
} from '../../../modules/customization/types'
import { BaseService } from './BaseService'

export class CustomizationsService extends BaseService {
  async getCustomization(
    teamId: string,
    includeTerms?: boolean,
  ): Promise<AxiosResponse<Customization[]>> {
    return await this.getServiceInstance().get(this.urls.GET_CUSTOMIZATION, {
      params: {
        teamId,
        includeTerms,
      },
    })
  }

  async updateCustomization(
    teamId: string,
    updateCustomizationFields: Partial<UpdateCustomizationValues>,
  ): Promise<AxiosResponse<Customization>> {
    return await this.getServiceInstance().patch(
      this.urls.PATCH_CUSTOMIZATION,
      updateCustomizationFields,
      {
        params: { teamId },
      },
    )
  }
}
