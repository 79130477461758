import type { ConnectorId } from '../../../lib/ConnectorId'

export const COMPONENT_STATUSES = [
  'operational',
  'degraded_performance',
  'partial_outage',
  'major_outage',
  'under_maintenance',
] as const
export type ComponentStatus = (typeof COMPONENT_STATUSES)[number]

export type IncidentComponent = {
  status: ComponentStatus
  name: string
  createdAt: string
  updatedAt: string
  position: number
  description: null | string
  showcase: boolean
  startDate: string
  id: string
  groupId: string | null
  connectorId: ConnectorId | null
}

type IncidentAffectedComponent = {
  code: string
  name: string
  oldStatus: ComponentStatus
  newStatus: ComponentStatus
  connectorId: ConnectorId | null
}

type IncidentUpdate = {
  id: string
  body: string
  status: string
  createdAt: string
  updatedAt: string
  affectedComponents: IncidentAffectedComponent[] | null
}

export const INCIDENT_IMPACT_VALUES = [
  'none',
  'minor',
  'major',
  'critical',
  'maintenance',
] as const

export type IncidentImpact = (typeof INCIDENT_IMPACT_VALUES)[number]

export const REALTIME_INCIDENT_STATUSES = [
  'investigating',
  'identified',
  'monitoring',
  'resolved',
] as const
export type RealtimeIncidentStatus = (typeof REALTIME_INCIDENT_STATUSES)[number]

/**
 * The incident status.
 * For realtime incidents, valid values are investigating, identified, monitoring, and resolved.
 * For scheduled incidents, valid values are scheduled, in_progress, verifying, and completed.
 */
type IncidentStatus =
  | RealtimeIncidentStatus
  | 'scheduled'
  | 'in_progress'
  | 'verifying'
  | 'completed'

export type Incident = {
  id: string
  name: string
  status: IncidentStatus
  impact: IncidentImpact
  incidentUpdates: IncidentUpdate[]
  createdAt: string
  updatedAt: string
  resolvedAt: string | null
  // startedAt is optional because sometimes PATCH request doesn't return it back
  startedAt?: string
  components: IncidentComponent[]
}

export type IncidentFields = {
  name: string
  // note: incident.status is not required. When creating an Incident, by default the Incident Impact gets calculated automatically.
  //  However, if we want to specify it manually, we can do so here.
  status: IncidentStatus | undefined
  body: string
  impact: IncidentImpact | undefined
  componentStatus: ComponentStatus | undefined
  deliverNotifications: boolean
}

export type IncidentFieldsErrors = {
  name?: string
  status?: string
  body?: string
  impact?: string
  componentStatus?: string
  deliverNotifications?: string
}
