import { connect } from 'react-redux'

import {
  auth0LoginRequest,
  auth0LogoutRequest,
} from '../../modules/auth/actions'
import { getAuth, getError, isLoggingIn } from '../../modules/auth/selectors'
import { RootState } from '../../modules/reducer'
import Callback from './Callback'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Callback.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoggingIn(state),
  auth: getAuth(state),
  error: getError(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onAuth0Login: (getIdTokenClaims, getAccessTokenSilently) =>
    dispatch(auth0LoginRequest(getIdTokenClaims, getAccessTokenSilently)),
  onAuth0Logout: () => dispatch(auth0LogoutRequest()),
})

export default connect(mapState, mapDispatch)(Callback)
