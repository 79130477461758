import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { CustomizationState } from './reducer'
import { Customization } from './types'

export const getState = (state: RootState): CustomizationState =>
  state.customization
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getCustomizationByTeamId = (
  state: RootState,
  teamId: string,
): Customization | null => getData(state)[teamId] || null
