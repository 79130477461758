import { connect } from 'react-redux'

import {
  UPDATE_CONNECTOR_REQUEST,
  updateConnectorRequest,
} from '../../../modules/connector/actions'
import { isLoadingTypeByConnectorId } from '../../../modules/connector/selectors'
import {
  Connector,
  UpdateConnectorRequest,
} from '../../../modules/connector/types'
import { getIncidentsByConnectorId } from '../../../modules/incident/selectors'
import { RootState } from '../../../modules/reducer'
import ConnectorItem from './ConnectorItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ConnectorItem.types'

const mapState = (
  state: RootState,
  { connector: { id: connectorId } }: OwnProps,
): MapStateProps => ({
  isLoading: isLoadingTypeByConnectorId(
    state,
    UPDATE_CONNECTOR_REQUEST,
    connectorId,
  ),
  incidents: getIncidentsByConnectorId(state, Number(connectorId)),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateConnector: (
    connector: Connector,
    updateConnectorFields: UpdateConnectorRequest,
  ) => dispatch(updateConnectorRequest(connector, updateConnectorFields)),
})

export default connect(mapState, mapDispatch)(ConnectorItem)
