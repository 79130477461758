const { REACT_APP_CORE_API_URL: apiBaseUrl } = process.env

if (apiBaseUrl === undefined || apiBaseUrl.length < 4) {
  throw new Error('Must define REACT_APP_CORE_API_URL env var as a valid URL')
}

export type UrlsMap = {
  GET_APPLICATION: string
  POST_APPLICATION: string
  PATCH_APPLICATION: string
  GET_ACCESS: string
  DELETE_ACCESS: string
  POST_ACCESS: string

  // Connectors
  GET_CONNECTORS: string
  PATCH_CONNECTOR: string
  PATCH_CONNECTORS: string

  // Items
  GET_ITEM: string
  PATCH_ITEM: string
}

export const urls: UrlsMap = {
  GET_APPLICATION: `${apiBaseUrl}/clients/:id`,
  POST_APPLICATION: `${apiBaseUrl}/clients`,
  PATCH_APPLICATION: `${apiBaseUrl}/clients/:id`,
  GET_ACCESS: `${apiBaseUrl}/microauth`,
  DELETE_ACCESS: `${apiBaseUrl}/microauth/:id`,
  POST_ACCESS: `${apiBaseUrl}/microauth`,
  // Connectors
  GET_CONNECTORS: `${apiBaseUrl}/connectors`,
  PATCH_CONNECTOR: `${apiBaseUrl}/connectors/:id`,
  PATCH_CONNECTORS: `${apiBaseUrl}/connectors`,

  // Items
  GET_ITEM: `${apiBaseUrl}/items/:id`,
  PATCH_ITEM: `${apiBaseUrl}/items/:id`,
}
