import React from 'react'

import Icon from '../Icon'

import './CloseIcon.css'

type Props = {
  onClick?: () => void
}

const CloseIcon = ({ onClick }: Props) => (
  <Icon
    className={'CloseIcon close link'}
    onClick={onClick}
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#292828">
        <g>
          <path
            d="M15.057 0L8 7.057 0.943 0 0 0.943 7.057 8 0 15.057 0.943 16 8 8.943 15.057 16 16 15.057 8.943 8 16 0.943z"
            transform="translate(-280 -24) translate(280 24)"
          />
        </g>
      </g>
    </g>
  </Icon>
)

export default React.memo(CloseIcon)
