import { connect } from 'react-redux'

import { FETCH_AUTH_API_APPLICATIONS_REQUEST } from '../../modules/application/actions'
import {
  getAuthApiApplications,
  getAuthApiApplicationsByUser,
  getCoreApiApplications,
  getError,
  getLoading,
  isLoadingType as isApplicationLoadingType,
} from '../../modules/application/selectors'
import { Application } from '../../modules/application/types'
import { isUpgradeApplicationAllowed } from '../../modules/auth/selectors'
import {
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST,
  submitMoveApplicationToProductionRequest,
} from '../../modules/moveApplicationToProduction/actions'
import { isLoadingType as isMoveApplicationToProductionLoadingType } from '../../modules/moveApplicationToProduction/selectors'
import { RootState } from '../../modules/reducer'
import ApplicationsTable from './ApplicationsTable'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ApplicationsTable.types'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  const { user, team } = ownProps

  return {
    applications: user
      ? // filter by specified user
        getAuthApiApplicationsByUser(state, user)
      : team
      ? // filter by specified team
        team.applications
      : // no filtering, display all
        getAuthApiApplications(state),

    coreApiApplications: getCoreApiApplications(state),
    error: getError(state),
    isLoading: isApplicationLoadingType(
      getLoading(state),
      FETCH_AUTH_API_APPLICATIONS_REQUEST,
    ),
    isMoveApplicationToProductionLoading:
      isMoveApplicationToProductionLoadingType(
        getLoading(state),
        SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST,
      ),
    isUpgradeApplicationAllowed: isUpgradeApplicationAllowed(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onMoveApplicationToProduction: (application: Application) =>
    dispatch(submitMoveApplicationToProductionRequest({ application })),
})

export default connect(mapState, mapDispatch)(ApplicationsTable)
