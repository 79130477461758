import { connect } from 'react-redux'

import { updateTeamRequest } from '../../../modules/team/actions'
import { Team, UpdateTeamRequest } from '../../../modules/team/types'
import UpdateTeamModal from './TeamUpdateModal'
import { MapDispatch, MapDispatchProps } from './TeamUpdateModal.types'

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdate: (
    team: Team,
    updateValues: UpdateTeamRequest,
    updateReason: string,
  ) => dispatch(updateTeamRequest(team, updateValues, updateReason)),
})

export default connect(null, mapDispatch)(UpdateTeamModal)
