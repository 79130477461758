import React from 'react'

import { Props } from './Section.types'

import './Section.css'

const Section = (props: Props) => {
  const { className, children } = props

  const classNames = `Section ${className !== undefined ? className : ''}`

  return <div className={classNames}>{children}</div>
}

export default React.memo(Section)
