import {
  FREE_PRODUCTS,
  FULL_PRODUCTS,
  ProductType,
} from '../../../modules/team/types'

export function validateUpdateReasonField(updateReason: string): void {
  if (updateReason.length === 0) {
    // required
    throw new Error('Please specify reason for this change')
  }
  if (updateReason.length < 6) {
    throw new Error('Update reason should be at least 6 characters')
  }
  if (updateReason.length > 300) {
    throw new Error('Update reason should be shorter than 300 characters')
  }
}

export function getProductPlanTag(
  product: ProductType,
): 'Free' | 'Full' | 'Extra' {
  if (FREE_PRODUCTS.includes(product)) {
    return 'Free'
  }
  if (FULL_PRODUCTS.includes(product)) {
    return 'Full'
  }
  return 'Extra'
}
