import React, { useCallback, useState } from 'react'

import { Popup } from '@pluggyai/ui'
import { addDays, isAfter, isWithinInterval } from 'date-fns'
import { Button, Grid, Segment } from 'semantic-ui-react'

import { formatDate } from '../../../lib/utils/date'
import { FREE_TRIAL_MAXIMUM_EXPIRED_RECENTLY_DAYS } from '../../../modules/team/types'
import { mapSubscriptionToString } from '../../../modules/team/utils'
import { ApplicationsTable } from '../../ApplicationsTable'
import { TeamUpdateModal } from '../TeamUpdateModal'
import { TeamUpdateTermsModal } from '../TeamUpdateTermsModal'
import { FreeTrialExpiresAtMessage } from './FreeTrialExpiresAtMessage'
import { Props } from './TeamListItem.types'
import { TeamMembers } from './TeamMembers'
import { buildDashboardTeamImpersonateLink } from './utils'

import './TeamListItem.css'

const TeamListItem = ({ team, isImpersonateAllowed }: Props) => {
  const { id, name, subscription } = team

  const [displayMembersList, setDisplayMembersList] = useState<boolean>(false)

  const handleMembersListToggle = useCallback(() => {
    setDisplayMembersList((value) => !value)
  }, [])

  const [displayApplicationsList, setDisplayApplicationsList] =
    useState<boolean>(false)

  const handleApplicationsListToggle = useCallback(() => {
    setDisplayApplicationsList((value) => !value)
  }, [])

  const [teamUpdateModalOpen, setTeamUpdateModalOpen] = useState<boolean>(false)
  const handleTeamUpdateModalOpen = useCallback(() => {
    setTeamUpdateModalOpen(true)
  }, [])
  const handleTeamUpdateModalClose = useCallback(() => {
    setTeamUpdateModalOpen(false)
  }, [])

  const [teamUpdateTermsModalOpen, setTeamUpdateTermsModalOpen] =
    useState(false)

  const handleTeamUpdateTermsModalOpen = useCallback(() => {
    setTeamUpdateTermsModalOpen(true)
  }, [])

  const handleTeamUpdateTermsModalClose = useCallback(() => {
    setTeamUpdateTermsModalOpen(false)
  }, [])

  const { freeTrialExpiresAt, extraFreeTrialExpiresAt } = subscription

  let freeTrialExpiresAtDate: Date | undefined

  if (freeTrialExpiresAt) {
    freeTrialExpiresAtDate = new Date(freeTrialExpiresAt)
  }

  if (extraFreeTrialExpiresAt) {
    freeTrialExpiresAtDate = new Date(extraFreeTrialExpiresAt)
  }

  const isFreeTrialActive =
    subscription.plan === 'TRIAL' &&
    Boolean(
      freeTrialExpiresAtDate && isAfter(freeTrialExpiresAtDate, Date.now()),
    )

  const isFreeTrialExpiredRecently =
    (subscription.plan === 'FREE' || subscription.plan === 'FREE_PLUS') &&
    Boolean(
      freeTrialExpiresAtDate &&
        isWithinInterval(Date.now(), {
          start: freeTrialExpiresAtDate,
          end: addDays(
            freeTrialExpiresAtDate,
            FREE_TRIAL_MAXIMUM_EXPIRED_RECENTLY_DAYS,
          ),
        }),
    )

  const isFreeTrialActiveOrExpiredRecently =
    isFreeTrialActive || isFreeTrialExpiredRecently

  return (
    <Segment className={'TeamListItem'} data-id={id}>
      <Grid columns={5} stackable>
        <Grid.Row>
          {/* Name | id */}
          <Grid.Column width={4}>
            <span className={'title'}>{name}</span>
            <br />
            <span className={'id'}>{id}</span>
          </Grid.Column>

          {/* Subscription */}
          <Grid.Column width={6}>
            Subscription: <br />
            {mapSubscriptionToString(subscription)}
            {freeTrialExpiresAtDate && isFreeTrialActiveOrExpiredRecently && (
              <>
                <br />
                <FreeTrialExpiresAtMessage expiresAt={freeTrialExpiresAtDate} />
              </>
            )}
          </Grid.Column>

          {/* Created At | Updated At */}
          <Grid.Column width={3}>
            Created at:
            <br />
            {formatDate(team.createdAt)}
            <br />
            Updated at:
            <br />
            {formatDate(team.updatedAt)}
          </Grid.Column>

          {/* Actions */}
          <Grid.Column width={3}>
            <Button primary size="mini" onClick={handleTeamUpdateModalOpen}>
              Edit Subscription
            </Button>
            <Button
              primary
              size="mini"
              onClick={handleTeamUpdateTermsModalOpen}
            >
              Edit Terms/Privacy texts
            </Button>
            <Button
              primary
              inverted
              size="mini"
              onClick={handleMembersListToggle}
            >
              {displayMembersList ? 'Hide Members' : 'View Members'}
            </Button>
            <Button
              primary
              inverted
              size="mini"
              onClick={handleApplicationsListToggle}
            >
              {displayApplicationsList
                ? 'Hide Applications'
                : 'View Applications'}
            </Button>
            <Popup
              className={'impersonate-link-popup'}
              position={'top center'}
              content={'Not enough permissions for this action'}
              disabled={isImpersonateAllowed}
              /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
                      related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
              trigger={
                <div className={'impersonate-link-popup-wrapper'}>
                  <a
                    className={`link ${isImpersonateAllowed ? '' : 'disabled'}`}
                    href={
                      isImpersonateAllowed
                        ? buildDashboardTeamImpersonateLink(id)
                        : undefined
                    }
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Impersonate
                  </a>
                </div>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* Team-related data */}
      {teamUpdateModalOpen && (
        <TeamUpdateModal team={team} onClose={handleTeamUpdateModalClose} />
      )}
      {displayMembersList && <TeamMembers team={team} />}
      {displayApplicationsList && <ApplicationsTable team={team} />}

      {teamUpdateTermsModalOpen && (
        <TeamUpdateTermsModal
          team={team}
          onClose={handleTeamUpdateTermsModalClose}
        />
      )}
    </Segment>
  )
}

export default React.memo(TeamListItem)
