import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, FormField } from '@pluggyai/ui'
import { Form } from 'semantic-ui-react'

import { CustomizationFields } from '../../../modules/customization/types'
import { Props } from './TeamUpdateTermsForm.types'
import { TextInputOptional } from './TextInputOptional'

const TeamUpdateTermsForm = ({
  onSubmit,
  values,
  initialValues,
  onFieldChange,
  errors,
  isLoading,
  isEditAllowed = true,
  fields,
  className,
}: Props) => {
  const { t } = useTranslation()

  const isFieldIncluded = useCallback(
    (field: keyof CustomizationFields) => !fields || fields.includes(field),
    [fields],
  )

  return (
    <Form
      className={`TeamUpdateTermsForm ${className || ''}`}
      onSubmit={onSubmit}
    >
      <Alert
        size={'medium'}
        type={'info'}
        message={
          'Note: Terms and Conditions/Privacy Policy texts should be provided in HTML format.'
        }
      />
      {isFieldIncluded('termsAndConditions') && (
        <FormField>
          <label>
            {t('customization.form.field.termsAndConditions.label')}
          </label>
          <TextInputOptional
            label={t('customization.form.field.termsAndConditions.label')}
            onChange={({ value }) => onFieldChange('termsAndConditions', value)}
            value={values.termsAndConditions}
            initialValue={initialValues?.termsAndConditions}
            error={errors.termsAndConditions}
            disabled={isLoading || !isEditAllowed}
          />
        </FormField>
      )}

      {isFieldIncluded('privacyPolicy') && (
        <FormField>
          <label>{t('customization.form.field.privacyPolicy.label')}</label>
          <TextInputOptional
            label={t('customization.form.field.privacyPolicy.label')}
            onChange={({ value }) => onFieldChange('privacyPolicy', value)}
            value={values.privacyPolicy}
            initialValue={initialValues?.privacyPolicy}
            error={errors.privacyPolicy}
            disabled={isLoading || !isEditAllowed}
          />
        </FormField>
      )}
      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}

export default React.memo(TeamUpdateTermsForm)
