import { AxiosResponse } from 'axios'

import {
  CreateIncidentRequest,
  UpdateIncidentRequest,
} from '../../../lib/client/statusPage/types'
import { Incident } from '../../modules/incident/types'
import { FilterableStats } from '../../modules/stats/types'
import { BaseService } from './BaseService'
import { objectToQuerystring } from './utils'

const { REACT_APP_BACKOFFICE_API_URL: backofficeApiUrl = '' } = process.env

const VERCEL_API_INCIDENTS = `${backofficeApiUrl}/api/incidents`

class IncidentsService extends BaseService {
  async getIncidents(
    params?: FilterableStats,
  ): Promise<AxiosResponse<Incident[]>> {
    return this.getServiceInstance().get(
      `${VERCEL_API_INCIDENTS}${objectToQuerystring(params)}`,
    )
  }

  async createIncident(values: CreateIncidentRequest): Promise<Incident> {
    const { data: incident } = await this.getServiceInstance().post<Incident>(
      VERCEL_API_INCIDENTS,
      values,
    )

    return incident
  }

  async updateIncident(values: UpdateIncidentRequest): Promise<Incident> {
    const { data: incident } = await this.getServiceInstance().patch<Incident>(
      VERCEL_API_INCIDENTS,
      values,
    )

    return incident
  }
}

export const incidentsService = new IncidentsService()
