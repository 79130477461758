import { action } from 'typesafe-actions'

import { Item, UpdateItemRequest } from './types'

/// Fetch Items request

export const FETCH_ITEM_REQUEST = '[Request] Fetch Item'
export const FETCH_ITEM_SUCCESS = '[Success] Fetch Item'
export const FETCH_ITEM_FAILURE = '[Failure] Fetch Item'

export const fetchItemRequest = (id: string) =>
  action(FETCH_ITEM_REQUEST, { id })
export const fetchItemSuccess = (item: Item) =>
  action(FETCH_ITEM_SUCCESS, { item })
export const fetchItemFailure = (itemId: string, error: string) =>
  action(FETCH_ITEM_FAILURE, { itemId, error })

export type FetchItemRequestAction = ReturnType<typeof fetchItemRequest>
export type FetchItemSuccessAction = ReturnType<typeof fetchItemSuccess>
export type FetchItemFailureAction = ReturnType<typeof fetchItemFailure>

/// Update Item request

export const UPDATE_ITEM_REQUEST = '[Request] Update Item'
export const UPDATE_ITEM_SUCCESS = '[Success] Update Item'
export const UPDATE_ITEM_FAILURE = '[Failure] Update Item'

export const updateItemRequest = (
  item: Item,
  updateItemFields: UpdateItemRequest,
) => action(UPDATE_ITEM_REQUEST, { item, updateItemFields })
export const updateItemSuccess = (item: Item) =>
  action(UPDATE_ITEM_SUCCESS, { item })
export const updateItemFailure = (error: string) =>
  action(UPDATE_ITEM_FAILURE, { error })

export type UpdateItemRequestAction = ReturnType<typeof updateItemRequest>
export type UpdateItemSuccessAction = ReturnType<typeof updateItemSuccess>
export type UpdateItemFailureAction = ReturnType<typeof updateItemFailure>

// Clear Item error
export const CLEAR_ITEM_ERROR = '[Request] Clear Item Item error'
export const clearItemError = () => action(CLEAR_ITEM_ERROR)
export type ClearItemErrorAction = ReturnType<typeof clearItemError>

/// Poll Item
export const POLL_ITEM_START = '[Start] Polling item'
export const POLL_ITEM_STOP = '[Stop] Polling item'

export const startPollingItem = (itemId: string) =>
  action(POLL_ITEM_START, {
    itemId,
  })

export const stopPollingItem = (itemId: string) =>
  action(POLL_ITEM_STOP, { itemId })

export type StartPollingItemAction = ReturnType<typeof startPollingItem>
export type StopPollingItemAction = ReturnType<typeof stopPollingItem>
