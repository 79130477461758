import { action } from 'typesafe-actions'

import { ConnectorId } from '../../../lib/ConnectorId'
import { Incident, IncidentFields } from './types'

/*
 * Fetch Incidents
 */

export const FETCH_INCIDENTS_REQUEST = '[Request] Fetch Incidents'
export const FETCH_INCIDENTS_SUCCESS = '[Success] Fetch Incidents'
export const FETCH_INCIDENTS_FAILURE = '[Failure] Fetch Incidents'

export const fetchIncidentsRequest = () => action(FETCH_INCIDENTS_REQUEST)
export const fetchIncidentsSuccess = (incidents: Incident[]) =>
  action(FETCH_INCIDENTS_SUCCESS, { incidents })
export const fetchIncidentsFailure = (error: string) =>
  action(FETCH_INCIDENTS_FAILURE, { error })

export type FetchIncidentsRequestAction = ReturnType<
  typeof fetchIncidentsRequest
>
export type FetchIncidentsSuccessAction = ReturnType<
  typeof fetchIncidentsSuccess
>
export type FetchIncidentsFailureAction = ReturnType<
  typeof fetchIncidentsFailure
>

/*
 * Create Incident
 */

export const CREATE_INCIDENT_REQUEST = '[Request] Create Incident'
export const CREATE_INCIDENT_SUCCESS = '[Success] Create Incident'
export const CREATE_INCIDENT_FAILURE = '[Failure] Create Incident'

export const createIncidentRequest = (
  incidentValues: IncidentFields,
  connectorId: ConnectorId,
) => action(CREATE_INCIDENT_REQUEST, { incidentValues, connectorId })
export const createIncidentSuccess = (incident: Incident) =>
  action(CREATE_INCIDENT_SUCCESS, { incident })
export const createIncidentFailure = (error: string) =>
  action(CREATE_INCIDENT_FAILURE, { error })

export type CreateIncidentRequestAction = ReturnType<
  typeof createIncidentRequest
>
export type CreateIncidentSuccessAction = ReturnType<
  typeof createIncidentSuccess
>
export type CreateIncidentFailureAction = ReturnType<
  typeof createIncidentFailure
>

/*
 * Update Incident
 */

export const UPDATE_INCIDENT_REQUEST = '[Request] Update Incident'
export const UPDATE_INCIDENT_SUCCESS = '[Success] Update Incident'
export const UPDATE_INCIDENT_FAILURE = '[Failure] Update Incident'

export const updateIncidentRequest = (
  incident: Incident,
  incidentValues: IncidentFields,
  connectorId: ConnectorId,
) => action(UPDATE_INCIDENT_REQUEST, { incident, incidentValues, connectorId })
export const updateIncidentSuccess = (incident: Incident) =>
  action(UPDATE_INCIDENT_SUCCESS, { incident })
export const updateIncidentFailure = (error: string) =>
  action(UPDATE_INCIDENT_FAILURE, { error })

export type UpdateIncidentRequestAction = ReturnType<
  typeof updateIncidentRequest
>
export type UpdateIncidentSuccessAction = ReturnType<
  typeof updateIncidentSuccess
>
export type UpdateIncidentFailureAction = ReturnType<
  typeof updateIncidentFailure
>
