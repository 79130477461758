import React, { useCallback, useMemo, useState } from 'react'

import {
  Alert,
  Button,
  CheckboxProps,
  Divider,
  Form,
  Header,
  Input,
  Radio,
} from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { isAfter, isBefore } from 'date-fns'
import { FormField, Grid, Modal } from 'semantic-ui-react'

import { toTitleCase } from '../../../lib/utils/string'
import {
  DEFAULT_SUBSCRIPTION_UPDATE_FREQUENCY,
  FEATURE_TYPES,
  FeatureType,
  FREE_FEATURES,
  FREE_PRODUCTS,
  FULL_FEATURES,
  FULL_PRODUCTS,
  isFeatureType,
  isProductType,
  isSubscriptionPlan,
  PaymentInitiationCredentials,
  PRODUCT_TYPES,
  ProductType,
  Subscription,
  TRIAL_SUBSCRIPTION,
  UPDATE_FREQUENCY_OPTIONS,
} from '../../../modules/team/types'
import { CloseIcon } from '../../Icon'
import { FreeTrialExpiresAtMessage } from '../TeamItem/FreeTrialExpiresAtMessage'
import { FreeTrialField } from './FreeTrialField'
import { Props } from './TeamUpdateModal.types'
import { getProductPlanTag, validateUpdateReasonField } from './utils'

import './TeamUpdateModal.css'

function mapUpdateFrequencyToOption(value = 0): Option {
  return {
    id: String(value),
    name: value === 0 ? 'Never' : String(value),
  }
}

const subscriptionUpdateFrequencyOptions: Option[] =
  UPDATE_FREQUENCY_OPTIONS.map(mapUpdateFrequencyToOption)

const TeamUpdateModal = ({ team, onUpdate, onClose }: Props) => {
  const initialSubscription = useMemo<Subscription>(() => {
    // deep clone of team.subscription
    return JSON.parse(JSON.stringify(team.subscription))
  }, [team])

  const initialPaymentInitiationCredentials =
    useMemo<PaymentInitiationCredentials>(() => {
      // deep clone of team.integrations
      if (team.integrations?.iniciadorPayments) {
        return JSON.parse(JSON.stringify(team.integrations.iniciadorPayments))
      }
      return { clientId: '', clientSecret: '' }
    }, [team])

  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription>(initialSubscription)

  const [paymentInitiationCredentials, setPaymentInitiationCredentials] =
    useState<PaymentInitiationCredentials>(initialPaymentInitiationCredentials)

  const [iniciadorCredentials, setIniciadorCredentials] = useState<
    string | undefined
  >(undefined)
  const [updateReason, setUpdateReason] = useState<string>('')
  const [formErrors, setFormErrors] = useState<{
    updateReason?: string
  }>({
    updateReason: undefined,
  })

  const handleUpdateFrequencyInHoursChange = useCallback(
    ({ value }: { value: Option | Option[] }) => {
      const valueAsNumber = Number((value as Option).id)

      setCurrentSubscription((previous) => ({
        ...previous,
        updateFrequencyInHours: valueAsNumber,
      }))
    },
    [],
  )

  const handleUpdatePaymentInitiationClientIdChange = useCallback(
    ({ value }: { value: string }) => {
      setPaymentInitiationCredentials((previous) => ({
        ...previous,
        clientId: value,
      }))
    },
    [],
  )
  const handleUpdatePaymentInitiationClientSecretChange = useCallback(
    ({ value }: { value: string }) => {
      setPaymentInitiationCredentials((previous) => ({
        ...previous,
        clientSecret: value,
      }))
    },
    [],
  )

  const handleUpdateReasonChange = useCallback(
    ({ value }: { value: string }) => {
      setFormErrors((previous) => {
        previous.updateReason = undefined
        return previous
      })
      setUpdateReason(value)
    },
    [],
  )

  const handleIniciadorCredentialsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const [file] = event.target.files
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const encryptedIniciadorCredentials = reader.result
          setIniciadorCredentials(encryptedIniciadorCredentials as string)
        }
      }
    },
    [],
  )

  const initialFreeTrialExpiresAtDate = initialSubscription.freeTrialExpiresAt
    ? new Date(initialSubscription.freeTrialExpiresAt)
    : null

  const [currentFreeTrialExpiresAt, setCurrentFreeTrialExpiresAt] =
    useState<Date | null>(initialFreeTrialExpiresAtDate)

  const isInitialFreeTrialActive =
    initialSubscription.plan === 'TRIAL' &&
    Boolean(
      initialFreeTrialExpiresAtDate &&
        isAfter(initialFreeTrialExpiresAtDate, Date.now()),
    )

  const isInitialFreeTrialExpired =
    initialSubscription.plan === 'FREE' &&
    Boolean(
      initialFreeTrialExpiresAtDate &&
        isBefore(initialFreeTrialExpiresAtDate, Date.now()),
    )

  const handleSubmit = useCallback(() => {
    try {
      validateUpdateReasonField(updateReason.trim())
    } catch (error) {
      setFormErrors({ updateReason: error.message })
      return
    }

    // reorder to maintain the same order as the PRODUCT_TYPES array
    currentSubscription.products.sort(
      (a, b) => PRODUCT_TYPES.indexOf(a) - PRODUCT_TYPES.indexOf(b),
    )

    // reorder to maintain the same order as the FEATURE_TYPES array
    currentSubscription.features.sort(
      (a, b) => FEATURE_TYPES.indexOf(a) - FEATURE_TYPES.indexOf(b),
    )

    onUpdate(
      team,
      {
        subscription: currentSubscription,
        iniciadorCredentials,
        integrations: {
          iniciadorPayments: paymentInitiationCredentials,
        },
      },
      updateReason,
    )
    onClose()
  }, [
    currentSubscription,
    onUpdate,
    team,
    iniciadorCredentials,
    paymentInitiationCredentials,
    updateReason,
    onClose,
  ])

  const isFullSubscriptionSelected = currentSubscription.plan === 'FULL'
  const isFreeSubscriptionSelected = currentSubscription.plan === 'FREE'

  const handleSubscriptionProductChange = useCallback(
    (value: string | number) => {
      if (!isProductType(value)) {
        throw new Error(`Selected value: ${value} is not a valid product`)
      }

      const { products } = currentSubscription

      // if value is present in products, remove it; otherwise, add it
      const updatedProducts: ProductType[] = products.includes(value)
        ? // remove
          products.filter((product) => product !== value)
        : // add value
          [...products, value]

      setCurrentSubscription((previous) => ({
        ...previous,
        products: updatedProducts,
      }))
    },
    [currentSubscription],
  )

  const handleSubscriptionPlanChange = useCallback(
    (plan: string | number, expiresAtDate?: Date) => {
      if (!isSubscriptionPlan(plan)) {
        throw new Error('Selected value is not a valid subscription plan')
      }

      if (plan !== 'FULL' && plan !== 'FREE' && plan !== 'TRIAL') {
        throw new Error(`Selected plan ${plan} is not supported`)
      }

      if (plan === 'FULL') {
        // 'FULL' -> enable also FULL products and features
        setCurrentSubscription((previous): Subscription => {
          const fullExclusiveProducts = FULL_PRODUCTS.filter(
            (fullProduct) => !FREE_PRODUCTS.includes(fullProduct),
          )

          // add FULL exclusive products to previous.products
          const updatedProducts: ProductType[] = [
            ...new Set([...previous.products, ...fullExclusiveProducts]),
          ]
          // add FULL exclusive features to previous.features
          const updatedFeatures: FeatureType[] = [
            ...new Set([...previous.features, ...FULL_FEATURES]),
          ]

          return {
            ...previous,
            plan,
            products: updatedProducts,
            features: updatedFeatures,
          }
        })
        return
      }

      if (plan === 'FREE') {
        // 'FREE' has only free products enabled
        setCurrentSubscription((previous): Subscription => {
          // keep only FREE products from previous.products
          const updatedProducts: ProductType[] = previous.products.filter(
            (product) => FREE_PRODUCTS.includes(product),
          )
          // keep only FREE features from previous.features
          const updatedFeatures: FeatureType[] = previous.features.filter(
            (feature) => FREE_FEATURES.includes(feature),
          )

          return {
            ...previous,
            products: updatedProducts,
            features: updatedFeatures,
            plan,
            updateFrequencyInHours: DEFAULT_SUBSCRIPTION_UPDATE_FREQUENCY,
          }
        })
        return
      }

      const hasPaymentInitiationFeature =
        currentSubscription.features.includes('PAYMENT_INITIATION')

      const hasBulkPaymentsFeature =
        currentSubscription.features.includes('BULK_PAYMENTS')

      const extraFeautures: FeatureType[] = []

      if (hasPaymentInitiationFeature) {
        extraFeautures.push('PAYMENT_INITIATION')
      }

      if (hasBulkPaymentsFeature) {
        extraFeautures.push('BULK_PAYMENTS')
      }

      // 'TRIAL' has all products enabled
      setCurrentSubscription((previous) => ({
        ...previous,
        ...TRIAL_SUBSCRIPTION(
          expiresAtDate ? new Date(expiresAtDate) : new Date(),
          extraFeautures,
        ),
      }))
    },
    [currentSubscription.features],
  )

  const handleFreeTrialExpiresAtChange = useCallback(
    (expiresAtDate: Date) => {
      setCurrentFreeTrialExpiresAt(expiresAtDate)
      handleSubscriptionPlanChange('TRIAL', expiresAtDate)
    },
    [handleSubscriptionPlanChange],
  )

  const handleSubcriptionProductionChange = useCallback(
    (production: boolean) => {
      setCurrentSubscription((previous) => ({
        ...previous,
        production,
      }))
    },
    [],
  )

  const handleSubscriptionFeatureChange = useCallback(
    (value: number | string) => {
      if (!isFeatureType(value)) {
        throw new Error('Selected value is not a valid subscription feature')
      }

      const { features } = currentSubscription

      // if value is present in features, remove it; otherwise, add it
      const updatedFeatures: FeatureType[] = features.includes(value)
        ? // remove
          features.filter((feature) => feature !== value)
        : // add value
          [...features, value]

      setCurrentSubscription((previous) => ({
        ...previous,
        features: updatedFeatures,
      }))
    },
    [currentSubscription],
  )

  const handleSubscriptionFormRadioChange = useCallback(
    (
      _e: React.FormEvent<HTMLInputElement>,
      { value, name, checked }: CheckboxProps,
    ) => {
      if (name === 'plan' && value) {
        handleSubscriptionPlanChange(value)
        return
      }

      if (name === 'product' && value) {
        handleSubscriptionProductChange(value)
        return
      }

      if (name === 'production' && typeof checked !== 'undefined') {
        handleSubcriptionProductionChange(checked)
        return
      }

      if (name === 'feature' && value) {
        handleSubscriptionFeatureChange(value)
        return
      }
    },
    [
      handleSubcriptionProductionChange,
      handleSubscriptionFeatureChange,
      handleSubscriptionPlanChange,
      handleSubscriptionProductChange,
    ],
  )

  const handleUpdateSubscriptionPricingChange = useCallback(
    (
      { value }: { value: string },
      pricingField: 'pixIn' | 'pixOut' | 'boletoOut',
      parentField: 'pluggyPricing' | 'customerPricing',
    ) => {
      setCurrentSubscription((previous) => {
        const updatedPaymentsConfiguration = {
          ...previous.paymentsConfiguration,
          [parentField]: {
            ...previous.paymentsConfiguration?.[parentField],
            [pricingField]: Number(value),
          },
        }

        return {
          ...previous,
          paymentsConfiguration: updatedPaymentsConfiguration,
        }
      })
    },
    [],
  )

  return (
    <Modal
      className={'TeamUpdateModal'}
      onClose={onClose}
      open={true}
      size="small"
      closeIcon={<CloseIcon />}
    >
      <Modal.Header>
        <div className={'title'}>Update details of the team</div>
        <div className={'subtitle'}>
          Here we can manage the different subscriptions options
        </div>
        <div className={'team-details'}>
          <p>
            <strong>Team Name: </strong>
            {team.name}
          </p>
          <p>
            <strong>Team ID: </strong>
            {team.id}
          </p>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form className={'team-subscription-form'} onSubmit={handleSubmit}>
            <Form.Group>
              <Header>Subscription Plan</Header>
              {/* Free base option */}
              <FormField>
                <Radio
                  label={
                    <label>
                      Free
                      {initialFreeTrialExpiresAtDate &&
                        isInitialFreeTrialExpired && (
                          <span className={'free-trial-message-container'}>
                            {'- '}
                            <FreeTrialExpiresAtMessage
                              expiresAt={initialFreeTrialExpiresAtDate}
                            />
                          </span>
                        )}
                    </label>
                  }
                  name={'plan'}
                  value={'FREE'}
                  checked={currentSubscription.plan === 'FREE'}
                  onClick={handleSubscriptionFormRadioChange}
                  type={'radio'}
                />
              </FormField>
              <FreeTrialField
                team={team}
                isSelected={currentSubscription.plan === 'TRIAL'}
                onChange={handleFreeTrialExpiresAtChange}
                freeTrialExpiresAtDate={currentFreeTrialExpiresAt}
              />
              {/* Full base option */}
              <FormField>
                <Radio
                  label={'Full'}
                  name={'plan'}
                  value={'FULL'}
                  checked={currentSubscription.plan === 'FULL'}
                  onClick={handleSubscriptionFormRadioChange}
                  type={'radio'}
                />
              </FormField>
              {initialFreeTrialExpiresAtDate && isInitialFreeTrialActive && (
                <Alert
                  className={'free-trial-alert'}
                  type={'warning'}
                  size={'medium'}
                  message={
                    <>
                      <p>
                        <strong>Active Free Trial notice</strong>
                      </p>
                      <p>
                        This team has all features enabled because it has{' '}
                        <FreeTrialExpiresAtMessage
                          expiresAt={initialFreeTrialExpiresAtDate}
                        />
                        .{' '}
                      </p>
                      <p>
                        Subscription will be set to <strong>Free</strong>{' '}
                        automatically after this date.{' '}
                        <i>If needed, you can extend it below</i>, or just
                        change it to another subscription plan.
                      </p>
                    </>
                  }
                />
              )}
              <Divider />
              <Header>Products</Header>
              {/* Products */}
              {PRODUCT_TYPES.map((product: ProductType) => {
                const productPlanTag = getProductPlanTag(product)

                const disabled =
                  // on FREE plan, don't allow selecting FULL products
                  currentSubscription.plan === 'FREE' &&
                  (productPlanTag === 'Full' || productPlanTag === 'Extra')

                return (
                  <FormField key={product} className={'product-field'}>
                    <Radio
                      label={toTitleCase(product)}
                      name={'product'}
                      value={product}
                      checked={currentSubscription.products.includes(product)}
                      onClick={
                        !disabled
                          ? handleSubscriptionFormRadioChange
                          : undefined
                      }
                      disabled={disabled}
                      type={'checkbox'}
                    />
                    <span className={`product-plan-tag ${productPlanTag}`}>
                      {productPlanTag}
                    </span>
                  </FormField>
                )
              })}

              <Divider />
              <Header className={'go-to-production-header'}>
                <div className={'title'}>Go to Production</div>
                <a
                  className={'link'}
                  href={
                    'https://www.notion.so/pluggy/Go-to-production-be18fde06da54abf8e94cac359306319'
                  }
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  See documentation
                </a>
              </Header>
              {/* Go to production toggle */}
              <FormField>
                <Radio
                  label={
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label>
                      Contract signed - <strong>Production</strong> applications
                      can be created from Dashboard.
                    </label>
                  }
                  name={'production'}
                  checked={currentSubscription.production}
                  onClick={
                    isFullSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={!isFullSubscriptionSelected}
                  type={'checkbox'}
                />
              </FormField>
              <Divider />

              <Header>Features</Header>
              {/* Categorization */}
              <FormField className={'categorization-options'}>
                <Radio
                  label={'Categorization'}
                  name={'feature'}
                  value={'CATEGORIZATION'}
                  checked={currentSubscription.features.includes(
                    'CATEGORIZATION',
                  )}
                  disabled={isFreeSubscriptionSelected}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Full'}>Full</span>
              </FormField>

              {/* Merchant Extraction */}
              <FormField>
                <Radio
                  label={"Transaction 'merchant' field (Merchant Extraction)"}
                  name={'feature'}
                  value={'MERCHANT_EXTRACTION'}
                  checked={currentSubscription.features.includes(
                    'MERCHANT_EXTRACTION',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>

              {/* Investment metadata */}
              <FormField>
                <Radio
                  label={"Investment 'metadata' field (related to Security)"}
                  name={'feature'}
                  checked={currentSubscription.features.includes(
                    'INVESTMENT_METADATA',
                  )}
                  value={'INVESTMENT_METADATA'}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>

              {/* Optional Feature: Custom company name as device name */}
              <FormField>
                <Radio
                  label={'Company name as Device name'}
                  name={'feature'}
                  value={'CUSTOM_COMPANY_DEVICE_NAME'}
                  checked={currentSubscription.features.includes(
                    'CUSTOM_COMPANY_DEVICE_NAME',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>

              {/* Optional Feature: Customer can access OF Connectors */}
              <FormField>
                <Radio
                  label={'OF Connectors'}
                  name={'feature'}
                  value={'OPEN_FINANCE_CONNECTORS_FLOW'}
                  checked={currentSubscription.features.includes(
                    'OPEN_FINANCE_CONNECTORS_FLOW',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>
              {currentSubscription.features.includes(
                'OPEN_FINANCE_CONNECTORS_FLOW',
              ) && (
                <FormField>
                  <label id="iniciador-credentials-label">
                    Upload Iniciador Credentials
                    <input
                      id="iniciador-credentials-input"
                      onChange={handleIniciadorCredentialsChange}
                      type="file"
                      accept=".enc"
                    ></input>
                  </label>
                </FormField>
              )}

              {/* Optional Feature: Customer can list the Credit Card processor connectors */}
              <FormField>
                <Radio
                  label={'Credit Card Processor Connectors'}
                  name={'feature'}
                  value={'CREDIT_CARD_PROCESSOR_CONNECTORS'}
                  checked={currentSubscription.features.includes(
                    'CREDIT_CARD_PROCESSOR_CONNECTORS',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>

              {/* Optional Feature: Customer can access payment initiation API */}
              <FormField>
                <Radio
                  label={'Payment Initation'}
                  name={'feature'}
                  value={'PAYMENT_INITIATION'}
                  checked={currentSubscription.features.includes(
                    'PAYMENT_INITIATION',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>
              {currentSubscription.features.includes('PAYMENT_INITIATION') && (
                <FormField>
                  <Input
                    type={'text'}
                    label={'Payment Initiation client id'}
                    onChange={handleUpdatePaymentInitiationClientIdChange}
                    value={paymentInitiationCredentials.clientId}
                  />
                  <Input
                    type={'text'}
                    label={'Payment Initiation client secret'}
                    onChange={handleUpdatePaymentInitiationClientSecretChange}
                    value={paymentInitiationCredentials.clientSecret}
                  />
                </FormField>
              )}

              <FormField>
                <Radio
                  label={'Bulk Payments'}
                  name={'feature'}
                  value={'BULK_PAYMENTS'}
                  checked={currentSubscription.features.includes(
                    'BULK_PAYMENTS',
                  )}
                  onClick={
                    !isFreeSubscriptionSelected
                      ? handleSubscriptionFormRadioChange
                      : undefined
                  }
                  disabled={isFreeSubscriptionSelected}
                  type={'checkbox'}
                />
                <span className={'product-plan-tag Extra'}>Extra</span>
              </FormField>

              {currentSubscription.features.includes('BULK_PAYMENTS') && (
                <FormField>
                  <Grid columns={3} className="payment-pricing">
                    <Grid.Row>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Pluggy - PIX In'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'pixIn',
                              'pluggyPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.pluggyPricing?.pixIn || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Pl;uggy - PIX Out'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'pixOut',
                              'pluggyPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.pluggyPricing?.pixOut || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Pluggy - Boleto Out'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'boletoOut',
                              'pluggyPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.pluggyPricing?.boletoOut || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row s>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Customer - PIX In'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'pixIn',
                              'customerPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.customerPricing?.pixIn || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Customer - PIX Out'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'pixOut',
                              'customerPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.customerPricing?.pixOut || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Input
                          type={'number'}
                          label={'Customer - Boleto Out'}
                          onChange={(event) =>
                            handleUpdateSubscriptionPricingChange(
                              event,
                              'boletoOut',
                              'customerPricing',
                            )
                          }
                          value={`${(
                            currentSubscription.paymentsConfiguration
                              ?.customerPricing?.boletoOut || 0
                          ).toFixed(2)}`}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </FormField>
              )}

              {/* Items Frequency Update */}
              <FormField className={'items-frequency-update-option'}>
                <div>
                  <span className={'label'}>
                    Items Auto-sync Update frequency (in hours):
                  </span>
                  <span className={'product-plan-tag Extra'}>Extra</span>
                </div>
                <Input
                  className={'items-frequency-update-option-dropdown'}
                  label={'Item Update Frequency (in hours)'}
                  type="select"
                  options={subscriptionUpdateFrequencyOptions}
                  onChange={handleUpdateFrequencyInHoursChange}
                  /*
                    TODO: in pluggy/ui it should accept passing the 'option.id' (or alternatively, 'option.name' if specified)
                     fields directly and it should work, instead of having to rebuild an Option object
                   */
                  value={mapUpdateFrequencyToOption(
                    currentSubscription.updateFrequencyInHours,
                  )}
                  disabled={!isFullSubscriptionSelected}
                />
                <span className={'items-frequency-help-message'}>
                  {'The default is "Never"'}
                </span>
              </FormField>

              <Divider />
              <FormField className={'reason'}>
                <Header className={'motivation-header'}>
                  <div className={'title'}>
                    What is the motivation of this change?
                  </div>
                  <div className={'subtitle'}>Please be concise and clear.</div>
                </Header>

                <Input
                  type={'text'}
                  label={'Reason of change'}
                  onChange={handleUpdateReasonChange}
                  value={updateReason}
                  error={formErrors.updateReason}
                />
              </FormField>
            </Form.Group>
            <Button primary type="submit">
              Update
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default React.memo(TeamUpdateModal)
