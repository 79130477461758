import { connect } from 'react-redux'

import { getLocation, push } from 'connected-react-router'

import { auth0LogoutRequest } from '../../modules/auth/actions'
import { getAuth } from '../../modules/auth/selectors'
import { RootState } from '../../modules/reducer'
import Navbar from './Navbar'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Navbar.types'

const mapState = (state: RootState): MapStateProps => ({
  location: getLocation(state),
  auth: getAuth(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLogout: () => dispatch(auth0LogoutRequest()),
  onNavigate: (path: string) => dispatch(push(path)),
})

export default connect(mapState, mapDispatch)(Navbar)
