import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import { Team } from '../team/types'
import {
  FETCH_CUSTOMIZATION_FAILURE,
  FETCH_CUSTOMIZATION_REQUEST,
  FETCH_CUSTOMIZATION_SUCCESS,
  FetchCustomizationFailureAction,
  FetchCustomizationRequestAction,
  FetchCustomizationSuccessAction,
  UPDATE_CUSTOMIZATION_FAILURE,
  UPDATE_CUSTOMIZATION_REQUEST,
  UPDATE_CUSTOMIZATION_SUCCESS,
  UpdateCustomizationFailureAction,
  UpdateCustomizationRequestAction,
  UpdateCustomizationSuccessAction,
} from './actions'
import { Customization } from './types'

export type CustomizationState = {
  data: Record<Team['id'], Customization | undefined>
  loading: LoadingState
  error: Record<Team['id'], string | undefined>
}

const INITIAL_STATE: CustomizationState = {
  data: {},
  loading: [],
  error: {},
}

type CustomizationReducerAction =
  | FetchCustomizationRequestAction
  | FetchCustomizationSuccessAction
  | FetchCustomizationFailureAction
  | UpdateCustomizationRequestAction
  | UpdateCustomizationSuccessAction
  | UpdateCustomizationFailureAction

export function customizationReducer(
  state: CustomizationState = INITIAL_STATE,
  action: CustomizationReducerAction,
): CustomizationState {
  switch (action.type) {
    case FETCH_CUSTOMIZATION_REQUEST:
    case UPDATE_CUSTOMIZATION_REQUEST: {
      const {
        payload: {
          team: { id: teamId },
        },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          [teamId]: undefined,
        },
      }
    }
    case FETCH_CUSTOMIZATION_SUCCESS:
    case UPDATE_CUSTOMIZATION_SUCCESS: {
      const {
        payload: {
          team: { id: teamId },
          customization,
        },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          [teamId]: undefined,
        },
        data: {
          ...state.data,
          [teamId]: customization,
        },
      }
    }
    case FETCH_CUSTOMIZATION_FAILURE:
    case UPDATE_CUSTOMIZATION_FAILURE: {
      const {
        payload: {
          team: { id: teamId },
          error,
        },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          [teamId]: error,
        },
      }
    }
    default:
      return state
  }
}
