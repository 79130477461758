import React from 'react'

import { Item } from '../../modules/item/types'
import { ListSection } from '../ListSection'
import { Page } from '../Page'
import { ItemListItem } from './ItemListItem'
import { ItemSearchSection } from './ItemSearchSection'
import { Props } from './ItemsPage.types'

const ItemsPage = ({ items, isLoading, error }: Props) => (
  <Page className="ItemsPage">
    <ItemSearchSection />
    <ListSection
      title={'Item'}
      isLoading={!items && isLoading}
      data={items || []}
      className={'ItemsList'}
      error={error}
      EmptyPlaceholder={
        <div className={'user-empty-message'}>
          Searched Items will be listed here
        </div>
      }
      renderValue={(item: Item) => <ItemListItem item={item} key={item.id} />}
    />
  </Page>
)

export default React.memo(ItemsPage)
