import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { isStringsArray } from '../../../lib/utils/validation'
import {
  isMembersInvitationsArray,
  TeamFields,
  TeamFieldsErrors,
} from '../../../modules/team/types'
import { TeamCreateForm, validateTeamField } from '../TeamCreateForm'
import { Props } from './TeamCreateModal.types'

import './TeamCreateModal.css'

const initialTeamFields = (): TeamFields => ({
  name: '',
  imageUrl: '',
  members: [
    {
      email: '',
      role: 'MEMBER',
    },
  ],
  cnpj: '',
})

const TeamCreateModal = ({
  isLoading,
  error,
  onClose,
  onCreateTeam,
  clearTeamError,
}: Props) => {
  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<TeamFieldsErrors>({})
  const [teamValues, setTeamValues] = useState(initialTeamFields())

  const close = useCallback(() => {
    setFormErrors({})
    setTeamValues(initialTeamFields())
    onClose()
  }, [onClose])

  const handleOnClose = useCallback(() => {
    close()
  }, [close])

  const handleOnCancel = useCallback(() => {
    close()
  }, [close])

  const handleValuesChange = useCallback(
    (fieldName: keyof TeamFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // clear team state error, if any
      if (submit) {
        setSubmit(false)
      }
      clearTeamError()
      // set field value
      setTeamValues({ ...teamValues, [fieldName]: value })
    },
    [teamValues, clearTeamError, formErrors, submit],
  )

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: TeamFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(teamValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else if (isMembersInvitationsArray(value)) {
          const errorsJoined = validateTeamField(
            field as keyof TeamFields,
            value,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateTeamField(field as keyof TeamFields, value)

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof TeamFields] = fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        return
      }

      setSubmit(true)
      onCreateTeam(teamValues)
    },
    [teamValues, onCreateTeam, isLoading],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleOnClose()
    }
  }, [error, isLoading, submit, handleOnClose])

  return (
    <Modal
      className={'TeamCreateModal'}
      onClose={handleOnClose}
      open={true}
      closeIcon
      size={'small'}
    >
      <Modal.Header>
        New team
        <p>Create a new team</p>
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <TeamCreateForm
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={teamValues}
          errors={formErrors}
          isLoading={isLoading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button primary onClick={handleSubmit} loading={isLoading}>
          Create
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(TeamCreateModal)
