import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Divider, Input, Radio } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { Form } from 'semantic-ui-react'

import {
  COMPONENT_STATUSES,
  ComponentStatus,
  IncidentFields,
  REALTIME_INCIDENT_STATUSES,
  RealtimeIncidentStatus,
} from '../../../../modules/incident/types'
import type { Props } from './IncidentCreateForm.types'

import './IncidentCreateForm.css'

const incidentStatusOptions: Option[] = REALTIME_INCIDENT_STATUSES.map(
  (value: RealtimeIncidentStatus): Option => ({
    id: value,
    // TODO improve pluggyai/ui "select" to accept option with only 'id' field (if no 'name' set, it should work)
    name: value,
  }),
)

const componentStatusOptions: Option[] = COMPONENT_STATUSES.map(
  (value: ComponentStatus): Option => ({
    id: value,
    name: value,
  }),
)

const IncidentCreateForm = ({
  className,
  errors,
  fields,
  isLoading,
  onFieldChange,
  onSubmit,
  values,
  initialValue,
  connectorId,
}: Props) => {
  const { t } = useTranslation()

  const isFieldIncluded = useCallback(
    (field: keyof IncidentFields) => {
      const valueOfField = values[field] as
        | IncidentFields[keyof IncidentFields]
        | null
      if (valueOfField === null) {
        // was set null -> hide it
        return false
      }
      if (!fields) {
        return true
      }

      return fields.includes(field)
    },
    [fields, values],
  )

  // if is Update of an existing value, map initialValue to the current form & update UI state
  useEffect(() => {
    if (!initialValue) {
      return
    }

    const { components, status, name } = initialValue

    onFieldChange('name', name)
    onFieldChange('status', status)

    const connectorIdComponentStatus = components.find(
      (component) => component.connectorId === connectorId,
    )?.status

    if (!connectorIdComponentStatus) {
      return
    }
    onFieldChange('componentStatus', connectorIdComponentStatus)
  }, [initialValue, onFieldChange, connectorId])

  return (
    <Form
      className={`IncidentCreateForm ${className || ''}`}
      onSubmit={onSubmit}
    >
      {initialValue && (
        <>
          <div className={'current-incident'}>
            Created {new Date(initialValue.createdAt).toLocaleString()}, Started{' '}
            {initialValue.startedAt
              ? new Date(initialValue.startedAt).toLocaleString()
              : '-'}
            {initialValue.resolvedAt &&
              `, Resolved ${new Date(
                initialValue.resolvedAt,
              ).toLocaleString()}`}
            {initialValue.incidentUpdates.map(
              ({
                body,
                id: incidentUpdateId,
                status: incidentUpdateStatus,
                createdAt: incidentUpdateCreatedAt,
              }) => (
                <div className={'incident-update'} key={incidentUpdateId}>
                  <strong>{incidentUpdateStatus}</strong> - <span>{body}</span>
                  <p>{new Date(incidentUpdateCreatedAt).toLocaleString()}</p>
                </div>
              ),
            )}
          </div>
          <Divider />
        </>
      )}
      <Alert
        size={'medium'}
        message={
          <div>
            Please ensure to be aligned with our Incident guidelines, in our{' '}
            <a
              href={
                'https://www.notion.so/pluggy/Status-Page-Passo-a-Passo-9418599ac491420ea8f01b9f03864796#ad18cf6c9130447f93e673e94c8cf36d'
              }
            >
              Notion document
            </a>
            .
          </div>
        }
        type={'info'}
      />
      {isFieldIncluded('name') && (
        <div className={'field-container'}>
          <h4>{t('incident.form.field.name.header')}</h4>
          <Input
            label={t('incident.form.field.name.label')}
            type="text"
            onChange={({ value }) => onFieldChange('name', value)}
            value={values.name}
            error={errors.name}
            disabled={isLoading}
          />
        </div>
      )}
      {isFieldIncluded('body') && (
        <div className={'field-container'}>
          <h4>{t('incident.form.field.body.header')}</h4>
          <Input
            label={t('incident.form.field.body.label')}
            type="text"
            onChange={({ value }) => onFieldChange('body', value)}
            value={values.body}
            error={errors.body}
            disabled={isLoading}
          />
        </div>
      )}
      {isFieldIncluded('status') && (
        <div className={'field-container'}>
          <h4>{t('incident.form.field.status.header')}</h4>
          <Input
            label={t('incident.form.field.status.label')}
            type="select"
            options={incidentStatusOptions}
            onChange={({ value }: { value: Option | Option[] }) =>
              onFieldChange('status', (value as Option).id)
            }
            /*
            TODO: in pluggy/ui it should accept passing the 'option.id' (or alternatively, 'option.name' if specified)
             fields directly and it should work, instead of having to rebuild an Option object
           */
            value={
              values.status && {
                id: values.status,
                name: values.status,
              }
            }
            error={errors.status}
            disabled={isLoading}
          />
        </div>
      )}

      {isFieldIncluded('componentStatus') && (
        <div className={'field-container'}>
          <h4>{t('incident.form.field.componentStatus.header')}</h4>
          <Input
            label={t('incident.form.field.componentStatus.label')}
            type="select"
            options={componentStatusOptions}
            onChange={({ value }: { value: Option | Option[] }) =>
              onFieldChange('componentStatus', (value as Option).id)
            }
            /*
            TODO: in pluggy/ui it should accept passing the 'option.id' (or alternatively, 'option.name' if specified)
             fields directly and it should work, instead of having to rebuild an Option object
           */
            value={
              values.componentStatus && {
                id: values.componentStatus,
                name: values.componentStatus,
              }
            }
            error={errors.componentStatus}
            disabled={isLoading}
          />
        </div>
      )}
      {isFieldIncluded('deliverNotifications') && (
        <div className={'field-container'}>
          <Radio
            label={t('incident.form.field.deliverNotifications.label')}
            checked={values.deliverNotifications}
            onClick={() =>
              onFieldChange(
                'deliverNotifications',
                !values.deliverNotifications,
              )
            }
            type={'checkbox'}
          />
        </div>
      )}

      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}
export default React.memo(IncidentCreateForm)
