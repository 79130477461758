/**
 * Replace url params (ie. /:id/) with the specified values
 */
export function replaceUrlParams(
  url: string,
  params: Record<string, string | number>,
): string {
  let replacedUrl = url

  for (const [param, value] of Object.entries(params)) {
    replacedUrl = url.replace(`:${param}`, String(value))
  }
  return replacedUrl
}

/**
 * Convert an object to an URL-valid query string
 * @param object
 */
export function objectToQuerystring(
  object:
    | Record<string, string | number | boolean | undefined>
    | undefined
    | null,
): string {
  if (!object) {
    return ''
  }

  let queryString = ''

  for (const [key, value] of Object.entries(object)) {
    if (value === undefined) {
      continue
    }
    const delimiter = queryString.length === 0 ? '?' : '&'
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(value)

    queryString += `${delimiter}${encodedKey}=${encodedValue}`
  }

  return queryString
}
