import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { PageResponse } from 'pluggy-js'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { UsersService } from '../../lib/api/auth-api/UsersService'
import { getAuth0AccessToken } from '../auth/selectors'
import { addNotificationAction } from '../notification/actions'
import {
  FETCH_USERS_REQUEST,
  fetchUsersFailure,
  FetchUsersRequestAction,
  fetchUsersSuccess,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  resendVerificationEmailFailure,
  ResendVerificationEmailRequestAction,
  resendVerificationEmailSuccess,
} from './actions'
import { AuthApiUser } from './types'

function* handleFetchUsersRequest(action: FetchUsersRequestAction) {
  try {
    const {
      payload: { search },
    } = action
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UsersService(accessToken)
    const { data: users }: AxiosResponse<PageResponse<AuthApiUser>> =
      yield call(() => userService.getUsers(search))

    yield put(fetchUsersSuccess(users))
  } catch (error) {
    const errorMessage = i18next.t('users.error.fetch')
    yield put(fetchUsersFailure(errorMessage))
  }
}

function* handleResendVerificationEmailRequest(
  action: ResendVerificationEmailRequestAction,
) {
  const {
    payload: { userId },
  } = action

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const userService = new UsersService(accessToken)

    yield call(() => userService.resendVerificationEmail(userId))
    yield put(resendVerificationEmailSuccess(userId))

    yield put(
      addNotificationAction({
        title: 'Success!',
        message: `Verification email sent to user id '${userId}'.`,
        duration: 6000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    let errorMessage = `Failed to send verification email to user id '${userId}'`
    if (error.response?.body) {
      try {
        const { error: message } = JSON.parse(error.response.body) as {
          error?: string
        }
        errorMessage = `${errorMessage}. Message: '${message}', status: ${error.response.status}`
      } catch {
        errorMessage = `${errorMessage}. Response Body: ${error.response.body}`
      }
      error.message = errorMessage
    } else {
      error.message = `${errorMessage}: ${error.message}`
    }

    yield put(
      addNotificationAction({
        title: 'Something went wrong',
        message: errorMessage,
        duration: 8000,
        level: 'error',
      }),
    )

    yield put(resendVerificationEmailFailure(errorMessage, userId))
  }
}

export function* userSaga() {
  yield all([
    takeEvery(FETCH_USERS_REQUEST, handleFetchUsersRequest),
    takeEvery(
      RESEND_VERIFICATION_EMAIL_REQUEST,
      handleResendVerificationEmailRequest,
    ),
  ])
}
