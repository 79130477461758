import axios, { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  CreateIncidentRequest,
  UpdateIncidentRequest,
} from '../../../lib/client/statusPage/types'
import { incidentsService } from '../../lib/api/IncidentsService'
import { addNotificationAction } from '../notification/actions'
import {
  CREATE_INCIDENT_REQUEST,
  createIncidentFailure,
  CreateIncidentRequestAction,
  createIncidentSuccess,
  FETCH_INCIDENTS_REQUEST,
  fetchIncidentsFailure,
  FetchIncidentsRequestAction,
  fetchIncidentsSuccess,
  UPDATE_INCIDENT_REQUEST,
  updateIncidentFailure,
  UpdateIncidentRequestAction,
  updateIncidentSuccess,
} from './actions'
import { Incident } from './types'

function* handleFetchIncidentsRequest(_action: FetchIncidentsRequestAction) {
  try {
    const { data }: AxiosResponse<Incident[]> = yield call(() =>
      incidentsService.getIncidents(),
    )
    yield put(fetchIncidentsSuccess(data))
  } catch (error) {
    yield put(fetchIncidentsFailure(error.message))
  }
}

function* handleCreateIncidentRequest(action: CreateIncidentRequestAction) {
  const {
    payload: { incidentValues, connectorId },
  } = action

  const { body, impact, name, status, componentStatus, deliverNotifications } =
    incidentValues

  if (!componentStatus || !status) {
    yield put(
      createIncidentFailure(
        `Invalid incident values: ${JSON.stringify(incidentValues)}`,
      ),
    )
    return
  }

  const createIncidentRequest: CreateIncidentRequest = {
    body,
    componentStatus,
    connectorId,
    deliverNotifications,
    impact,
    name,
    status,
  }

  try {
    const incident: Incident = yield call(() =>
      incidentsService.createIncident(createIncidentRequest),
    )
    yield put(createIncidentSuccess(incident))

    yield put(
      addNotificationAction({
        title: i18next.t('incident.success.create.title'),
        message: i18next.t('incident.success.create.message', {
          name,
        }),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    let errorMessage: string = error.message
    let errorStatus: number | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      ;({
        data: { message: errorMessage },
        status: errorStatus,
      } = error.response as {
        data: { message: string }
        status: number
      })
    }

    const message = i18next.t('incident.error.create.message', {
      errorMessage,
      errorStatus,
      name,
    })

    yield put(
      addNotificationAction({
        title: i18next.t('incident.error.create.title'),
        message,
        duration: 4000,
        level: 'error',
      }),
    )

    yield put(createIncidentFailure(message))
  }
}

function* handleUpdateIncidentRequest(action: UpdateIncidentRequestAction) {
  const {
    payload: {
      incidentValues,
      incident: { id: incidentId },
      connectorId,
    },
  } = action

  const { body, impact, name, status, componentStatus, deliverNotifications } =
    incidentValues

  if (!componentStatus || !status) {
    yield put(
      updateIncidentFailure(
        `Invalid incident values: ${JSON.stringify(incidentValues)}`,
      ),
    )
    return
  }

  const updateIncidentRequest: UpdateIncidentRequest = {
    incidentId,
    name,
    body,
    componentStatus,
    deliverNotifications,
    impact,
    status,
    connectorId,
  }

  try {
    const incident: Incident = yield call(() =>
      incidentsService.updateIncident(updateIncidentRequest),
    )
    yield put(updateIncidentSuccess(incident))

    yield put(
      addNotificationAction({
        title: i18next.t('incident.success.update.title'),
        message: i18next.t('incident.success.update.message', {
          name,
        }),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    let errorMessage: string = error.message
    let errorStatus: number | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      ;({
        data: { message: errorMessage },
        status: errorStatus,
      } = error.response as {
        data: { message: string }
        status: number
      })
    }

    const message = i18next.t('incident.error.update.message', {
      errorMessage,
      errorStatus,
      name,
    })

    yield put(
      addNotificationAction({
        title: i18next.t('incident.error.update.title'),
        message,
        duration: 4000,
        level: 'error',
      }),
    )

    yield put(updateIncidentFailure(message))
  }
}

export function* incidentSaga() {
  yield all([
    takeEvery(FETCH_INCIDENTS_REQUEST, handleFetchIncidentsRequest),
    takeEvery(CREATE_INCIDENT_REQUEST, handleCreateIncidentRequest),
    takeEvery(UPDATE_INCIDENT_REQUEST, handleUpdateIncidentRequest),
  ])
}
