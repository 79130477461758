import { connect } from 'react-redux'

import { isImpersonateAllowed } from '../../../modules/auth/selectors'
import { RootState } from '../../../modules/reducer'
import { resendVerificationEmailRequest } from '../../../modules/user/actions'
import UserListItem from './UserListItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './UserListItem.types'

const mapState = (state: RootState): MapStateProps => ({
  isImpersonateAllowed: isImpersonateAllowed(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onResendVerificationEmail: (userId: string) =>
    dispatch(resendVerificationEmailRequest(userId)),
})

export default connect(mapState, mapDispatch)(UserListItem)
