import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'

import { locations } from '../../modules/routing/locations'
import { ApplicationsPage } from '../ApplicationsPage'
import { ApplicationsShareLinksPage } from '../ApplicationsShareLinksPage'
import { Callback, withCustomAuthenticationRequired } from '../Auth'
import { ConnectorsPage } from '../ConnectorsPage'
import { ItemsPage } from '../ItemsPage'
import { OverviewPage } from '../OverviewPage'

const ProtectedRoute = ({
  component,
  ...rest
}: Omit<RouteProps, 'component'> & {
  component: React.FC
}) => (
  <Route component={withCustomAuthenticationRequired(component)} {...rest} />
)

const Routes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoute
          exact
          path={locations.root()}
          component={OverviewPage}
        />
        <ProtectedRoute
          exact
          path={locations.applications()}
          component={ApplicationsPage}
        />
        <ProtectedRoute
          exact
          path={locations.demoLinks()}
          component={ApplicationsShareLinksPage}
        />
        <ProtectedRoute
          exact
          path={locations.connectors()}
          component={ConnectorsPage}
        />
        <Route
          exact
          path={locations.authCallback()}
          component={() => <Callback rootLocation={locations.root()} />}
        />
        <Route exact path={locations.items()} component={ItemsPage} />
        <Redirect to={locations.root()} />
      </Switch>
    </>
  )
}

export default Routes
