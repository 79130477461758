import { connect } from 'react-redux'

import { FETCH_ITEM_REQUEST } from '../../modules/item/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/item/selectors'
import { RootState } from '../../modules/reducer'
import ItemsPage from './ItemsPage'
import { MapDispatch, MapDispatchProps, MapStateProps } from './ItemsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  items: getData(state),
  error: getError(state).update,
  isLoading: isLoadingType(getLoading(state), FETCH_ITEM_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(ItemsPage)
