import { connect } from 'react-redux'

import { isLoadingType } from '../../../modules/application/selectors'
import { RootState } from '../../../modules/reducer'
import {
  clearTeamError,
  CREATE_TEAM_REQUEST,
  createTeamRequest,
} from '../../../modules/team/actions'
import { getError, getLoading } from '../../../modules/team/selectors'
import { TeamFields } from '../../../modules/team/types'
import { FETCH_USERS_REQUEST } from '../../../modules/user/actions'
import TeamCreateModal from './TeamCreateModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamCreateModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading:
    isLoadingType(getLoading(state), CREATE_TEAM_REQUEST) ||
    isLoadingType(getLoading(state), FETCH_USERS_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateTeam: (createTeamFields: TeamFields) =>
    dispatch(createTeamRequest(createTeamFields)),
  clearTeamError: () => dispatch(clearTeamError()),
})

export default connect(mapState, mapDispatch)(TeamCreateModal)
