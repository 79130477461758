import { push } from 'connected-react-router'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { ApplicationService } from '../../lib/api/auth-api/ApplicationService'
import { fetchApplicationsRequest } from '../application/actions'
import { getAuth0AccessToken } from '../auth/selectors'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import { locations } from '../routing/locations'
import {
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE,
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST,
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS,
  submitMoveApplicationToProductionFailure,
  SubmitMoveApplicationToProductionFailureAction,
  SubmitMoveApplicationToProductionRequestAction,
  submitMoveApplicationToProductionSuccess,
} from './actions'

function* handleSubmitMoveApplicationToProductionRequest(
  action: SubmitMoveApplicationToProductionRequestAction,
) {
  const { application } = action.payload

  try {
    const accessToken: string = yield select(getAuth0AccessToken)

    if (!accessToken) {
      throw new Error(
        'No access token in state, can not move application to production',
      )
    }

    const applicationService = new ApplicationService(accessToken)

    yield call(() =>
      applicationService.moveApplicationToProduction(application.id),
    )

    yield put(submitMoveApplicationToProductionSuccess())
  } catch (error) {
    const notificationOptions: NotificationOptions = {
      level: 'error',
      title: 'Application move to production failed',
      message: 'The application could not be moved to production',
      duration: 4000,
    }
    yield put(addNotificationAction(notificationOptions))

    error.message = `Could not submit go to production: ${error.message}`
    console.error(error)

    yield put(submitMoveApplicationToProductionFailure(error.message))
  }
}

function* handleSubmitMoveApplicationToProductionSuccess() {
  yield put(
    addNotificationAction({
      title: 'Application moved to production',
      message: 'The application has been moved to production',
      duration: 4000,
    }),
  )
  // fetch applications
  yield put(fetchApplicationsRequest())
  // navigate to applications page
  yield put(push(locations.applications()))
}

function* handleSubmitMoveApplicationToProductionFailure(
  _action: SubmitMoveApplicationToProductionFailureAction,
) {
  // navigate to applications page
  yield put(push(locations.applications()))
}

export function* moveApplicationToProductionSaga() {
  yield all([
    takeEvery(
      SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST,
      handleSubmitMoveApplicationToProductionRequest,
    ),
    takeEvery(
      SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS,
      handleSubmitMoveApplicationToProductionSuccess,
    ),
    takeEvery(
      SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE,
      handleSubmitMoveApplicationToProductionFailure,
    ),
  ])
}
