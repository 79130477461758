import React, { memo } from 'react'

import { Radio, TextArea } from '@pluggyai/ui'

import { Props } from './TextInputOptional.types'

import './TextInputOptional.css'

const TextInputOptional = ({
  label,
  onChange,
  value,
  initialValue,
  disabled,
  error,
}: Props) => {
  const valueOrInitialValue: string | undefined =
    value === null
      ? initialValue === null
        ? "{{ Pluggy's Default }}"
        : initialValue
      : value

  return (
    <div className={'TextInputOptional'}>
      <Radio
        label={"Pluggy's default"}
        checked={value === null}
        onClick={() => onChange({ value: null })}
        type={'radio'}
      />
      <Radio
        label={'Custom:'}
        checked={value !== null}
        onClick={() => onChange({ value: initialValue || '' })}
        type={'radio'}
      />
      <div className={'input-container input-wrapper'}>
        <TextArea
          label={label}
          type="text"
          onChange={(event: React.FormEvent<HTMLTextAreaElement>) =>
            onChange({ value: event.currentTarget.value })
          }
          className={`input ${valueOrInitialValue ? 'filled' : ''}`}
          value={valueOrInitialValue || ''}
          error={error}
          disabled={disabled || value === null}
          id={`textarea-${label}`}
        />
        <label htmlFor={`textarea-${label}`} className={'label'}>
          {label}
        </label>
      </div>
    </div>
  )
}

export default memo(TextInputOptional)
