import { connect } from 'react-redux'

import {
  FETCH_CONNECTORS_REQUEST,
  fetchConnectorsRequest,
  updateConnectorsRequest,
} from '../../modules/connector/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/connector/selectors'
import { fetchIncidentsRequest } from '../../modules/incident/actions'
import { RootState } from '../../modules/reducer'
import ConnectorsPage from './ConnectorsPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ConnectorsPage.types'

const mapState = (state: RootState): MapStateProps => ({
  connectors: getData(state),
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), FETCH_CONNECTORS_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchConnectors: () => dispatch(fetchConnectorsRequest()),
  onFetchIncidents: () => dispatch(fetchIncidentsRequest()),
  onUpdateConnectors: () => dispatch(updateConnectorsRequest()),
})

export default connect(mapState, mapDispatch)(ConnectorsPage)
