import { addDays, addMinutes, format, startOfDay } from 'date-fns'

/**
 * Format date value to 'yyyy-MM-dd HH:MM:SS' string, in local browser timezone
 *
 * @param date
 */
export function formatDate(date: string | Date | number): string {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:SS')
}

/**
 * Helper to correct a date by adding (or subtracting, if negative) the current Timezone offset time
 * @param date
 */
function addTimezoneOffset(date: Date): Date {
  return addMinutes(date, date.getTimezoneOffset())
}

/**
 * Helper to format a Date object to 'yyyy-MM-dd' format, to be used as a "date" input value.
 * It takes in account the local timezone offset so the formatted date is accurate in UTC.
 *
 * @param date
 */
export function formatDateForDateInput(date: Date): string {
  const startOfNextDay = addDays(startOfDay(date), 1)
  const datePlusTimezoneOffset = addTimezoneOffset(startOfNextDay)
  try {
    return format(datePlusTimezoneOffset, 'yyyy-MM-dd')
  } catch (error) {
    console.error(`Could not format date ${datePlusTimezoneOffset}`, error)
    return '?'
  }
}
