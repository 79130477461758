import { action } from 'typesafe-actions'

import { Connector, UpdateConnectorRequest } from './types'

/// Fetch Connectors request

export const FETCH_CONNECTORS_REQUEST = '[Request] Fetch Connectors'
export const FETCH_CONNECTORS_SUCCESS = '[Success] Fetch Connectors'
export const FETCH_CONNECTORS_FAILURE = '[Failure] Fetch Connectors'

export const fetchConnectorsRequest = () => action(FETCH_CONNECTORS_REQUEST)
export const fetchConnectorsSuccess = (connectors: Connector[]) =>
  action(FETCH_CONNECTORS_SUCCESS, { connectors })
export const fetchConnectorsFailure = (error: string) =>
  action(FETCH_CONNECTORS_FAILURE, { error })

export type FetchConnectorsRequestAction = ReturnType<
  typeof fetchConnectorsRequest
>
export type FetchConnectorsSuccessAction = ReturnType<
  typeof fetchConnectorsSuccess
>
export type FetchConnectorsFailureAction = ReturnType<
  typeof fetchConnectorsFailure
>

/// Update Connector request

export const UPDATE_CONNECTOR_REQUEST = '[Request] Update Connector'
export const UPDATE_CONNECTOR_SUCCESS = '[Success] Update Connector'
export const UPDATE_CONNECTOR_FAILURE = '[Failure] Update Connector'

export const updateConnectorRequest = (
  connector: Connector,
  updateConnectorFields: UpdateConnectorRequest,
) => action(UPDATE_CONNECTOR_REQUEST, { connector, updateConnectorFields })
export const updateConnectorSuccess = (connector: Connector) =>
  action(UPDATE_CONNECTOR_SUCCESS, { connector })
export const updateConnectorFailure = (error: string) =>
  action(UPDATE_CONNECTOR_FAILURE, { error })

export type UpdateConnectorRequestAction = ReturnType<
  typeof updateConnectorRequest
>
export type UpdateConnectorSuccessAction = ReturnType<
  typeof updateConnectorSuccess
>
export type UpdateConnectorFailureAction = ReturnType<
  typeof updateConnectorFailure
>

/// Clear Connector error

export const CLEAR_CONNECTOR_ERROR = '[Request] Clear Connector Item error'
export const clearConnectorError = () => action(CLEAR_CONNECTOR_ERROR)
export type ClearConnectorErrorAction = ReturnType<typeof clearConnectorError>

/// Update Connectors Request

export const UPDATE_CONNECTORS_REQUEST = '[Request] Update Connectors'
export const UPDATE_CONNECTORS_SUCCESS = '[Success] Update Connectors'
export const UPDATE_CONNECTORS_FAILURE = '[Failure] Update Connectors'

export const updateConnectorsRequest = () => action(UPDATE_CONNECTORS_REQUEST)
export const updateConnectorsSuccess = (count: number) =>
  action(UPDATE_CONNECTORS_SUCCESS, { count })
export const updateConnectorsFailure = (error: string) =>
  action(UPDATE_CONNECTORS_FAILURE, { error })

export type UpdateConnectorsRequestAction = ReturnType<
  typeof updateConnectorsRequest
>
export type UpdateConnectorsSuccessAction = ReturnType<
  typeof updateConnectorsSuccess
>
export type UpdateConnectorsFailureAction = ReturnType<
  typeof updateConnectorsFailure
>
