import React from 'react'

import { Button, Divider, Table } from 'semantic-ui-react'

import { isTeamMember, TeamRole } from '../../../../modules/team/types'
import { Props } from './TeamMembers.types'

import './TeamMembers.css'

const TeamMembers = ({ team }: Props) => {
  const { members, invitations } = team
  return (
    <>
      <Divider />
      <h4>Members</h4>
      <p>
        Confirmed: {members.length} | Pending: {invitations.length}
      </p>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {/* list members and invites */}
          {[...members, ...invitations].map((user) => {
            const { id: userId, email } = user
            let type: 'Member' | 'Invite'
            let role: TeamRole
            if (isTeamMember(user)) {
              type = 'Member'
              ;({ role } = user.teamUser)
            } else {
              type = 'Invite'
              ;({ role } = user)
            }
            const handleDeleteMemberClick = () => {
              // TODO onDeleteTeamMember(team, user) : onDeleteTeamInvitation(team, user)
              console.log(`Team id: ${team.id} - remove ${role} id: ${user.id}`)
            }
            return (
              <Table.Row key={userId}>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{type}</Table.Cell>
                <Table.Cell>{role}</Table.Cell>
                <Table.Cell>
                  {/* TODO: enable when implemented, or remove? */}
                  <Button
                    primary
                    size="mini"
                    onClick={handleDeleteMemberClick}
                    disabled
                  >
                    Remove
                  </Button>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}

export default React.memo(TeamMembers)
