import { AxiosResponse } from 'axios'

import type { ConnectorId } from '../../../lib/ConnectorId'
import {
  Connector,
  UpdateConnectorRequest,
} from '../../modules/connector/types'
import { BaseService } from './BaseService'
import { replaceUrlParams } from './utils'

class ConnectorsService extends BaseService {
  async getConnectors(): Promise<AxiosResponse<{ results: Connector[] }>> {
    return await this.getServiceInstance().get(this.urls.GET_CONNECTORS, {
      params: {
        // retrieve BR connectors only
        countries: 'BR',
      },
    })
  }

  async updateConnector(
    id: ConnectorId,
    updateValues: UpdateConnectorRequest,
  ): Promise<AxiosResponse<Connector>> {
    const url = replaceUrlParams(this.urls.PATCH_CONNECTOR, {
      id,
    })
    return await this.getServiceInstance().patch(url, updateValues)
  }

  /**
   * Update all connectors, to trigger a resync the Connectors' data
   */
  async updateConnectors(): Promise<AxiosResponse<{ count: number }>> {
    return await this.getServiceInstance().patch(this.urls.PATCH_CONNECTORS)
  }
}

export const connectorsService = new ConnectorsService()
