import i18n from 'i18next'

import {
  isValidCnpj,
  isValidEmail,
  isValidUrl,
} from '../../../lib/utils/validation'
import {
  MemberInvitation,
  TEAM_ROLES,
  TeamFields,
} from '../../../modules/team/types'

const validations: Record<
  keyof TeamFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  imageUrl: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length > 0 && !isValidUrl(value)) {
      return i18n.t('team.form.error.url', {
        fieldName,
      })
    }
    return undefined
  },
  name: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length === 0) {
      return i18n.t('team.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 100
    if (value.length > maxLength) {
      return i18n.t('team.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }
    return undefined
  },
  cnpj: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (value.length === 0) {
      // field is optional, no error
      return undefined
    }
    if (!isValidCnpj(value)) {
      return i18n.t('team.form.error.cnpj', { fieldName })
    }
    return undefined
  },
  members: (fieldName: string, value: unknown): string | undefined => {
    const membersEmailsArray = value as MemberInvitation[]
    if (!value || membersEmailsArray.length === 0) {
      // no members to invite, OK because it's optional
      return undefined
    }

    const allEmptyEmails = membersEmailsArray.every(
      ({ email }) => email.trim().length === 0,
    )

    const noOwnerMembers = membersEmailsArray.every(
      ({ email, role }) => email.length > 0 && role !== 'OWNER',
    )

    const errors = membersEmailsArray.map(({ email: memberEmail, role }, i) => {
      if (allEmptyEmails) {
        // no email included, NOT OK because we need at least one
        return i18n.t('team.form.error.members.empty')
      }

      if (memberEmail.length === 0) {
        return i18n.t('team.form.error.empty', {
          fieldName,
        })
      }

      if (!isValidEmail(memberEmail)) {
        return i18n.t('team.form.error.email', {
          fieldName,
        })
      }

      // check if value was already included in the list
      const previousEmails = membersEmailsArray
        .slice(0, i)
        .map((prevValue) => prevValue.email)
      if (previousEmails.includes(memberEmail)) {
        return i18n.t('team.form.error.list-repeated', {
          fieldName,
        })
      }

      if (!TEAM_ROLES.includes(role)) {
        return i18n.t('team.form.error.invalid-value', {
          fieldName,
        })
      }

      if (noOwnerMembers) {
        return i18n.t('team.form.error.members.missing-owner')
      }

      return undefined
    })

    return errors.join(',')
  },
}

export function validateTeamField(
  fieldName: keyof TeamFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(`team.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
