import { action } from 'typesafe-actions'

import { NotificationOptions } from './types'
import createNotification from './utils'

/// Notification actions

export const ADD_NOTIFICATION = 'Add Notification'
export const REMOVE_NOTIFICATION = 'Remove Notification'

export const addNotificationAction = (options: NotificationOptions) =>
  action(ADD_NOTIFICATION, { notification: createNotification(options) })

export const removeNotificationAction = (id: string) =>
  action(REMOVE_NOTIFICATION, { id })

export type AddNotificationAction = ReturnType<typeof addNotificationAction>
export type RemoveNotificationAction = ReturnType<
  typeof removeNotificationAction
>
