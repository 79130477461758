import { connect } from 'react-redux'

import {
  FETCH_CUSTOMIZATION_REQUEST,
  fetchCustomizationRequest,
  UPDATE_CUSTOMIZATION_REQUEST,
  updateCustomizationRequest,
} from '../../../modules/customization/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../../modules/customization/selectors'
import { UpdateCustomizationValues } from '../../../modules/customization/types'
import { RootState } from '../../../modules/reducer'
import { fetchTeamsRequest } from '../../../modules/team/actions'
import { Team } from '../../../modules/team/types'
import TeamUpdateTermsModal from './TeamUpdateTermsModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamUpdateTermsModal.types'

const mapState = (state: RootState): MapStateProps => ({
  customizationsByTeamId: getData(state),
  customizationErrorsByTeamId: getError(state),
  isFetchingCustomization: isLoadingType(
    getLoading(state),
    FETCH_CUSTOMIZATION_REQUEST,
  ),
  isUpdatingCustomization: isLoadingType(
    getLoading(state),
    UPDATE_CUSTOMIZATION_REQUEST,
  ),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchTeams: () => dispatch(fetchTeamsRequest()),
  onUpdate: (team: Team, updateValues: UpdateCustomizationValues) =>
    dispatch(updateCustomizationRequest(team, updateValues)),
  onFetchCustomization: (team: Team) =>
    dispatch(fetchCustomizationRequest(team)),
})

export default connect(mapState, mapDispatch)(TeamUpdateTermsModal)
