import React from 'react'

import Icon from '../Icon'

import './DropdownOpenIcon.css'

const DropdownOpenIcon = () => (
  <Icon
    className={'dropdown-open-icon'}
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillOpacity=".87" fillRule="evenodd">
      <g fill="#FFF">
        <g>
          <path
            d="M14.103 16.741c.307.288.307.755 0 1.043-.308.288-.807.288-1.115 0l-7.757-7.262c-.308-.288-.308-.755 0-1.044l7.757-7.262c.308-.288.807-.288 1.115 0 .307.288.307.755 0 1.043L6.902 10l7.2 6.741z"
            transform="translate(-57 -1233) translate(57 1233) rotate(-90 9.667 10)"
          />
        </g>
      </g>
    </g>
  </Icon>
)

export default React.memo(DropdownOpenIcon)
