import React from 'react'

import {
  addDays,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isWithinInterval,
} from 'date-fns'

import { formatDate } from '../../../../lib/utils/date'
import { Props } from './FreeTrialExpiresAtMessage.types'

import './FreeTrialExpiresAtMessage.css'

const FreeTrialExpiresAtMessage = ({ expiresAt, includeDate }: Props) => {
  const isFreeTrialActive = isAfter(new Date(expiresAt), Date.now())

  const isFreeTrialExpired = isBefore(expiresAt, Date.now())

  // expired recently if it expired in the last 7 days
  const isFreeTrialExpiredInLast7Days = isWithinInterval(Date.now(), {
    start: expiresAt,
    end: addDays(expiresAt, 7),
  })

  // expires soon if is expiring in 3 days
  const isFreeTrialExpiringSoon = isWithinInterval(Date.now(), {
    start: addDays(expiresAt, -3),
    end: expiresAt,
  })

  return (
    <span
      className={`FreeTrialExpiresAtMessage ${
        isFreeTrialExpired ? 'expired' : ''
      } ${isFreeTrialExpiredInLast7Days ? 'expired-recently' : ''} ${
        isFreeTrialExpiringSoon ? 'expires-soon' : ''
      }`}
    >
      {isFreeTrialActive
        ? `Active Free trial until: ${formatDate(expiresAt)}`
        : `Free trial expired: ${formatDistanceToNow(expiresAt, {
            addSuffix: true,
          })}${includeDate ? `, at ${formatDate(expiresAt)}` : ''}`}
    </span>
  )
}

export default React.memo(FreeTrialExpiresAtMessage)
