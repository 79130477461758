import { PageResponse } from 'pluggy-js'
import { action } from 'typesafe-actions'

import {
  MemberInvitation,
  Team,
  TeamFields,
  TeamInvitation,
  TeamMember,
  TeamRole,
  UpdateTeamRequest,
} from './types'

/// Fetch Teams request

export const FETCH_TEAMS_REQUEST = '[Request] Fetch Teams'
export const FETCH_TEAMS_SUCCESS = '[Success] Fetch Teams'
export const FETCH_TEAMS_FAILURE = '[Failure] Fetch Teams'

export const fetchTeamsRequest = (search?: string) =>
  action(FETCH_TEAMS_REQUEST, {
    search,
  })
export const fetchTeamsSuccess = (teams: PageResponse<Team>) =>
  action(FETCH_TEAMS_SUCCESS, { teams })
export const fetchTeamsFailure = (error: string) =>
  action(FETCH_TEAMS_FAILURE, { error })

export type FetchTeamsRequestAction = ReturnType<typeof fetchTeamsRequest>
export type FetchTeamsSuccessAction = ReturnType<typeof fetchTeamsSuccess>
export type FetchTeamsFailureAction = ReturnType<typeof fetchTeamsFailure>

/// Create Team request

export const CREATE_TEAM_REQUEST = '[Request] Create Team'
export const CREATE_TEAM_SUCCESS = '[Success] Create Team'
export const CREATE_TEAM_FAILURE = '[Failure] Create Team'

export const createTeamRequest = (createTeamFields: TeamFields) =>
  action(CREATE_TEAM_REQUEST, {
    createTeamFields,
  })
export const createTeamSuccess = (team: Team) =>
  action(CREATE_TEAM_SUCCESS, { team })
export const createTeamFailure = (error: string) =>
  action(CREATE_TEAM_FAILURE, { error })

export type CreateTeamRequestAction = ReturnType<typeof createTeamRequest>
export type CreateTeamSuccessAction = ReturnType<typeof createTeamSuccess>
export type CreateTeamFailureAction = ReturnType<typeof createTeamFailure>

/// Delete Team request

export const DELETE_TEAM_REQUEST = '[Request] Delete Team'
export const DELETE_TEAM_SUCCESS = '[Success] Delete Team'
export const DELETE_TEAM_FAILURE = '[Failure] Delete Team'

export const deleteTeamRequest = (team: Team) =>
  action(DELETE_TEAM_REQUEST, {
    team,
  })
export const deleteTeamSuccess = (team: Team) =>
  action(DELETE_TEAM_SUCCESS, { team })
export const deleteTeamFailure = (error: string) =>
  action(DELETE_TEAM_FAILURE, { error })

export type DeleteTeamRequestAction = ReturnType<typeof deleteTeamRequest>
export type DeleteTeamSuccessAction = ReturnType<typeof deleteTeamSuccess>
export type DeleteTeamFailureAction = ReturnType<typeof deleteTeamFailure>

/// Create Team Member request

export const CREATE_TEAM_MEMBER_REQUEST = '[Request] Create Team Member'
export const CREATE_TEAM_MEMBER_SUCCESS = '[Success] Create Team Member'
export const CREATE_TEAM_MEMBER_FAILURE = '[Failure] Create Team Member'

export const createTeamMemberRequest = (
  teamId: string,
  createTeamMembersFields: MemberInvitation[],
) =>
  action(CREATE_TEAM_MEMBER_REQUEST, {
    id: teamId,
    createTeamMembersFields,
  })
export const createTeamMemberSuccess = (team: Team) =>
  action(CREATE_TEAM_MEMBER_SUCCESS, { team })
export const createTeamMemberFailure = (error: string) =>
  action(CREATE_TEAM_MEMBER_FAILURE, { error })

export type CreateTeamMemberRequestAction = ReturnType<
  typeof createTeamMemberRequest
>
export type CreateTeamMemberSuccessAction = ReturnType<
  typeof createTeamMemberSuccess
>
export type CreateTeamMemberFailureAction = ReturnType<
  typeof createTeamMemberFailure
>

/// Update Team request

export const UPDATE_TEAM_REQUEST = '[Request] Update Team'
export const UPDATE_TEAM_SUCCESS = '[Success] Update Team'
export const UPDATE_TEAM_FAILURE = '[Failure] Update Team'

export const updateTeamRequest = (
  team: Team,
  updateTeamFields: UpdateTeamRequest,
  updateReason: string,
) => action(UPDATE_TEAM_REQUEST, { team, updateTeamFields, updateReason })
export const updateTeamSuccess = (team: Team, updateReason: string) =>
  action(UPDATE_TEAM_SUCCESS, { team, updateReason })
export const updateTeamFailure = (error: string) =>
  action(UPDATE_TEAM_FAILURE, { error })

export type UpdateTeamRequestAction = ReturnType<typeof updateTeamRequest>
export type UpdateTeamSuccessAction = ReturnType<typeof updateTeamSuccess>
export type UpdateTeamFailureAction = ReturnType<typeof updateTeamFailure>

/// Update Team Member Role request

export const UPDATE_TEAM_MEMBER_ROLE_REQUEST =
  '[Request] Update Team Member Role'
export const UPDATE_TEAM_MEMBER_ROLE_SUCCESS =
  '[Success] Update Team Member Role'
export const UPDATE_TEAM_MEMBER_ROLE_FAILURE =
  '[Failure] Update Team Member Role'

export const updateTeamMemberRoleRequest = (
  team: Team,
  teamMember: TeamMember | TeamInvitation,
  role: TeamRole,
) =>
  action(UPDATE_TEAM_MEMBER_ROLE_REQUEST, {
    team,
    teamMember,
    role,
  })
export const updateTeamMemberRoleSuccess = (team: Team) =>
  action(UPDATE_TEAM_MEMBER_ROLE_SUCCESS, { team })
export const updateTeamMemberRoleFailure = (error: string) =>
  action(UPDATE_TEAM_MEMBER_ROLE_FAILURE, { error })

export type UpdateTeamMemberRoleRequestAction = ReturnType<
  typeof updateTeamMemberRoleRequest
>
export type UpdateTeamMemberRoleSuccessAction = ReturnType<
  typeof updateTeamMemberRoleSuccess
>
export type UpdateTeamMemberRoleFailureAction = ReturnType<
  typeof updateTeamMemberRoleFailure
>

/// Delete Team Member request

export const DELETE_TEAM_MEMBER_REQUEST = '[Request] Delete Team Member'
export const DELETE_TEAM_MEMBER_SUCCESS = '[Success] Delete Team Member'
export const DELETE_TEAM_MEMBER_FAILURE = '[Failure] Delete Team Member'

export const deleteTeamMemberRequest = (
  team: Team,
  teamMember: TeamMember | TeamInvitation,
) =>
  action(DELETE_TEAM_MEMBER_REQUEST, {
    team,
    teamMember,
  })
export const deleteTeamMemberSuccess = (team: Team) =>
  action(DELETE_TEAM_MEMBER_SUCCESS, { team })
export const deleteTeamMemberFailure = (error: string) =>
  action(DELETE_TEAM_MEMBER_FAILURE, { error })

export type DeleteTeamMemberRequestAction = ReturnType<
  typeof deleteTeamMemberRequest
>
export type DeleteTeamMemberSuccessAction = ReturnType<
  typeof deleteTeamMemberSuccess
>
export type DeleteTeamMemberFailureAction = ReturnType<
  typeof deleteTeamMemberFailure
>

// Clear Team error
export const CLEAR_TEAM_ERROR = '[Request] Clear connect item error'
export const clearTeamError = () => action(CLEAR_TEAM_ERROR)
export type ClearTeamErrorAction = ReturnType<typeof clearTeamError>
