import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Button } from '@pluggyai/ui'
import { usePrevious } from '@pluggyai/ui/dist/utils/hooks/usePrevious'
import { Modal } from 'semantic-ui-react'

import { isStringsArray } from '../../lib/utils/validation'
import {
  ApplicationShareLinkFields,
  ApplicationShareLinkFieldsErrors,
} from '../../modules/application/types'
import { buildCustomDemoUrl } from '../../modules/application/utils'
import {
  ApplicationShareLinkCreateForm,
  validateApplicationShareLinkField,
} from './ApplicationShareLinkCreateForm'
import { Props } from './ApplicationShareLinkCreateModal.types'

import './ApplicationShareLinkCreateModal.css'

const initialApplicationShareLinkFields = (): ApplicationShareLinkFields => ({
  customUrlDomain: '',
  expiresAtOption: 'Never',
})

function copyToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .catch((error) => console.error('Failed copy to clipboard', error))
}

const ApplicationShareLinkCreateModal = ({
  isLoading,
  currentApplicationShareLink,
  error,
  onClose,
  onCreateApplicationShareLink,
  onUpdateApplicationShareLink,
  clearApplicationShareLinkError,
}: Props) => {
  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] =
    useState<ApplicationShareLinkFieldsErrors>({})
  const [applicationShareLinkValues, setApplicationShareLinkValues] = useState(
    initialApplicationShareLinkFields(),
  )

  const close = useCallback(() => {
    setFormErrors({})
    setApplicationShareLinkValues(initialApplicationShareLinkFields())
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    close()
  }, [close])

  const handleCopyDemoCustomUrl = useCallback(() => {
    const { customUrlDomain } = applicationShareLinkValues
    const customDemoUrl = buildCustomDemoUrl(customUrlDomain)
    copyToClipboard(customDemoUrl)
  }, [applicationShareLinkValues])

  const handleValuesChange = useCallback(
    (fieldName: keyof ApplicationShareLinkFields, value: unknown) => {
      // clear field error, if any
      setFormErrors((previousFormErrors: ApplicationShareLinkFieldsErrors) => {
        const newFormErrors = { ...previousFormErrors }
        delete newFormErrors[fieldName]
        return newFormErrors
      })

      // stop submitting, if it was happening
      setSubmit(false)

      // set field value
      setApplicationShareLinkValues((values) => ({
        ...values,
        [fieldName]: value,
      }))
    },
    [],
  )

  const previousApplicationShareLinkValues = usePrevious(
    applicationShareLinkValues,
  )
  useEffect(() => {
    if (
      previousApplicationShareLinkValues !== applicationShareLinkValues &&
      error
    ) {
      clearApplicationShareLinkError()
    }
  }, [
    applicationShareLinkValues,
    previousApplicationShareLinkValues,
    error,
    clearApplicationShareLinkError,
  ])

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: ApplicationShareLinkFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(applicationShareLinkValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateApplicationShareLinkField(
            field as keyof ApplicationShareLinkFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateApplicationShareLinkField(
            field as keyof ApplicationShareLinkFields,
            value,
          )

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof ApplicationShareLinkFields] =
            fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        return
      }

      setSubmit(true)

      if (currentApplicationShareLink) {
        // is updating an existing value
        const { applicationId } = currentApplicationShareLink
        onUpdateApplicationShareLink(applicationId, applicationShareLinkValues)
        return
      }

      onCreateApplicationShareLink(applicationShareLinkValues)
    },
    [
      applicationShareLinkValues,
      onCreateApplicationShareLink,
      isLoading,
      onUpdateApplicationShareLink,
      currentApplicationShareLink,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleClose()
    }
  }, [error, isLoading, submit, handleClose])

  return (
    <Modal
      className={'ApplicationShareLinkCreateModal'}
      onClose={handleClose}
      open={true}
      closeIcon
      size={'small'}
    >
      <Modal.Header>
        Demo link
        <p>
          Once you save it, you will see it on “Demo links” page in case you
          need to edit it.
        </p>
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <ApplicationShareLinkCreateForm
          initialValue={currentApplicationShareLink}
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={applicationShareLinkValues}
          errors={formErrors}
          isLoading={isLoading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button link onClick={handleCopyDemoCustomUrl}>
          Copy Link
        </Button>
        <Button primary onClick={handleSubmit} loading={isLoading}>
          {currentApplicationShareLink ? 'Update' : 'Create'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(ApplicationShareLinkCreateModal)
