import axios, { AxiosInstance } from 'axios'

import { retrieveAuthResult } from '../../modules/auth/storage'
import { urls, UrlsMap } from '../constants/urls'

export class BaseService {
  protected urls: UrlsMap
  private service: AxiosInstance | undefined

  constructor() {
    this.urls = urls
  }

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      this.service = axios.create()
      // setup interceptor to lazily retrieve pluggy apiKey from storage
      this.service.interceptors.request.use(function appendAuthHeader(config) {
        const authResult = retrieveAuthResult()
        if (!authResult) {
          // no 'auth' stored
          throw new Error("Missing 'auth' in storage")
        }

        const { apiKey } = authResult
        if (!apiKey) {
          // no pluggy apiKey stored in auth data
          throw new Error('missing pluggy-api auth.apiKey in storage')
        }
        const { headers } = config
        // add apiKey to request
        if (!headers) {
          throw new Error('Unexpectedly config.headers is undefined')
        }
        headers['X-API-KEY'] = apiKey
        return config
      })
    }
    return this.service
  }
}
