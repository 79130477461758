import { AnyAction } from 'redux'

import { ConnectorId } from '../../../lib/ConnectorId'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { ConnectorState } from './reducer'

export const getState = (state: RootState): ConnectorState =>
  state.connector as ConnectorState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const isLoadingTypeByConnectorId = (
  state: RootState,
  type: string,
  connectorId: ConnectorId,
): boolean => {
  const loading = getLoading(state)

  return loading.some(
    (action: AnyAction) =>
      action.type === type && action.payload?.id === connectorId,
  )
}
