import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CREATE_INCIDENT_FAILURE,
  CREATE_INCIDENT_REQUEST,
  CREATE_INCIDENT_SUCCESS,
  CreateIncidentFailureAction,
  CreateIncidentRequestAction,
  CreateIncidentSuccessAction,
  FETCH_INCIDENTS_FAILURE,
  FETCH_INCIDENTS_REQUEST,
  FETCH_INCIDENTS_SUCCESS,
  FetchIncidentsFailureAction,
  FetchIncidentsRequestAction,
  FetchIncidentsSuccessAction,
  UPDATE_INCIDENT_FAILURE,
  UPDATE_INCIDENT_REQUEST,
  UPDATE_INCIDENT_SUCCESS,
  UpdateIncidentFailureAction,
  UpdateIncidentRequestAction,
  UpdateIncidentSuccessAction,
} from './actions'
import { Incident } from './types'

export type IncidentsState = {
  data: Incident[] | null
  loading: LoadingState
  error: string | null
}

export const INITIAL_STATE: IncidentsState = {
  data: null,
  loading: [],
  error: null,
}

export type ExecutionReducerAction =
  | FetchIncidentsSuccessAction
  | FetchIncidentsRequestAction
  | FetchIncidentsFailureAction
  | CreateIncidentSuccessAction
  | CreateIncidentRequestAction
  | CreateIncidentFailureAction
  | UpdateIncidentSuccessAction
  | UpdateIncidentRequestAction
  | UpdateIncidentFailureAction

export function incidentReducer(
  state: IncidentsState = INITIAL_STATE,
  action: ExecutionReducerAction,
): IncidentsState {
  switch (action.type) {
    case UPDATE_INCIDENT_REQUEST:
    case FETCH_INCIDENTS_REQUEST:
    case CREATE_INCIDENT_REQUEST:
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_INCIDENTS_SUCCESS:
      const { incidents } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: incidents,
      }
    case UPDATE_INCIDENT_SUCCESS:
    case CREATE_INCIDENT_SUCCESS:
      const { incident } = action.payload

      // update or insert incident on current state
      const updatedIncidents = [...(state.data || [])]
      const i = updatedIncidents.findIndex(
        (incident_) => incident_.id === incident.id,
      )
      if (i > -1) {
        // found -> update (override)
        updatedIncidents[i] = incident
      } else {
        // not found -> insert
        updatedIncidents.push(incident)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: updatedIncidents,
      }
    case UPDATE_INCIDENT_FAILURE:
    case FETCH_INCIDENTS_FAILURE:
    case CREATE_INCIDENT_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    default:
      return state
  }
}
