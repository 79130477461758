import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { all, call, takeEvery } from 'redux-saga/effects'

import { page } from './utils'

/**
 * Track page navigation
 */
function* handleLocationChange(action: LocationChangeAction) {
  const {
    payload: { isFirstRendering },
  } = action
  yield call(() => page(undefined, { isFirstRendering }))
}

export function* analyticsSaga() {
  yield all([takeEvery(LOCATION_CHANGE, handleLocationChange)])
}
