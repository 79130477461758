import { AuthApiApplication } from '../application/types'
import { AuthApiUser } from '../user/types'

export const TEAM_ROLES = ['OWNER', 'ADMIN', 'MEMBER'] as const
export type TeamRole = (typeof TEAM_ROLES)[number]

export type TeamMember = AuthApiUser & {
  teamUser: {
    role: TeamRole
  }
}

export type TeamInvitation = Pick<
  AuthApiUser,
  'email' | 'id' | 'updatedAt' | 'createdAt'
> & {
  role: TeamRole
}

/**
 * Helper type-guard function to decide if a team 'member' is
 * actually a TeamMember, or a TeamInvitation
 * @param member
 */
export function isTeamMember(
  member: TeamMember | TeamInvitation,
): member is TeamMember {
  return typeof (member as TeamMember).teamUser !== 'undefined'
}

/**
 * Note: this type should be updated as new products are
 * added in pluggy-connectors -> @pluggyai/commons
 */
export const PRODUCT_TYPES = [
  'ACCOUNTS',
  'CREDIT_CARDS',
  'TRANSACTIONS',
  'INVESTMENTS',
  'IDENTITY',
  'INVESTMENTS_TRANSACTIONS',
  'PAYMENT_DATA',
  'BROKERAGE_NOTE',
  'OPPORTUNITIES',
  'PORTFOLIO',
  'INCOME_REPORTS',
  'LOANS',
  'BENEFITS',
  'EXCHANGE_OPERATIONS',
] as const
export type ProductType = (typeof PRODUCT_TYPES)[number]

export function isProductType(value: unknown): value is ProductType {
  return PRODUCT_TYPES.includes(value as ProductType)
}

/**
 * List of 'FREE' products that always must be included in any 'subscription'
 * Note: this list should be updated as new FREE products are added in pluggy-api
 */
export const FREE_PRODUCTS: readonly ProductType[] = [
  'ACCOUNTS',
  'CREDIT_CARDS',
  'TRANSACTIONS',
]

/**
 * List of 'FULL' products that must be included, as a minimum, in a full (or superior) subscription.
 * Note: this list should be updated as new "FULL" products are added in pluggy-api
 */
export const FULL_PRODUCTS: readonly ProductType[] = [
  ...FREE_PRODUCTS,
  'INVESTMENTS',
  'IDENTITY',
]

/**
 * Note: this type should be updated as new custom features are added in pluggy-api
 */
export const FEATURE_TYPES = [
  /**
   * Include 'category' in CoreApi GET /transactions response
   * By default, this is being included in 'FULL' -or higher- subscription package.
   */
  'CATEGORIZATION',
  /**
   * If set, client is allowed to create Items through Pluggy API directly
   * By default, this is being included in 'FULL' -or higher- subscription package.
   */
  'CREATE_ITEMS_API',
  /**
   * If set, client can access all connectors, otherwise only the 'FREE' ones.
   * By default, this is being included in 'FULL' -or higher- subscription package.
   */
  'ALL_CONNECTORS',
  /**
   * Include 'merchant' in CoreApi GET /transactions response
   * This is an optional extra feature for a 'FULL' subscription, not included by default.
   */
  'MERCHANT_EXTRACTION',
  /**
   * If set, CoreApi Client will send 'companyName' field to connectors, to be included in alerts/messages related to device registration.
   * Otherwise, the default 'Pluggy' name will be shown
   * Optional feature, intended for special contracts.
   */
  'CUSTOM_COMPANY_DEVICE_NAME',
  /**
   * If set, clients will force Santander PJ connector to go through the WEB flow.
   * Used only for very specific cases and/or internal tests.
   * Not intended for configuration by operations Team.
   */
  'SANTANDER_PJ_WEB',
  /**
   *  Include 'metadata' field in GET /investments response.
   *  This field contains relevant data for "Previdencia" Investment Type (optional feature, for special contracts).
   */
  'INVESTMENT_METADATA',
  /**
   * Include OF Connectors on the GET /connectors response
   * Allows customers to access the Open Finance / Regulated Connectors
   */
  'OPEN_FINANCE_CONNECTORS_FLOW',
  /**
   * Include Credit Card Processor Connectors on the GET /connectors response
   */
  'CREDIT_CARD_PROCESSOR_CONNECTORS',
  /**
   * Allow the customer to interact with the payment's API.
   */
  'PAYMENT_INITIATION',
  /**
   * Allow the customer to interact with the bulk payments API.
   */
  'BULK_PAYMENTS',
] as const
export type FeatureType = (typeof FEATURE_TYPES)[number]

export function isFeatureType(value: unknown): value is FeatureType {
  return FEATURE_TYPES.includes(value as FeatureType)
}

/**
 * All Features available,
 * except specific features that have to be added strictly manually.
 * - 'SANTANDER_PJ_WEB' which is a very special, technical case. Should be added via DB directly only for now.
 * - 'INVESTMENT_METADATA' should only be enabled upon business agreement, signing a specific contract for Security "Previdencia" feature.
 */
const ALL_FEATURES = FEATURE_TYPES.filter(
  (feature: FeatureType) =>
    feature !== 'SANTANDER_PJ_WEB' &&
    feature !== 'INVESTMENT_METADATA' &&
    feature !== 'OPEN_FINANCE_CONNECTORS_FLOW' &&
    feature !== 'CREDIT_CARD_PROCESSOR_CONNECTORS' &&
    feature !== 'PAYMENT_INITIATION' &&
    feature !== 'BULK_PAYMENTS',
)

/**
 * Features to include in 'Free' subscription
 */
export const FREE_FEATURES: readonly FeatureType[] = []

/**
 * Features to include in 'Full' subscription
 */
export const FULL_FEATURES: readonly FeatureType[] = [
  'CATEGORIZATION',
  'CREATE_ITEMS_API',
  'ALL_CONNECTORS',
]

/**
 * The possible updateFrequencyInHours values
 */
export const UPDATE_FREQUENCY_OPTIONS: number[] = [
  0, 4, 6, 8, 12, 24, 30, 48, 96,
]

export const SUBSCRIPTION_PLANS = [
  // the standard subscription, with limited features
  'FREE',
  // the FREE subscription, plus some FULL paid features, but not all of them
  'FREE_PLUS',
  // Has all features enabled, but with an expiration date, then is set back to FREE
  'TRIAL',
  // Basic paid subscription with most features/products
  'FULL',
  // 'Full' subscription with some or all of the additional optional features
  'FULL_PLUS',
] as const

export type SubscriptionPlan = (typeof SUBSCRIPTION_PLANS)[number]

export function isSubscriptionPlan(value: unknown): value is SubscriptionPlan {
  return SUBSCRIPTION_PLANS.includes(value as SubscriptionPlan)
}

export class PaymentsConfigurationPricing {
  pixIn?: number
  pixOut?: number
  boletoOut?: number
}

export class PaymentsConfiguration {
  customerPricing?: PaymentsConfigurationPricing
  pluggyPricing?: PaymentsConfigurationPricing
}

export type Subscription = {
  features: FeatureType[]
  products: ProductType[]
  production: boolean
  updateFrequencyInHours?: number
  freeTrialExpiresAt?: string | null
  extraFreeTrialExpiresAt?: string | null
  plan: SubscriptionPlan
  paymentsConfiguration?: PaymentsConfiguration
}

export const DEFAULT_SUBSCRIPTION_UPDATE_FREQUENCY = 0 // Never

export const FREE_SUBSCRIPTION: Subscription = {
  products: [...FREE_PRODUCTS],
  features: [...FREE_FEATURES],
  production: false,
  updateFrequencyInHours: DEFAULT_SUBSCRIPTION_UPDATE_FREQUENCY,
  plan: 'FREE',
}

export const FULL_SUBSCRIPTION: Subscription = {
  products: [...FULL_PRODUCTS],
  features: [...FULL_FEATURES],
  production: false,
  plan: 'FULL',
}

/**
 * Subscription with all features and all products, intended as default for shared Demo application
 */
export const SHARED_DEMO_FULL_COMPLETE_SUBSCRIPTION: Subscription = {
  features: [...FEATURE_TYPES],
  products: [...PRODUCT_TYPES],
  production: false,
  plan: 'FULL',
}

/**
 * Subscription with all features and products, but with an expiresAt date, after which it will be set back to FREE.
 *
 * @param expiresAt
 * @constructor
 */
export const TRIAL_SUBSCRIPTION = (
  expiresAt: Date,
  extraTrialFeatures: FeatureType[] = [],
): Subscription => {
  return {
    features: [...ALL_FEATURES, ...extraTrialFeatures],
    products: [...PRODUCT_TYPES],
    production: false,
    freeTrialExpiresAt: new Date(expiresAt).toISOString(),
    plan: 'TRIAL',
  }
}

export const FREE_TRIAL_MINIMUM_LENGTH_IN_DAYS = 14
export const FREE_TRIAL_INITIAL_LENGTH_IN_DAYS = 15
export const FREE_TRIAL_MAXIMUM_EXPIRED_RECENTLY_DAYS = 15
export const FREE_TRIAL_MAXIMUM_LENGTH_AFTER_CREATED_AT_DAYS = 45

export type Team = {
  id: string
  name: string
  imageUrl: string | null
  members: TeamMember[]
  invitations: TeamInvitation[]
  cnpj: string | null
  subscription: Subscription
  createdAt: string
  updatedAt: string
  integrations?: {
    iniciador?: {
      clientId?: string
      clientSecret?: string
    }
    iniciadorPayments?: PaymentInitiationCredentials
  }
  applications: AuthApiApplication[]
}

export type MemberInvitation = {
  email: string
  role: TeamRole
}

export type TeamFields = {
  name: string
  imageUrl: string
  members: MemberInvitation[]
  cnpj: string
}

export type UpdateTeamRequest = Partial<Pick<Team, 'subscription'>> & {
  iniciadorCredentials?: string
  integrations?: {
    iniciadorPayments?: PaymentInitiationCredentials
  }
}

export type CreateTeamRequest = {
  name: string
  imageUrl?: string
  members?: MemberInvitation[]
  cnpj?: string
}

export type CreateTeamMemberRequest = MemberInvitation

export type UpdateTeamMemberRoleRequest = {
  role: TeamRole
}

export type UpdateTeamInvitationRoleRequest = {
  role: TeamRole
}

export type TeamFieldsErrors = {
  name?: string
  imageUrl?: string
  members?: string[]
  cnpj?: string
}

export type PaymentInitiationCredentials = {
  clientId?: string
  clientSecret?: string
}

export function isMembersInvitationsArray(
  values: unknown,
): values is MemberInvitation[] {
  return (
    Array.isArray(values) &&
    values.every(
      (value) =>
        typeof value.email === 'string' && typeof value.role === 'string',
    )
  )
}
