import { connect } from 'react-redux'

import { UPDATE_CUSTOMIZATION_REQUEST } from '../../../../../modules/customization/actions'
import { getLoading as getCustomizationLoading } from '../../../../../modules/customization/selectors'
import { RootState } from '../../../../../modules/reducer'
import { FETCH_TEAMS_REQUEST } from '../../../../../modules/team/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../../../../modules/team/selectors'
import TeamSearchSection from './TeamSearchSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamSearchSection.types'

const mapState = (state: RootState): MapStateProps => ({
  isInitialTeamsLoading:
    getData(state) === null &&
    isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST),
  isCustomizationUpdating: isLoadingType(
    getCustomizationLoading(state),
    UPDATE_CUSTOMIZATION_REQUEST,
  ),
  error: getError(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(TeamSearchSection)
