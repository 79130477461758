import { all, delay, put, takeEvery } from 'redux-saga/effects'

import {
  ADD_NOTIFICATION,
  AddNotificationAction,
  removeNotificationAction,
} from './actions'

function* handleAddNotificationAction(action: AddNotificationAction) {
  const {
    notification: { duration, id },
  } = action.payload

  if (duration !== undefined) {
    yield delay(duration)
    yield put(removeNotificationAction(id))
  }
}

export function* notificationSaga() {
  yield all([takeEvery(ADD_NOTIFICATION, handleAddNotificationAction)])
}
