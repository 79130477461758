import { connect } from 'react-redux'

import {
  clearApplicationShareLinkError,
  CREATE_APPLICATION_SHARE_LINK_REQUEST,
  createApplicationShareLinkRequest,
  UPDATE_APPLICATION_SHARE_LINK_REQUEST,
  updateApplicationShareLinkRequest,
} from '../../modules/application/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/application/selectors'
import { ApplicationShareLinkFields } from '../../modules/application/types'
import { RootState } from '../../modules/reducer'
import ApplicationShareLinkCreateModal from './ApplicationShareLinkCreateModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationShareLinkCreateModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading:
    isLoadingType(getLoading(state), CREATE_APPLICATION_SHARE_LINK_REQUEST) ||
    isLoadingType(getLoading(state), UPDATE_APPLICATION_SHARE_LINK_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateApplicationShareLink: (
    createApplicationShareLinkFields: ApplicationShareLinkFields,
  ) =>
    dispatch(
      createApplicationShareLinkRequest(createApplicationShareLinkFields),
    ),
  clearApplicationShareLinkError: () =>
    dispatch(clearApplicationShareLinkError()),
  onUpdateApplicationShareLink: (
    applicationId: string,
    updateApplicationShareLinkFields: ApplicationShareLinkFields,
  ) =>
    dispatch(
      updateApplicationShareLinkRequest(
        applicationId,
        updateApplicationShareLinkFields,
      ),
    ),
})

export default connect(mapState, mapDispatch)(ApplicationShareLinkCreateModal)
