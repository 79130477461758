import React from 'react'

import { Toast } from '@pluggyai/ui'

import { Props } from './Toasts.types'

import './Toasts.css'

const Toasts = (props: Props) => {
  const { notifications, onRemoveNotification } = props

  return (
    <div className={'Toasts'}>
      {notifications.map((notification) => {
        const { id, message, open, title, level } = notification
        return (
          <Toast
            key={id}
            title={title}
            message={message}
            open={open}
            direction={'right'}
            type={level}
            onClose={() => onRemoveNotification(id)}
          />
        )
      })}
    </div>
  )
}

export default React.memo(Toasts)
