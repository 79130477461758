import { AxiosResponse } from 'axios'

import { Application } from '../../modules/application/types'
import { BaseService } from './BaseService'
import { replaceUrlParams } from './utils'

class MicroAuthService extends BaseService {
  async getAllAccess(clientId: string): Promise<Application[]> {
    const {
      data: { results },
    } = await this.getServiceInstance().get(
      `${this.urls.GET_ACCESS}?clientId=${clientId}`,
    )
    return results
  }

  async revokeAccess(id: number): Promise<AxiosResponse<Application>> {
    const url = replaceUrlParams(this.urls.DELETE_ACCESS, { id })
    return this.getServiceInstance().delete(url)
  }

  async addAccess(
    email: string,
    clientId: string,
  ): Promise<AxiosResponse<Application>> {
    return this.getServiceInstance().post(this.urls.POST_ACCESS, {
      email,
      clientId,
    })
  }
}

export const microauthService = new MicroAuthService()
