import { ConnectorId } from '../../../lib/ConnectorId'

export const CONNECTOR_STATUSES = ['OFFLINE', 'ONLINE', 'UNSTABLE'] as const
export type ConnectorStatus = (typeof CONNECTOR_STATUSES)[number]

export type ConnectorType =
  | 'PERSONAL_BANK'
  | 'BUSINESS_BANK'
  | 'INVOICE'
  | 'INVESTMENT'

export type CredentialSelectOption = {
  value: string
  label: string
}

export type ConnectorCredential = {
  label: string
  name: string
  type: 'text' | 'password' | 'number' | 'image' | 'select'
  data?: string
  mfa?: boolean
  validation?: string
  validationMessage?: string
  placeholder?: string
  optional?: boolean
  // Assistive information to help the user provide us the credential.
  assistiveText?: string
  // Available options if credential is of type 'select'
  options?: CredentialSelectOption[]
}

export type ConnectorHealth = {
  status: ConnectorStatus
  stage: string
}

export type Connector = {
  id: ConnectorId
  name: string
  institutionUrl: string
  imageUrl?: string
  primaryColor?: string
  type?: ConnectorType
  country?: string
  credentials: ConnectorCredential[]
  hasMFA?: boolean
  products?: string[]
  oauthUrl?: string
  health?: ConnectorHealth
}

export type UpdateConnectorRequest = {
  status: ConnectorStatus
}
