import React from 'react'

import { Container } from 'semantic-ui-react'

import { Navbar } from '../Navbar'
import { Props } from './Page.types'

import './Page.css'

const Page = (props: Props) => {
  const { className, children } = props

  const classNames = ['Page']
  if (className !== undefined) {
    classNames.push(className)
  }

  return (
    <>
      <Navbar />
      <Container className={classNames.join(' ')}>{children}</Container>
    </>
  )
}

export default React.memo(Page)
