import React, { useCallback, useState } from 'react'

import { Form, SearchBox } from '@pluggyai/ui'
import { Segment } from 'semantic-ui-react'

import { Props } from './TeamSearchSection.types'

import './TeamSearchSection.css'

const TeamSearchSection = ({
  error,
  isInitialTeamsLoading,
  isCustomizationUpdating,
  onSearchTeam,
}: Props) => {
  const [searchTeamId, setSearchTeamId] = useState('')
  const [searchError, setSearchError] = useState<string | null>(null)

  const handleSearchBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchError(null)
      setSearchTeamId(event.target.value.trim())
    },
    [],
  )

  const handleClear = useCallback(() => {
    if (isInitialTeamsLoading || isCustomizationUpdating) {
      return
    }
    setSearchTeamId('')
    onSearchTeam(null)
  }, [onSearchTeam, isInitialTeamsLoading, isCustomizationUpdating])

  const handleSearchSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isInitialTeamsLoading) {
        // is loading teams, wait
        return
      }

      event.preventDefault()

      // validate fields values
      if (searchTeamId.length === 0) {
        setSearchError("Team id can't be empty")
        return
      }

      if (searchTeamId.length !== 36) {
        setSearchError('Team id must be an UUID')
        return
      }

      onSearchTeam(searchTeamId)
      setSearchError(null)
    },
    [isInitialTeamsLoading, searchTeamId, onSearchTeam],
  )

  return (
    <Segment className={'TeamSearchSection'}>
      <Form className={'team-search-form'} onSubmit={handleSearchSubmit}>
        <SearchBox
          placeholder={'Search Team id'}
          size="medium"
          value={searchTeamId}
          onChange={handleSearchBoxChange}
          onClear={handleClear}
          loading={isInitialTeamsLoading}
          disabled={isCustomizationUpdating}
          error={Boolean(searchError || error)}
        />
        <div className={'error-message'}>{searchError || ''}</div>
      </Form>
    </Segment>
  )
}

export default React.memo(TeamSearchSection)
