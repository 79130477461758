import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { AccessState } from './reducer'
import { Access } from './types'

export const getState = (state: RootState): AccessState =>
  state.access as AccessState
export const getData = (state: RootState): Record<string, Access> =>
  getState(state).data
export const getLoading = (state: RootState): LoadingState =>
  getState(state).loading
export const getError = (state: RootState): string | null =>
  getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)
