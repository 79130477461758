import { connect } from 'react-redux'

import {
  FETCH_APPLICATIONS_SHARE_LINKS_REQUEST,
  fetchApplicationsRequest,
  fetchApplicationsShareLinksRequest,
} from '../../modules/application/actions'
import {
  getCoreApiApplications,
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import ApplicationsShareLinksPage from './ApplicationsShareLinksPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationsShareLinksPage.types'

const mapState = (state: RootState): MapStateProps => ({
  applicationsShareLinks: getData(state).applicationsShareLinks,
  coreApiApplications: getCoreApiApplications(state),
  error: getError(state),
  isLoading: isLoadingType(
    getLoading(state),
    FETCH_APPLICATIONS_SHARE_LINKS_REQUEST,
  ),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchApplicationsShareLinks: () =>
    dispatch(fetchApplicationsShareLinksRequest()),
  onFetchCoreApiApplications: () => dispatch(fetchApplicationsRequest()),
})

export default connect(mapState, mapDispatch)(ApplicationsShareLinksPage)
