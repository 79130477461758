import React, { useEffect, useState } from 'react'

import { Button } from '@pluggyai/ui'
import {
  Container,
  Dropdown,
  Image,
  Menu,
  MenuItemProps,
  Placeholder,
} from 'semantic-ui-react'

import { AuthResult } from '../../modules/auth/types'
import { locations } from '../../modules/routing/locations'
import { Props } from './Navbar.types'

import './Navbar.css'

export const UserAvatar = ({
  auth,
  className,
  isLoading = false,
}: {
  auth: AuthResult
  isLoading?: boolean
  className?: string
}): JSX.Element | null =>
  auth.profile.picture ? (
    <Image
      avatar
      inline
      src={auth.profile.picture}
      alt="Profile"
      className={`oval ${className || ''}`}
      width="50"
    />
  ) : isLoading ? (
    <Placeholder className={`oval ${className || ''}`}>
      <Placeholder.Image />
    </Placeholder>
  ) : (
    <div className={`oval ui avatar inline image ${className || ''}`} />
  )

const Navbar = ({ auth, location, onLogout, onNavigate }: Props) => {
  const [activeItem, setActiveItem] = useState<string | null>(null)

  function handleItemClick(
    event: React.MouseEvent<HTMLAnchorElement>,
    { name }: MenuItemProps,
  ) {
    event.preventDefault()
    if (name === undefined) {
      console.error("No name specified, can't navigate")
      return
    }
    setActiveItem(name)
    if (!onNavigate) {
      console.error('No onNavigate() handler defined')
      return
    }
    onNavigate(name)
  }

  function handleLogoutClick() {
    if (!onLogout) {
      return
    }
    onLogout()
  }

  useEffect(() => {
    if (!location) {
      return
    }
    const { pathname } = location
    setActiveItem(pathname)
  }, [location])

  return (
    <Menu className={'Navbar'} fixed="top">
      <Container>
        <Menu.Menu position={'left'} className={'logo-container'}>
          <Menu.Item header>
            <Image
              as={'a'}
              onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault()
                if (onNavigate) {
                  onNavigate(locations.root())
                }
              }}
              href={locations.root()}
              src="./logo.png"
              alt="Pluggy logo"
              width="180"
              style={{ marginRight: '1.5em' }}
            />
          </Menu.Item>
        </Menu.Menu>

        <Menu.Item
          as="a"
          name={locations.root()}
          href={locations.root()}
          active={activeItem === locations.root()}
          onClick={handleItemClick}
        >
          Overview
        </Menu.Item>
        <Menu.Item
          as="a"
          name={locations.applications()}
          href={locations.applications()}
          active={activeItem === locations.applications()}
          onClick={handleItemClick}
        >
          Applications
        </Menu.Item>
        <Menu.Item
          as="a"
          name={locations.demoLinks()}
          href={locations.demoLinks()}
          active={activeItem === locations.demoLinks()}
          onClick={handleItemClick}
        >
          Demo Links
        </Menu.Item>
        <Menu.Item
          as="a"
          name={locations.connectors()}
          href={locations.connectors()}
          active={activeItem === locations.connectors()}
          onClick={handleItemClick}
        >
          Connectors
        </Menu.Item>
        <Menu.Item
          as="a"
          name={locations.items()}
          href={locations.items()}
          active={activeItem === locations.items()}
          onClick={handleItemClick}
        >
          Items
        </Menu.Item>
        <Menu.Menu position={'right'} className={'borderless user-menu'}>
          <Menu.Item fitted="horizontally" className={'borderless'}>
            {auth && (
              <span className="user-info">
                <span className={'h3 user-name'}>{auth.profile.name}</span>
                <UserAvatar auth={auth} />
              </span>
            )}
          </Menu.Item>
          {auth && (
            <Dropdown item simple inline text={''} className={'borderless'}>
              <Dropdown.Menu size={'large'} className={'borderless'}>
                <Dropdown.Item>
                  <Button link onClick={handleLogoutClick}>
                    Log out
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  )
}
export default React.memo(Navbar)
