import { connect } from 'react-redux'

import { removeNotificationAction } from '../../modules/notification/actions'
import { getData } from '../../modules/notification/selectors'
import { RootState } from '../../modules/reducer'
import Toasts from './Toasts'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Toasts.types'

const mapState = (state: RootState): MapStateProps => ({
  notifications: getData(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onRemoveNotification: (id: string) => dispatch(removeNotificationAction(id)),
})

export default connect(mapState, mapDispatch)(Toasts)
