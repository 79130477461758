import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { addDays, addMonths, isAfter, isBefore, isMatch } from 'date-fns'
import i18n from 'i18next'

import { isValidEmail } from '../../../lib/utils/validation'
import {
  ApplicationShareLinkFields,
  ExpiresAtOption,
} from '../../../modules/application/types'

/**
 * Regex to validate a single domain part name (no dots).
 * Allow middle dashes in the middle only. Minimum length: 2.
 */
const VALID_DOMAIN_PART_REGEX = /^[\w-]+\w$/

/**
 * Regex to validate a domain. Source: https://stackoverflow.com/a/26093611/6279385
 */
const VALID_DOMAIN_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]?(?:\.[a-zA-Z]{2,})+$/

const validations: Record<
  keyof ApplicationShareLinkFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  customUrlDomain: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (!value) {
      return i18n.t('applicationShareLink.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 40
    if (value.length > maxLength) {
      return i18n.t('applicationShareLink.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }

    if (!new RegExp(VALID_DOMAIN_PART_REGEX).test(value)) {
      return i18n.t('applicationShareLink.form.error.invalid-domain-part', {
        fieldName,
        length: maxLength,
      })
    }

    return undefined
  },
  emails: (fieldName: string, value: unknown): string | undefined => {
    if (value === null) {
      // value not included
      return undefined
    }
    if (typeof value !== 'string') {
      throw new Error("Not a string value (should be a string joined by ','")
    }
    const emailsArray = value.split(',')
    if (emailsArray.length === 0) {
      // no members to invite, OK because it's optional
      return undefined
    }

    const allEmptyEmails = emailsArray.every(
      (email) => email.trim().length === 0,
    )

    const errors = emailsArray.map((email, i) => {
      if (allEmptyEmails) {
        // no email included, NOT OK because we need at least one
        return i18n.t('applicationShareLink.form.error.empty', {
          fieldName,
        })
      }

      if (email.length === 0) {
        return i18n.t('applicationShareLink.form.error.empty', {
          fieldName,
        })
      }

      if (!isValidEmail(email)) {
        return i18n.t('applicationShareLink.form.error.email', {
          fieldName,
        })
      }

      // check if value was already included in the list
      const previousEmails = emailsArray.slice(0, i)
      if (previousEmails.includes(email)) {
        return i18n.t('applicationShareLink.form.error.list-repeated', {
          fieldName,
        })
      }

      return undefined
    })

    return errors.join(',')
  },
  emailDomain: (fieldName: string, value: unknown): string | undefined => {
    if (value === null) {
      // value not included
      return undefined
    }
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }

    if (value.length === 0) {
      return i18n.t('applicationShareLink.form.error.empty', {
        fieldName,
      })
    }

    if (!new RegExp(VALID_DOMAIN_REGEX).test(value)) {
      return i18n.t('applicationShareLink.form.error.domain', {
        fieldName,
      })
    }

    return undefined
  },
  expiresAtOption: (
    _fieldName: string,
    _value: unknown,
  ): string | undefined => {
    // never return error, as this was already validated due to coming from a "select" input
    return undefined
  },
  expiresAt: (fieldName: string, value: unknown): string | undefined => {
    if (value === null) {
      // value not included
      return undefined
    }
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }

    if (value.length === 0) {
      return i18n.t('applicationShareLink.form.error.empty', {
        fieldName,
      })
    }

    if (!isMatch(value, 'yyyy-MM-dd')) {
      return i18n.t('applicationShareLink.form.error.invalid-format', {
        fieldName,
        expectedFormat: 'yyyy-MM-dd',
      })
    }

    return undefined
  },
}

export function validateApplicationShareLinkField(
  fieldName: keyof ApplicationShareLinkFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(
    `applicationShareLink.form.field.${fieldName}.label`,
  )
  return validations[fieldName](fieldLabel, value)
}

const SHARE_LINK_TYPE_OPTIONS = [
  'EVERYONE',
  'EMAIL_DOMAIN',
  'EMAILS_LIST',
] as const
export type ShareLinkTypeOption = (typeof SHARE_LINK_TYPE_OPTIONS)[number]

export function buildShareLinkTypeOption(value: ShareLinkTypeOption): Option {
  return {
    id: value,
    name: i18n.t(
      `applicationShareLink.form.field.shareLinkType.option.${value}`,
    ),
  }
}

export function buildShareLinkTypeOptions(): [Option[], Option] {
  const options: Option[] = SHARE_LINK_TYPE_OPTIONS.map(
    buildShareLinkTypeOption,
  )
  const [defaultOption] = options
  return [options, defaultOption]
}

export function mapExpiresAtDateToOption(
  expiresAt: string | null,
): ExpiresAtOption {
  if (!expiresAt) {
    return 'Never'
  }
  const expiresAtDate = new Date(expiresAt)
  const now = new Date()
  const dateIn1Month = addMonths(now, 1)
  const dateIn3Months = addMonths(now, 3)
  const dateIn7Days = addDays(now, 7)

  if (isAfter(expiresAtDate, now) && isBefore(expiresAtDate, dateIn7Days)) {
    return '7 days'
  }
  if (
    isAfter(expiresAtDate, dateIn7Days) &&
    isBefore(expiresAtDate, dateIn1Month)
  ) {
    return '1 month'
  }
  if (
    isAfter(expiresAtDate, dateIn1Month) &&
    isBefore(expiresAtDate, dateIn3Months)
  ) {
    return '3 months'
  }

  return 'Never'
}
