import axios, { AxiosResponse } from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { CustomizationsService } from '../../lib/api/auth-api/CustomizationService'
import { getAuth0AccessToken } from '../auth/selectors'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import {
  FETCH_CUSTOMIZATION_REQUEST,
  fetchCustomizationFailure,
  FetchCustomizationRequestAction,
  fetchCustomizationSuccess,
  UPDATE_CUSTOMIZATION_REQUEST,
  updateCustomizationFailure,
  UpdateCustomizationRequestAction,
  updateCustomizationSuccess,
} from './actions'
import { getCustomizationByTeamId } from './selectors'
import { Customization, UpdateCustomizationValues } from './types'

function* handleFetchCustomizationRequest(
  action: FetchCustomizationRequestAction,
) {
  const {
    payload: { team },
  } = action
  const { id: teamId } = team

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not fetch customization')
    }
    const customizationsService = new CustomizationsService(accessToken)

    const { data: customization }: AxiosResponse<Customization> = yield call(
      () => customizationsService.getCustomization(teamId, true),
    )

    yield put(fetchCustomizationSuccess(team, customization))
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }

    errorMessage = `Could not retrieve Customization: ${errorMessage} ${
      status ? `(${status})` : ''
    }`

    yield put(fetchCustomizationFailure(team, errorMessage))
  }
}

function* handleUpdateCustomizationRequest(
  action: UpdateCustomizationRequestAction,
) {
  const {
    payload: { team, updateCustomizationFields },
  } = action

  const { id: teamId } = team

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const customizationsService = new CustomizationsService(accessToken)
    const currentCustomization: Customization | null = yield select((state) =>
      getCustomizationByTeamId(state, teamId),
    )
    if (!currentCustomization) {
      throw new Error("No customization in state, can't update")
    }

    // TODO: review if this extra object is needed or we can reuse
    const updateCustomizationValues: UpdateCustomizationValues = {
      termsAndConditions: updateCustomizationFields.termsAndConditions,
      privacyPolicy: updateCustomizationFields.privacyPolicy,
    }

    // submit update request
    const { data: updatedCustomization }: AxiosResponse<Customization> =
      yield call(() =>
        customizationsService.updateCustomization(
          teamId,
          updateCustomizationValues,
        ),
      )

    yield put(
      addNotificationAction({
        title: 'Customization Updated',
        message: `Updated customization values for team id ${teamId}.`,
        duration: 4000,
        level: 'succeed',
      }),
    )

    yield put(updateCustomizationSuccess(team, updatedCustomization))
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }

    errorMessage = `Could not update Customization: ${errorMessage} ${
      status ? `(${status})` : ''
    }`
    const notificationOptions: NotificationOptions = {
      title: 'Update Customization failed',
      message: errorMessage,
      level: 'error',
    }
    yield put(addNotificationAction(notificationOptions))
    yield put(updateCustomizationFailure(team, errorMessage))
  }
}

export function* customizationSaga() {
  yield all([
    takeLatest(FETCH_CUSTOMIZATION_REQUEST, handleFetchCustomizationRequest),
    takeLatest(UPDATE_CUSTOMIZATION_REQUEST, handleUpdateCustomizationRequest),
  ])
}
