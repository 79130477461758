import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  AUTH0_LOGIN_FAILURE,
  AUTH0_LOGIN_REQUEST,
  AUTH0_LOGIN_SUCCESS,
  AUTH0_LOGOUT_FAILURE,
  AUTH0_LOGOUT_REQUEST,
  AUTH0_LOGOUT_SUCCESS,
  Auth0LoginFailureAction,
  Auth0LoginRequestAction,
  Auth0LoginSuccessAction,
  Auth0LogoutFailureAction,
  Auth0LogoutRequestAction,
  Auth0LogoutSuccessAction,
  PLUGGY_API_LOGIN_FAILURE,
  PLUGGY_API_LOGIN_REQUEST,
  PLUGGY_API_LOGIN_SUCCESS,
  PluggyApiLoginFailureAction,
  PluggyApiLoginRequestAction,
  PluggyApiLoginSuccessAction,
} from './actions'
import { AuthResult } from './types'

export type AuthState = {
  data: {
    auth: AuthResult | null
  }
  loading: LoadingState
  error: string | null
}

export const INITIAL_STATE: AuthState = {
  data: {
    auth: null,
  },
  loading: [],
  error: null,
}

export type AuthReducerAction =
  | Auth0LoginRequestAction
  | Auth0LoginSuccessAction
  | Auth0LoginFailureAction
  | Auth0LogoutRequestAction
  | Auth0LogoutSuccessAction
  | Auth0LogoutFailureAction
  | PluggyApiLoginRequestAction
  | PluggyApiLoginSuccessAction
  | PluggyApiLoginFailureAction

export function authReducer(
  state: AuthState = INITIAL_STATE,
  action: AuthReducerAction,
): AuthState {
  switch (action.type) {
    case AUTH0_LOGIN_REQUEST:
    case AUTH0_LOGOUT_REQUEST:
    case PLUGGY_API_LOGIN_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case AUTH0_LOGOUT_SUCCESS:
      return INITIAL_STATE
    case AUTH0_LOGIN_FAILURE:
    case AUTH0_LOGOUT_FAILURE:
    case PLUGGY_API_LOGIN_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    case AUTH0_LOGIN_SUCCESS: {
      const { authResult } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          auth: authResult,
        },
      }
    }
    case PLUGGY_API_LOGIN_SUCCESS: {
      const { authResult } = action.payload

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          auth: authResult,
        },
      }
    }
    default:
      return state
  }
}
