import { IdToken } from '@auth0/auth0-react'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { signOut } from '../../lib/auth0'
import { identify } from '../analytics/utils'
import {
  AUTH0_LOGIN_FAILURE,
  AUTH0_LOGIN_REQUEST,
  AUTH0_LOGIN_SUCCESS,
  AUTH0_LOGOUT_REQUEST,
  auth0loginFailure,
  Auth0LoginFailureAction,
  Auth0LoginRequestAction,
  auth0LoginSuccess,
  Auth0LoginSuccessAction,
  auth0LogoutFailure,
  auth0LogoutRequest,
  Auth0LogoutRequestAction,
  auth0LogoutSuccess,
  PLUGGY_API_LOGIN_REQUEST,
  PLUGGY_API_LOGIN_SUCCESS,
  pluggyApiLoginFailure,
  pluggyApiLoginRequest,
  PluggyApiLoginRequestAction,
  pluggyApiLoginSuccess,
  PluggyApiLoginSuccessAction,
} from './actions'
import { clearAuthResult, storeAuthResult } from './storage'
import { AuthResult } from './types'
import { buildAuthResult, createPluggyApiKey } from './utils'

export type ErrorResponse = {
  status: number
  message: string
}

function* handleAuth0LoginFailure(_action: Auth0LoginFailureAction) {
  clearAuthResult()
}

function* handleLogoutRequest(_action: Auth0LogoutRequestAction) {
  try {
    signOut()
    clearAuthResult()
    yield put(auth0LogoutSuccess())
  } catch (error) {
    yield put(auth0LogoutFailure(error.message))
  }
}

/**
 * Perform login request to Backoffice API, using the obtained Auth0 login data
 */
function* handleAuth0LoginRequest(action: Auth0LoginRequestAction) {
  const { getIdTokenClaims, getAccessTokenSilently } = action.payload

  try {
    const [idToken, accessToken]: [IdToken, string] = yield call(() =>
      Promise.all([getIdTokenClaims(), getAccessTokenSilently()]),
    )

    if (!accessToken) {
      throw new Error("Can't perform login - missing auth0 accessToken")
    }

    const authResult: AuthResult = yield call(() =>
      buildAuthResult(idToken, accessToken),
    )

    yield put(auth0LoginSuccess(authResult))
  } catch (error) {
    yield put(auth0loginFailure(error.message))

    // submit logout to clean the state
    yield put(auth0LogoutRequest())
  }
}

export function* handleAuth0LoginSuccess(action: Auth0LoginSuccessAction) {
  const {
    payload: { authResult },
  } = action

  // store Auth0 result data, even if still not obtained apiKey,
  // in case the next request fails and we want to be able to retry from here
  storeAuthResult(authResult)

  // track identity in analytics
  const {
    profile: { auth0Id, email, name },
  } = authResult

  identify(auth0Id, {
    email,
    name,
  })

  // validate auth0 accessToken & fetch pluggy-api apiKey
  yield put(pluggyApiLoginRequest(authResult))
}

export function* handlePluggyApiLoginRequest(
  action: PluggyApiLoginRequestAction,
) {
  const {
    payload: { authResult },
  } = action

  const { accessToken } = authResult
  // TODO check for auth0 token expiration here?

  // fetch pluggy-api apiKey
  try {
    const apiKey: string = yield call(() => createPluggyApiKey(accessToken))
    const updatedAuthResult: AuthResult = {
      ...authResult,
      apiKey,
    }

    yield put(pluggyApiLoginSuccess(updatedAuthResult))
  } catch (error) {
    yield put(pluggyApiLoginFailure(error.message))
    // TODO find a better way to detect the Auth0 token expired error,
    //  than just comparing the string 'message' from the /api/validate response
    // TODO2 implement auth0 token refresh logic in /api/validate endpoint?
    if (error.message.toLowerCase().includes('auth0 token expired')) {
      yield put(auth0LogoutRequest())
    }
  }
}

export function* handlePluggyApiLoginSuccess(
  action: PluggyApiLoginSuccessAction,
) {
  const {
    payload: { authResult },
  } = action

  // store fully authorized data
  storeAuthResult(authResult)
}

export function* authSaga() {
  yield all([
    takeLatest(AUTH0_LOGIN_REQUEST, handleAuth0LoginRequest),
    takeLatest(AUTH0_LOGIN_FAILURE, handleAuth0LoginFailure),
    takeLatest(AUTH0_LOGIN_SUCCESS, handleAuth0LoginSuccess),

    takeLatest(PLUGGY_API_LOGIN_REQUEST, handlePluggyApiLoginRequest),
    takeLatest(PLUGGY_API_LOGIN_SUCCESS, handlePluggyApiLoginSuccess),
    takeLatest(AUTH0_LOGOUT_REQUEST, handleLogoutRequest),
  ])
}
