import { PageResponse } from 'pluggy-js'

import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FetchUsersFailureAction,
  FetchUsersRequestAction,
  FetchUsersSuccessAction,
  RESEND_VERIFICATION_EMAIL_FAILURE,
  RESEND_VERIFICATION_EMAIL_REQUEST,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  ResendVerificationEmailFailureAction,
  ResendVerificationEmailRequestAction,
  ResendVerificationEmailSuccessAction,
} from './actions'
import { AuthApiUser } from './types'

export type UserState = {
  data: PageResponse<AuthApiUser> | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: UserState = {
  data: null,
  loading: [],
  error: null,
}

type UserReducerAction =
  | FetchUsersRequestAction
  | FetchUsersSuccessAction
  | FetchUsersFailureAction
  | ResendVerificationEmailRequestAction
  | ResendVerificationEmailSuccessAction
  | ResendVerificationEmailFailureAction

export function userReducer(
  state: UserState = INITIAL_STATE,
  action: UserReducerAction,
): UserState {
  switch (action.type) {
    case RESEND_VERIFICATION_EMAIL_REQUEST:
    case RESEND_VERIFICATION_EMAIL_SUCCESS:
    case FETCH_USERS_REQUEST: {
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.users,
      }
    case RESEND_VERIFICATION_EMAIL_FAILURE:
    case FETCH_USERS_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default: {
      return state
    }
  }
}
