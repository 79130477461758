import { connect } from 'react-redux'

import { UPDATE_CUSTOMIZATION_REQUEST } from '../../../../modules/customization/actions'
import { getLoading as getCustomizationLoading } from '../../../../modules/customization/selectors'
import { RootState } from '../../../../modules/reducer'
import { FETCH_TEAMS_REQUEST } from '../../../../modules/team/actions'
import {
  getData,
  getError,
  getLoading,
  isLoadingType,
} from '../../../../modules/team/selectors'
import TeamSelector from './TeamSelector'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamSelector.types'

const mapState = (state: RootState): MapStateProps => ({
  teams: getData(state)?.results ?? [],
  error: getError(state),
  isLoading:
    isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST) ||
    isLoadingType(getCustomizationLoading(state), UPDATE_CUSTOMIZATION_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(TeamSelector)
