import React, { useCallback, useState } from 'react'

import { Popup } from '@pluggyai/ui'
import { Button, Grid, Segment } from 'semantic-ui-react'

import { formatDate } from '../../../lib/utils/date'
import { ApplicationsTable } from '../../ApplicationsTable'
import { Teams } from '../../Teams'
import { Props } from './UserListItem.types'
import { buildDashboardUserImpersonateLink } from './utils'

import './UserListItem.css'

const UserListItem = ({
  user,
  isImpersonateAllowed,
  onResendVerificationEmail,
}: Props) => {
  const { id, name, email, createdAt, updatedAt, emailVerified } = user
  const [displayTeamsList, setDisplayTeamsList] = useState<boolean>(false)

  const handleTeamsListToggle = useCallback(() => {
    setDisplayTeamsList((value) => !value)
  }, [])

  const [displayApplicationsList, setDisplayApplicationsList] =
    useState<boolean>(false)

  const handleApplicationsListToggle = useCallback(() => {
    setDisplayApplicationsList((value) => !value)
  }, [])

  const handleResendVerificationEmailClick = useCallback(() => {
    onResendVerificationEmail(id)
  }, [onResendVerificationEmail, id])

  return (
    <Segment className={'UserListItem'} data-id={id}>
      <Grid columns={4} stackable>
        <Grid.Row>
          {/* Name | id | impersonate link */}
          <Grid.Column width={4}>
            <span className={'title'}>{name}</span>
            <br />
            <span className={'id'}>{id}</span>
          </Grid.Column>

          {/* Email */}
          <Grid.Column width={3}>
            Email: <br />
            {email}
            <br />
            <span className={'is-verified'}>
              {emailVerified ? 'Verified ✓' : 'Not verified ✗'}
            </span>
          </Grid.Column>

          {/* Created At */}
          <Grid.Column width={3}>
            Created at:
            <br />
            {formatDate(createdAt)}
          </Grid.Column>

          {/* Updated At*/}
          <Grid.Column width={3}>
            Updated at:
            <br />
            {formatDate(updatedAt)}
          </Grid.Column>

          {/* Actions */}
          <Grid.Column width={3}>
            <Button
              primary
              inverted
              size="mini"
              onClick={handleTeamsListToggle}
            >
              {displayTeamsList ? 'Hide Teams' : 'View Teams'}
            </Button>
            <Button
              primary
              inverted
              size="mini"
              onClick={handleApplicationsListToggle}
            >
              {displayApplicationsList
                ? 'Hide Applications'
                : 'View Applications'}
            </Button>
            <Button
              primary
              size="mini"
              onClick={handleResendVerificationEmailClick}
            >
              Resend Verification Email
            </Button>
            {/*<br />*/}
            <Popup
              className={'impersonate-link-popup'}
              position={'top center'}
              content={'Not enough permissions for this action'}
              disabled={isImpersonateAllowed}
              /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
                      related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
              trigger={
                <div className={'impersonate-link-popup-wrapper'}>
                  <a
                    className={`link ${isImpersonateAllowed ? '' : 'disabled'}`}
                    href={
                      isImpersonateAllowed
                        ? buildDashboardUserImpersonateLink(email)
                        : undefined
                    }
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    Impersonate
                  </a>
                </div>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* User-related data */}
      {displayTeamsList && <Teams user={user} />}
      {displayApplicationsList && <ApplicationsTable user={user} />}
    </Segment>
  )
}

export default React.memo(UserListItem)
