import { PageResponse } from 'pluggy-js'

import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CLEAR_TEAM_ERROR,
  ClearTeamErrorAction,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_MEMBER_FAILURE,
  CREATE_TEAM_MEMBER_REQUEST,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CreateTeamFailureAction,
  CreateTeamMemberFailureAction,
  CreateTeamMemberRequestAction,
  CreateTeamMemberSuccessAction,
  CreateTeamRequestAction,
  CreateTeamSuccessAction,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_MEMBER_FAILURE,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DeleteTeamFailureAction,
  DeleteTeamMemberFailureAction,
  DeleteTeamMemberRequestAction,
  DeleteTeamMemberSuccessAction,
  DeleteTeamRequestAction,
  DeleteTeamSuccessAction,
  FETCH_TEAMS_FAILURE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_SUCCESS,
  FetchTeamsFailureAction,
  FetchTeamsRequestAction,
  FetchTeamsSuccessAction,
  UPDATE_TEAM_FAILURE,
  UPDATE_TEAM_MEMBER_ROLE_FAILURE,
  UPDATE_TEAM_MEMBER_ROLE_REQUEST,
  UPDATE_TEAM_MEMBER_ROLE_SUCCESS,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UpdateTeamFailureAction,
  UpdateTeamMemberRoleFailureAction,
  UpdateTeamMemberRoleRequestAction,
  UpdateTeamMemberRoleSuccessAction,
  UpdateTeamRequestAction,
  UpdateTeamSuccessAction,
} from './actions'
import { Team } from './types'

export type TeamState = {
  data: PageResponse<Team> | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: TeamState = {
  data: null,
  loading: [],
  error: null,
}

type TeamReducerAction =
  | CreateTeamRequestAction
  | CreateTeamSuccessAction
  | CreateTeamFailureAction
  | FetchTeamsRequestAction
  | FetchTeamsSuccessAction
  | FetchTeamsFailureAction
  | DeleteTeamRequestAction
  | DeleteTeamSuccessAction
  | DeleteTeamFailureAction
  | CreateTeamMemberRequestAction
  | CreateTeamMemberSuccessAction
  | CreateTeamMemberFailureAction
  | UpdateTeamMemberRoleRequestAction
  | UpdateTeamMemberRoleSuccessAction
  | UpdateTeamMemberRoleFailureAction
  | DeleteTeamMemberRequestAction
  | DeleteTeamMemberSuccessAction
  | DeleteTeamMemberFailureAction
  | UpdateTeamRequestAction
  | UpdateTeamSuccessAction
  | UpdateTeamFailureAction
  | ClearTeamErrorAction

export function teamReducer(
  state: TeamState = INITIAL_STATE,
  action: TeamReducerAction,
): TeamState {
  switch (action.type) {
    case CLEAR_TEAM_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case FETCH_TEAMS_REQUEST:
    case CREATE_TEAM_REQUEST:
    case DELETE_TEAM_REQUEST:
    case CREATE_TEAM_MEMBER_REQUEST:
    case UPDATE_TEAM_REQUEST:
    case UPDATE_TEAM_MEMBER_ROLE_REQUEST:
    case DELETE_TEAM_MEMBER_REQUEST: {
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    }
    case DELETE_TEAM_SUCCESS: {
      const {
        team: { id: deletedTeamId },
      } = action.payload

      // remove team from current state data
      const teamsExceptDeleted = (state.data?.results || []).filter(
        (_team) => _team.id !== deletedTeamId,
      )

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          results: teamsExceptDeleted,
        },
      }
    }
    case CREATE_TEAM_MEMBER_SUCCESS:
    case UPDATE_TEAM_MEMBER_ROLE_SUCCESS:
    case DELETE_TEAM_MEMBER_SUCCESS:
    case CREATE_TEAM_SUCCESS:
    case UPDATE_TEAM_SUCCESS: {
      const { team } = action.payload

      // update or insert team on current state
      const currentTeams = [...(state.data?.results || [])]
      const i = currentTeams.findIndex((_team) => _team.id === team.id)
      if (i > -1) {
        // found -> update item
        currentTeams[i] = team
      } else {
        // not found -> insert
        currentTeams.push(team)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          results: currentTeams,
        },
      }
    }
    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.teams,
      }
    case CREATE_TEAM_MEMBER_FAILURE:
    case UPDATE_TEAM_MEMBER_ROLE_FAILURE:
    case DELETE_TEAM_MEMBER_FAILURE:
    case FETCH_TEAMS_FAILURE:
    case CREATE_TEAM_FAILURE:
    case UPDATE_TEAM_FAILURE:
    case DELETE_TEAM_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default: {
      return state
    }
  }
}
