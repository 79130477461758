import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { isStringsArray } from '../../../lib/utils/validation'
import {
  CustomizationFields,
  CustomizationFieldsErrors,
} from '../../../modules/customization/types'
import { isMembersInvitationsArray, Team } from '../../../modules/team/types'
import {
  TeamUpdateTermsForm,
  validateCustomizationField,
} from '../TeamUpdateTermsForm'
import { TeamSelector } from './TeamSelector'
import { Props } from './TeamUpdateTermsModal.types'

import './TeamUpdateTermsModal.css'

function buildInitialCustomizationFields(): CustomizationFields {
  return {
    termsAndConditions: null,
    privacyPolicy: null,
  }
}

const TeamUpdateTermsModal = ({
  team,
  isFetchingCustomization,
  isUpdatingCustomization,
  customizationErrorsByTeamId,
  customizationsByTeamId,
  onClose,
  onUpdate,
  onFetchTeams,
  onFetchCustomization,
}: Props) => {
  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<CustomizationFieldsErrors>({})
  const [customizationValues, setCustomizationValues] =
    useState<CustomizationFields>(buildInitialCustomizationFields)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  useEffect(() => {
    if (team) {
      // team already selected
      return
    }
    // fetch teams on open
    onFetchTeams()
  }, [onFetchTeams, team])

  const close = useCallback(() => {
    setFormErrors({})
    setCustomizationValues(buildInitialCustomizationFields())
    onClose()
  }, [onClose])

  const handleOnClose = useCallback(() => {
    close()
  }, [close])

  const handleOnCancel = useCallback(() => {
    close()
  }, [close])

  const handleValuesChange = useCallback(
    (fieldName: keyof CustomizationFields, value: unknown) => {
      // clear field error, if any
      if (formErrors[fieldName]) {
        delete formErrors[fieldName]
        setFormErrors({ ...formErrors })
      }
      // clear team state error, if any
      if (submit) {
        setSubmit(false)
      }
      // set field value
      setCustomizationValues({ ...customizationValues, [fieldName]: value })
    },
    [customizationValues, formErrors, submit],
  )

  const handleTeamSelect = useCallback(
    (team_: Team | null) => {
      setSelectedTeam(team_)
      if (!team_) {
        return
      }
      onFetchCustomization(team_)
    },
    [onFetchCustomization],
  )

  useEffect(() => {
    if (!team) {
      return
    }
    handleTeamSelect(team)
  }, [team, handleTeamSelect])

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isFetchingCustomization || isUpdatingCustomization) {
        // is already loading/submitting, wait
        return
      }

      if (!selectedTeam) {
        // no team selected, can't submit
        return
      }

      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: CustomizationFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(customizationValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateCustomizationField(
            field as keyof CustomizationFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else if (isMembersInvitationsArray(value)) {
          const errorsJoined = validateCustomizationField(
            field as keyof CustomizationFields,
            value,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateCustomizationField(
            field as keyof CustomizationFields,
            value,
          )

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof CustomizationFields] =
            fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        return
      }

      setSubmit(true)

      onUpdate(selectedTeam, customizationValues)
    },
    [
      customizationValues,
      onUpdate,
      isFetchingCustomization,
      isUpdatingCustomization,
      selectedTeam,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone =
      submit &&
      selectedTeam &&
      customizationErrorsByTeamId[selectedTeam.id] === undefined &&
      !isUpdatingCustomization

    if (isDone) {
      handleOnClose()
    }
  }, [
    selectedTeam,
    customizationErrorsByTeamId,
    isUpdatingCustomization,
    submit,
    handleOnClose,
  ])

  useEffect(() => {
    if (!selectedTeam) {
      return
    }
    const currentCustomization = customizationsByTeamId[selectedTeam.id]
    if (!currentCustomization) {
      return
    }

    const currentCustomizationValues: CustomizationFields = {
      termsAndConditions: currentCustomization.termsAndConditions,
      privacyPolicy: currentCustomization.privacyPolicy,
    }
    setCustomizationValues(currentCustomizationValues)
  }, [customizationsByTeamId, selectedTeam])

  const currentCustomization =
    selectedTeam && customizationsByTeamId[selectedTeam.id]
  const formValuesChanged =
    currentCustomization &&
    (customizationValues.termsAndConditions !==
      currentCustomization.termsAndConditions ||
      customizationValues.privacyPolicy !== currentCustomization.privacyPolicy)

  return (
    <Modal
      className={'TeamUpdateTermsModal'}
      onClose={handleOnClose}
      open={true}
      closeIcon
    >
      <Modal.Header>
        {"Update Team's Terms/Privacy texts"}
        {team ? (
          <>
            <p className={'subtitle'}>
              Team <b>${team.name}</b> (id: <b>{team.id}</b>)
            </p>
            <p className={'subtitle'}>
              {'Update Terms&Conditions and/or Privacy Policy texts'}
            </p>
          </>
        ) : (
          <p className={'subtitle'}>
            {
              'Search Team by id, then update Terms&Conditions and/or Privacy Policy texts.'
            }
          </p>
        )}
        <p className={'subtitle'}>
          {
            "Texts will be visible in all Team's applications, in Connect widget."
          }
        </p>
      </Modal.Header>
      <Modal.Content>
        {!team && <TeamSelector onTeamSelect={handleTeamSelect} />}
        {selectedTeam &&
          customizationErrorsByTeamId[selectedTeam.id] !== undefined && (
            <Alert
              size={'medium'}
              type={'error'}
              message={customizationErrorsByTeamId[selectedTeam.id]}
            />
          )}
        <TeamUpdateTermsForm
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={customizationValues}
          initialValues={
            selectedTeam ? customizationsByTeamId[selectedTeam.id] : undefined
          }
          errors={formErrors}
          isLoading={isFetchingCustomization || isUpdatingCustomization}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={handleOnCancel}>
          Cancel
        </Button>
        <Button
          primary
          onClick={handleSubmit}
          loading={isUpdatingCustomization}
          disabled={
            !selectedTeam ||
            isFetchingCustomization ||
            isUpdatingCustomization ||
            !currentCustomization ||
            !formValuesChanged
          }
        >
          Update
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(TeamUpdateTermsModal)
