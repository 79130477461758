import { connect } from 'react-redux'

import { fetchAuthApiApplicationsRequest } from '../../modules/application/actions'
import { RootState } from '../../modules/reducer'
import {
  FETCH_TEAMS_REQUEST,
  fetchTeamsRequest,
} from '../../modules/team/actions'
import {
  getData,
  getError,
  getLoading,
  getTeamByApplication,
  isLoadingType,
} from '../../modules/team/selectors'
import { Team } from '../../modules/team/types'
import { fetchUsersRequest } from '../../modules/user/actions'
import Teams from './Teams'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './Teams.types'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  let teams: Team[] | null
  const { user, application } = ownProps
  if (user && !application) {
    teams = user.teams || []
  } else if (application) {
    const applicationOwnerTeam = getTeamByApplication(state, application)
    teams = applicationOwnerTeam && [applicationOwnerTeam]
  } else {
    teams = getData(state)?.results || null
  }

  return {
    teams,
    error: getError(state),
    isLoading: isLoadingType(getLoading(state), FETCH_TEAMS_REQUEST),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchTeams: (search: string) => dispatch(fetchTeamsRequest(search)),
  onFetchUsers: () => dispatch(fetchUsersRequest()),
  onFetchAuthApiApplications: () => dispatch(fetchAuthApiApplicationsRequest()),
})

export default connect(mapState, mapDispatch)(Teams)
