import React, { useCallback, useState } from 'react'

import { Team } from '../../../../modules/team/types'
import { ListSection } from '../../../ListSection'
import { TeamListItem } from '../../TeamItem'
import { TeamSearchSection } from './TeamSearchSection'
import { Props } from './TeamSelector.types'

const TeamSelector = ({ teams, isLoading, error, onTeamSelect }: Props) => {
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
  const [searchError, setSearchError] = useState<string | null>(null)

  const handleSearchTeam = useCallback(
    (teamId: string | null) => {
      if (!teams) {
        // no teams loaded yet
        return
      }

      if (teamId === null) {
        // clear selection
        onTeamSelect(null)
        setSelectedTeam(null)
        setSearchError(null)
        return
      }

      const teamFound = teams.find((team) => team.id === teamId) || null
      if (!teamFound) {
        setSearchError(`Team id '${teamId}' not found.`)
      } else {
        setSearchError(null)
      }

      onTeamSelect(teamFound)
      setSelectedTeam(teamFound)
    },
    [teams, onTeamSelect],
  )

  return (
    <div className="TeamSelector">
      <TeamSearchSection onSearchTeam={handleSearchTeam} />
      <ListSection
        title={'Team'}
        isLoading={!teams && isLoading}
        data={selectedTeam ? [selectedTeam] : []}
        className={'TeamsList'}
        error={error || searchError}
        EmptyPlaceholder={
          <div className={'user-empty-message'}>
            Searched Teams will be listed here
          </div>
        }
        renderValue={(team: Team) => <TeamListItem team={team} key={team.id} />}
      />
    </div>
  )
}

export default React.memo(TeamSelector)
