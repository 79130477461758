import { PageResponse } from 'pluggy-js'
import { action } from 'typesafe-actions'

import {
  Application,
  ApplicationShareLink,
  ApplicationShareLinkFields,
  AuthApiApplication,
} from './types'

/// Fetch Applications request from PluggyAPI

export const FETCH_APPLICATIONS_REQUEST = '[Request] Fetch Applications'
export const FETCH_APPLICATIONS_SUCCESS = '[Success] Fetch Applications'
export const FETCH_APPLICATIONS_FAILURE = '[Failure] Fetch Applications'

export const fetchApplicationsRequest = () => action(FETCH_APPLICATIONS_REQUEST)
export const fetchApplicationsSuccess = (applications: Application[]) =>
  action(FETCH_APPLICATIONS_SUCCESS, { applications })
export const fetchApplicationsFailure = (error: string) =>
  action(FETCH_APPLICATIONS_FAILURE, { error })

export type FetchApplicationsRequestAction = ReturnType<
  typeof fetchApplicationsRequest
>
export type FetchApplicationsSuccessAction = ReturnType<
  typeof fetchApplicationsSuccess
>
export type FetchApplicationsFailureAction = ReturnType<
  typeof fetchApplicationsFailure
>

/// Fetch Application request

export const FETCH_APPLICATION_REQUEST = '[Request] Fetch Application'
export const FETCH_APPLICATION_SUCCESS = '[Success] Fetch Application'
export const FETCH_APPLICATION_FAILURE = '[Failure] Fetch Application'

export const fetchApplicationRequest = (id: string) =>
  action(FETCH_APPLICATION_REQUEST, { id })
export const fetchApplicationSuccess = (application: Application) =>
  action(FETCH_APPLICATION_SUCCESS, { application })
export const fetchApplicationFailure = (error: string) =>
  action(FETCH_APPLICATION_FAILURE, { error })

export type FetchApplicationRequestAction = ReturnType<
  typeof fetchApplicationRequest
>
export type FetchApplicationSuccessAction = ReturnType<
  typeof fetchApplicationSuccess
>
export type FetchApplicationFailureAction = ReturnType<
  typeof fetchApplicationFailure
>

/// Create Application request

export const CREATE_APPLICATION_REQUEST = '[Request] Create Application'
export const CREATE_APPLICATION_SUCCESS = '[Success] Create Application'
export const CREATE_APPLICATION_FAILURE = '[Failure] Create Application'

export const createApplicationRequest = (name: string) =>
  action(CREATE_APPLICATION_REQUEST, { name })
export const createApplicationSuccess = (application: Application) =>
  action(CREATE_APPLICATION_SUCCESS, { application })
export const createApplicationFailure = (error: string) =>
  action(CREATE_APPLICATION_FAILURE, { error })

export type CreateApplicationRequestAction = ReturnType<
  typeof createApplicationRequest
>
export type CreateApplicationSuccessAction = ReturnType<
  typeof createApplicationSuccess
>
export type CreateApplicationFailureAction = ReturnType<
  typeof createApplicationFailure
>

export const FETCH_AUTH_API_APPLICATIONS_REQUEST =
  '[Request] Fetch Auth Api Applications'
export const FETCH_AUTH_API_APPLICATIONS_SUCCESS =
  '[Success] Fetch Auth Api Applications'
export const FETCH_AUTH_API_APPLICATIONS_FAILURE =
  '[Failure] Fetch Auth Api Applications'

export const fetchAuthApiApplicationsRequest = (search?: string) =>
  action(FETCH_AUTH_API_APPLICATIONS_REQUEST, {
    search,
  })
export const fetchAuthApiApplicationsSuccess = (
  applications: PageResponse<AuthApiApplication>,
) => action(FETCH_AUTH_API_APPLICATIONS_SUCCESS, { applications })
export const fetchAuthApiApplicationsFailure = (error: string) =>
  action(FETCH_AUTH_API_APPLICATIONS_FAILURE, { error })

export type FetchAuthApiApplicationsRequestAction = ReturnType<
  typeof fetchAuthApiApplicationsRequest
>
export type FetchAuthApiApplicationsSuccessAction = ReturnType<
  typeof fetchAuthApiApplicationsSuccess
>
export type FetchAuthApiApplicationsFailureAction = ReturnType<
  typeof fetchAuthApiApplicationsFailure
>

/// Create ApplicationShareLink request

export const CREATE_APPLICATION_SHARE_LINK_REQUEST =
  '[Request] Create ApplicationShareLink'
export const CREATE_APPLICATION_SHARE_LINK_SUCCESS =
  '[Success] Create ApplicationShareLink'
export const CREATE_APPLICATION_SHARE_LINK_FAILURE =
  '[Failure] Create ApplicationShareLink'

export const createApplicationShareLinkRequest = (
  applicationShareLinkFields: ApplicationShareLinkFields,
) =>
  action(CREATE_APPLICATION_SHARE_LINK_REQUEST, { applicationShareLinkFields })
export const createApplicationShareLinkSuccess = (
  applicationShareLink: ApplicationShareLink,
) => action(CREATE_APPLICATION_SHARE_LINK_SUCCESS, { applicationShareLink })
export const createApplicationShareLinkFailure = (error: string) =>
  action(CREATE_APPLICATION_SHARE_LINK_FAILURE, { error })

export type CreateApplicationShareLinkRequestAction = ReturnType<
  typeof createApplicationShareLinkRequest
>
export type CreateApplicationShareLinkSuccessAction = ReturnType<
  typeof createApplicationShareLinkSuccess
>
export type CreateApplicationShareLinkFailureAction = ReturnType<
  typeof createApplicationShareLinkFailure
>

/// Fetch ApplicationShareLink request

export const FETCH_APPLICATIONS_SHARE_LINKS_REQUEST =
  '[Request] Fetch ApplicationsShareLinks'
export const FETCH_APPLICATIONS_SHARE_LINKS_SUCCESS =
  '[Success] Fetch ApplicationsShareLinks'
export const FETCH_APPLICATIONS_SHARE_LINKS_FAILURE =
  '[Failure] Fetch ApplicationsShareLinks'

export const fetchApplicationsShareLinksRequest = () =>
  action(FETCH_APPLICATIONS_SHARE_LINKS_REQUEST)
export const fetchApplicationsShareLinksSuccess = (
  applicationsShareLinks: ApplicationShareLink[],
) => action(FETCH_APPLICATIONS_SHARE_LINKS_SUCCESS, { applicationsShareLinks })
export const fetchApplicationsShareLinksFailure = (error: string) =>
  action(FETCH_APPLICATIONS_SHARE_LINKS_FAILURE, { error })

export type FetchApplicationsShareLinksRequestAction = ReturnType<
  typeof fetchApplicationsShareLinksRequest
>
export type FetchApplicationsShareLinksSuccessAction = ReturnType<
  typeof fetchApplicationsShareLinksSuccess
>
export type FetchApplicationsShareLinksFailureAction = ReturnType<
  typeof fetchApplicationsShareLinksFailure
>

/// Update ApplicationShareLink request

export const UPDATE_APPLICATION_SHARE_LINK_REQUEST =
  '[Request] Update ApplicationShareLink'
export const UPDATE_APPLICATION_SHARE_LINK_SUCCESS =
  '[Success] Update ApplicationShareLink'
export const UPDATE_APPLICATION_SHARE_LINK_FAILURE =
  '[Failure] Update ApplicationShareLink'

export const updateApplicationShareLinkRequest = (
  applicationId: string,
  applicationShareLinkFields: ApplicationShareLinkFields,
) =>
  action(UPDATE_APPLICATION_SHARE_LINK_REQUEST, {
    applicationId,
    applicationShareLinkFields,
  })
export const updateApplicationShareLinkSuccess = (
  applicationShareLink: ApplicationShareLink,
) => action(UPDATE_APPLICATION_SHARE_LINK_SUCCESS, { applicationShareLink })
export const updateApplicationShareLinkFailure = (error: string) =>
  action(UPDATE_APPLICATION_SHARE_LINK_FAILURE, { error })

export type UpdateApplicationShareLinkRequestAction = ReturnType<
  typeof updateApplicationShareLinkRequest
>
export type UpdateApplicationShareLinkSuccessAction = ReturnType<
  typeof updateApplicationShareLinkSuccess
>
export type UpdateApplicationShareLinkFailureAction = ReturnType<
  typeof updateApplicationShareLinkFailure
>

// Clear ApplicationShareLink error
export const CLEAR_APPLICATION_SHARE_LINK_ERROR =
  '[Request] Clear ApplicationShareLink error'
export const clearApplicationShareLinkError = () =>
  action(CLEAR_APPLICATION_SHARE_LINK_ERROR)
export type ClearApplicationShareLinkErrorAction = ReturnType<
  typeof clearApplicationShareLinkError
>
