import { connect } from 'react-redux'

import {
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATIONS_REQUEST,
} from '../../modules/application/actions'
import {
  getAuthApiApplications,
  getCoreApiApplications,
  getLoading as isLoadingApplications,
  isLoadingType,
} from '../../modules/application/selectors'
import { RootState } from '../../modules/reducer'
import Applications from './Applications'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './Applications.types'

const mapState = (state: RootState): MapStateProps => ({
  applications: getCoreApiApplications(state),
  authApiApplications: getAuthApiApplications(state),
  isLoadingApplications:
    isLoadingType(isLoadingApplications(state), FETCH_APPLICATIONS_REQUEST) ||
    isLoadingType(isLoadingApplications(state), FETCH_APPLICATION_REQUEST),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet...
})

export default connect(mapState, mapDispatch)(Applications)
