import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@pluggyai/ui'
import { Icon, Select } from 'semantic-ui-react'

import {
  MemberInvitation,
  TEAM_ROLES,
  TeamFields,
  TeamRole,
} from '../../../../modules/team/types'
import { mapTeamRoleValueToI18nText } from '../../../../modules/team/utils'
import { DropdownOpenIcon } from '../../../Icon'

import './TeamMembersEmailsInput.css'

type Props = {
  onFieldChange: (
    fieldName: keyof TeamFields,
    value: MemberInvitation[],
  ) => void
  value: MemberInvitation[]
  errors?: string[]
  isSubmitting: boolean
}

const emptyDefaultMemberInvite = (): MemberInvitation => ({
  email: '',
  role: 'MEMBER',
})

const TeamMembersEmailsInput = ({
  onFieldChange,
  value: currentMembersInvitations,
  errors,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation()

  const onValueChange = useCallback(
    (value: MemberInvitation[]) => {
      onFieldChange('members', value)
    },
    [onFieldChange],
  )

  const handleOnAddNewEmailInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // preventing default & propagation to prevent parent form unwanted 'submit' event
      event.preventDefault()
      event.stopPropagation()
      const newMembersInvitations = [
        ...currentMembersInvitations,
        emptyDefaultMemberInvite(),
      ]
      onValueChange(newMembersInvitations)
    },
    [currentMembersInvitations, onValueChange],
  )

  const onValueEmailChange = useCallback(
    (emailValue: string, index: number) => {
      currentMembersInvitations[index].email = emailValue.trim()
      onValueChange([...currentMembersInvitations])
    },
    [onValueChange, currentMembersInvitations],
  )

  const onValueRoleChange = useCallback(
    (roleValue: TeamRole, index: number) => {
      currentMembersInvitations[index].role = roleValue
      onValueChange([...currentMembersInvitations])
    },
    [onValueChange, currentMembersInvitations],
  )

  const onMemberInvitationRemove = useCallback(
    (index: number) => {
      let newCurrentMembersInvitations: MemberInvitation[]
      if (currentMembersInvitations.length === 1) {
        // only 1 available input, don't delete it, just clear it
        currentMembersInvitations[0] = emptyDefaultMemberInvite()
        newCurrentMembersInvitations = [...currentMembersInvitations]
      } else {
        // more than one input available, remove it
        newCurrentMembersInvitations = [
          ...currentMembersInvitations.filter((_, i) => i !== index),
        ]
      }
      onValueChange(newCurrentMembersInvitations)
    },
    [onValueChange, currentMembersInvitations],
  )

  return (
    <div className={'TeamMembersEmailsInput'}>
      <div className={'team-members-emails-header'}>
        <span>{t('team.form.field.members.header')}</span>
        <Button
          link
          disabled={isSubmitting}
          onClick={handleOnAddNewEmailInput}
          type="button"
        >
          {t('team.form.field.members.action.new')}
        </Button>
      </div>
      {currentMembersInvitations.map(({ email, role }, i) => (
        <div key={`emails-${i}`} className={'add-item-row'}>
          <Input
            label={t('team.form.field.members.field.email.label')}
            type="text"
            onChange={({ value }) => {
              onValueEmailChange(value, i)
            }}
            value={email}
            error={errors?.[i]}
            disabled={isSubmitting}
          />
          {/* TODO: improve invite collaborators component to match the actual Figma design https://i.imgur.com/qX9jgJ2.png */}
          <Select
            className={'team-role-input'}
            onChange={(_e, { value }) =>
              onValueRoleChange(value as TeamRole, i)
            }
            value={role}
            options={TEAM_ROLES.map((role_) => ({
              key: role_,
              value: role_,
              text: mapTeamRoleValueToI18nText(role_),
            }))}
            disabled={isSubmitting}
            icon={<DropdownOpenIcon />}
          />
          <Icon
            className={'delete-item'}
            name="trash"
            link
            disabled={isSubmitting}
            onClick={() => onMemberInvitationRemove(i)}
          />
        </div>
      ))}
    </div>
  )
}

export default React.memo(TeamMembersEmailsInput)
