import { connect } from 'react-redux'

import {
  FETCH_ITEM_REQUEST,
  fetchItemRequest,
} from '../../../modules/item/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../../modules/item/selectors'
import { RootState } from '../../../modules/reducer'
import ItemSearchSection from './ItemSearchSection'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ItemSearchSection.types'

const mapState = (state: RootState): MapStateProps => ({
  isLoading: isLoadingType(getLoading(state), FETCH_ITEM_REQUEST),
  error: getError(state).fetch,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchItem: (itemId: string) => dispatch(fetchItemRequest(itemId)),
})

export default connect(mapState, mapDispatch)(ItemSearchSection)
