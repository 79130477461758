import { AxiosResponse } from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { microauthService } from '../../lib/api/MicroAuthService'
import {
  FetchAccessRequestAction,
  fetchAccessSuccess,
  fetchAccessFailure,
  FETCH_ACCESS_REQUEST,
  GRANT_ACCESS_REQUEST,
  GrantAccessRequestAction,
  grantAcessSuccess,
  grantAccessFailure,
  RevokeAccessRequestAction,
  REVOKE_ACCESS_REQUEST,
  revokeAccessSuccess,
  revokeAccessFailure,
} from './actions'
import { Access } from './types'

function* handleFetchAccessRequest(action: FetchAccessRequestAction) {
  try {
    const { clientId } = action.payload
    const access: Access[] = yield call(() =>
      microauthService.getAllAccess(clientId),
    )
    yield put(fetchAccessSuccess(access))
  } catch (error) {
    yield put(fetchAccessFailure(error.message))
  }
}

function* handleRevokeAccessRequest(action: RevokeAccessRequestAction) {
  try {
    const { id } = action.payload
    yield call(() => microauthService.revokeAccess(id))
    yield put(revokeAccessSuccess(id))
  } catch (error) {
    yield put(revokeAccessFailure(error.message))
  }
}

function* handleGrantAccessRequest(action: GrantAccessRequestAction) {
  try {
    const { email, clientId } = action.payload
    const { data: access }: AxiosResponse<Access> = yield call(() =>
      microauthService.addAccess(email, clientId),
    )
    yield put(grantAcessSuccess(access))
  } catch (error) {
    yield put(grantAccessFailure(error.message))
  }
}

export function* accessSaga() {
  yield all([
    takeEvery(FETCH_ACCESS_REQUEST, handleFetchAccessRequest),
    takeEvery(REVOKE_ACCESS_REQUEST, handleRevokeAccessRequest),
    takeEvery(GRANT_ACCESS_REQUEST, handleGrantAccessRequest),
  ])
}
