/**
 * Convert "SNAKE_CASE" string to "Title Case"
 * @param value
 */
export function toTitleCase(value: string): string {
  return value
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) =>
      word.length === 0
        ? word
        : word.length === 1
        ? word.toUpperCase()
        : `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`,
    )
    .join(' ')
}
