import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { POLL_ITEM_START, StartPollingItemAction } from './actions'
import { ItemState } from './reducer'

export const getState = (state: RootState): ItemState => state.item as ItemState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const isLoadingTypeByItemId = (
  state: RootState,
  type: string,
  itemId: string,
): boolean => {
  const loading = getLoading(state)

  return loading.some(
    (action: AnyAction) =>
      action.type === type && action.payload?.id === itemId,
  )
}

const findPollingStateType = (
  state: RootState,
  typePredicate: (pollingAction: StartPollingItemAction) => boolean,
) =>
  getLoading(state)
    .filter(
      (loadingState): loadingState is StartPollingItemAction =>
        loadingState.type === POLL_ITEM_START,
    )
    .filter(typePredicate)[0]

export const findPollingUpdatingState = (
  state: RootState,
  itemId: string,
): StartPollingItemAction | undefined =>
  findPollingStateType(
    state,
    (pollingAction) => pollingAction.payload.itemId === itemId,
  )

export const isPollingItemUpdate = (
  state: RootState,
  itemId: string,
): boolean => Boolean(findPollingUpdatingState(state, itemId))
