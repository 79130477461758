import { PageResponse } from 'pluggy-js'
import { action } from 'typesafe-actions'

import { AuthApiUser } from './types'

/// Fetch Users request

export const FETCH_USERS_REQUEST = '[Request] Fetch Users'
export const FETCH_USERS_SUCCESS = '[Success] Fetch Users'
export const FETCH_USERS_FAILURE = '[Failure] Fetch Users'

export const fetchUsersRequest = (search?: string) =>
  action(FETCH_USERS_REQUEST, {
    search,
  })
export const fetchUsersSuccess = (users: PageResponse<AuthApiUser>) =>
  action(FETCH_USERS_SUCCESS, { users })
export const fetchUsersFailure = (error: string) =>
  action(FETCH_USERS_FAILURE, { error })

export type FetchUsersRequestAction = ReturnType<typeof fetchUsersRequest>
export type FetchUsersSuccessAction = ReturnType<typeof fetchUsersSuccess>
export type FetchUsersFailureAction = ReturnType<typeof fetchUsersFailure>

/**
 *  Resend Verification Email Request
 */

export const RESEND_VERIFICATION_EMAIL_REQUEST =
  '[Request] Resend Verification Email'
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  '[Success] Resend Verification Email'
export const RESEND_VERIFICATION_EMAIL_FAILURE =
  '[Failure] Resend Verification Email'

export const resendVerificationEmailRequest = (userId: string) =>
  action(RESEND_VERIFICATION_EMAIL_REQUEST, { userId })
export const resendVerificationEmailSuccess = (userId: string) =>
  action(RESEND_VERIFICATION_EMAIL_SUCCESS, { userId })
export const resendVerificationEmailFailure = (error: string, userId: string) =>
  action(RESEND_VERIFICATION_EMAIL_FAILURE, { error, userId })

export type ResendVerificationEmailRequestAction = ReturnType<
  typeof resendVerificationEmailRequest
>
export type ResendVerificationEmailSuccessAction = ReturnType<
  typeof resendVerificationEmailSuccess
>
export type ResendVerificationEmailFailureAction = ReturnType<
  typeof resendVerificationEmailFailure
>
