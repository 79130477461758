/**
 * Helper to decide if a value is a valid Url string.
 *
 * Regex source: https://stackoverflow.com/a/15855457/6279385
 *
 * @param value
 */
export function isValidUrl(value: string): boolean {
  const urlRegExp =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  return urlRegExp.test(value)
}

/**
 * Helper to decide if a value is a valid HEX color string.
 *
 * Regex source: https://stackoverflow.com/a/9682781/6279385
 *
 * @param value
 */
export function isValidHexColor(value: string): boolean {
  const hexColorRegExp = /^#(?:[0-9a-f]{3}){1,2}$/i
  return hexColorRegExp.test(value)
}

/**
 * Helper to decide if a value looks like a valid email string.
 *
 * Regex source: https://www.w3resource.com/javascript/form/email-validation.php
 *
 * @param value
 */
export function isValidEmail(value: string): boolean {
  // disabling Eslint 'no-control-regex' rule to keep the regex as provided in the cited source
  const rfc2822StandardEmailValidationRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]: {2}(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  return rfc2822StandardEmailValidationRegex.test(value)
}

/**
 * Helper to validate if a value is an array of strings
 * @param values
 */
export function isStringsArray(values: unknown): values is string[] {
  return (
    Array.isArray(values) && values.every((value) => typeof value === 'string')
  )
}

/**
 * Helper to validate if a CNPJ string value
 *
 * Original sources:
 *  - https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
 *  - https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica
 * @param value
 */
export function isValidCnpj(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false
  }

  // Parse digits only from the string value
  const match = value.match(/\d/g)
  const numbers = Array.isArray(match)
    ? match.map((number) => Number(number))
    : []

  // Total digits count must be 14
  if (numbers.length !== 14) {
    return false
  }

  // Exclude values with all equal digits
  const items = [...new Set(numbers)]
  if (items.length === 1) {
    return false
  }

  // CNPJ-specific validation calculus
  const calc = (x: number) => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) {
        factor = 9
      }
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Check validation calculus and compare to last 2 digits (aka. 'digitos verificadores')
  const last2Digits = numbers.slice(12)

  // Validation check against 1st verification digit
  const first12Digits = calc(12)
  if (first12Digits !== last2Digits[0]) {
    return false
  }

  // Validation check against 2nd verification digit
  const digit1 = calc(13)
  return digit1 === last2Digits[1]
}
