import React, { useCallback, useState } from 'react'

import { Button, Card } from '@pluggyai/ui'
import type { SemanticCOLORS } from 'semantic-ui-react'

import { Incident } from '../../../modules/incident/types'
import { IncidentCreateModal } from '../IncidentCreateModal'
import { Props } from './IncidentItem.types'

import './IncidentItem.css'

const incidentImpactColorMap: Record<Incident['impact'], SemanticCOLORS> = {
  critical: 'red',
  maintenance: 'blue',
  major: 'orange',
  minor: 'yellow',
  none: 'grey',
}

const IncidentItem = ({ incident, connector }: Props) => {
  const {
    id,
    name,
    createdAt,
    impact,
    status,
    startedAt,
    incidentUpdates,
    resolvedAt,
  } = incident

  const [incidentUpdateModalOpen, setIncidentUpdateModalOpen] =
    useState<boolean>(false)
  const handleIncidentUpdateModalOpen = useCallback(() => {
    setIncidentUpdateModalOpen(true)
  }, [])
  const handleIncidentUpdateModalClose = useCallback(() => {
    setIncidentUpdateModalOpen(false)
  }, [])

  return (
    <>
      <Card
        className={'IncidentItem'}
        data-id={id}
        fluid
        color={incidentImpactColorMap[impact]}
      >
        <Card.Content>
          <Card.Header className={`ui ${incidentImpactColorMap[impact]}`}>
            {name}
          </Card.Header>
          <Card.Meta>
            Status: <strong>{status}</strong>- Impact: <strong>{impact}</strong>
          </Card.Meta>
          <Card.Description>
            Created {new Date(createdAt).toLocaleString()}, Started{' '}
            {startedAt ? new Date(startedAt).toLocaleString() : '-'}
            {resolvedAt &&
              `, Resolved ${new Date(resolvedAt).toLocaleString()}`}
            {incidentUpdates.map(
              ({
                body,
                id: incidentUpdateId,
                status: incidentUpdateStatus,
                createdAt: incidentUpdateCreatedAt,
              }) => (
                <div className={'incident-update'} key={incidentUpdateId}>
                  <strong>{incidentUpdateStatus}</strong> - <span>{body}</span>
                  <p>{new Date(incidentUpdateCreatedAt).toLocaleString()}</p>
                </div>
              ),
            )}
          </Card.Description>
        </Card.Content>
        <Card.Content extra className={'incident-actions'}>
          <Button primary onClick={handleIncidentUpdateModalOpen}>
            Update
          </Button>
        </Card.Content>
      </Card>
      {incidentUpdateModalOpen && (
        <IncidentCreateModal
          connector={connector}
          currentIncident={incident}
          onClose={handleIncidentUpdateModalClose}
        />
      )}
    </>
  )
}

export default React.memo(IncidentItem)
