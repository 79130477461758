import { IdToken } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'

import type { Auth0JwtPayload } from '../../../lib/vercel'
import { locations } from '../routing/locations'
import { ErrorResponse } from './sagas'
import { AuthResult, UserProfile } from './types'

export function getCallbackUrl(): string {
  return new URL(locations.authCallback(), document.baseURI).href
}

export function getAuth0Confguration(): {
  AUTH0_CLIENT_ID: string
  AUTH0_DOMAIN: string
  AUTH0_API_AUDIENCE: string
} {
  const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENTID,
    REACT_APP_AUTH0_API_AUDIENCE,
  } = process.env

  if (
    !REACT_APP_AUTH0_DOMAIN ||
    !REACT_APP_AUTH0_CLIENTID ||
    !REACT_APP_AUTH0_API_AUDIENCE
  ) {
    throw new Error(
      'Env vars REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, and REACT_APP_AUTH0_API_AUDIENCE must be defined!',
    )
  }

  return {
    AUTH0_CLIENT_ID: REACT_APP_AUTH0_CLIENTID,
    AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN,
    AUTH0_API_AUDIENCE: REACT_APP_AUTH0_API_AUDIENCE,
  }
}

const { REACT_APP_BACKOFFICE_API_URL: backofficeApiUrl = '' } = process.env
const tokenValidateLoginUrl = `${backofficeApiUrl}/api/validate`

/**
 * Validate Auth0 token against lambda, and retrieve a new Pluggy Api key
 * @param accessToken - the user Auth0 access token.
 */
export async function createPluggyApiKey(accessToken: string): Promise<string> {
  const { REACT_APP_PLUGGY_APP_KEY } = process.env
  if (REACT_APP_PLUGGY_APP_KEY) {
    return REACT_APP_PLUGGY_APP_KEY
  }

  // TODO use 'axios' instead of fetch.
  const response: Response = await fetch(tokenValidateLoginUrl, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      dataType: 'json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (!response.ok) {
    const errorResponse: ErrorResponse = await response.json()
    throw new Error(
      `Login failed: '${errorResponse.message}' (${response.status})`,
    )
  }

  const { apiKey } = await response.json()
  if (!apiKey) {
    throw new Error('Login failed: no apiKey in the response')
  }

  return apiKey
}

export async function buildAuthResult(
  idToken: IdToken,
  accessToken: string,
): Promise<AuthResult> {
  const auth0Profile: UserProfile = {
    auth0Id: idToken.sub,
    email: idToken.email,
    emailVerified: idToken.email_verified,
    name: idToken.name,
    updatedAt: idToken.updated_at,
    picture: idToken.picture,
  }

  const { scope = '' } = jwtDecode<Auth0JwtPayload>(accessToken)

  return {
    authenticated: true,
    expiresAt: idToken.exp,
    accessToken,
    profile: auth0Profile,
    scope,
  }
}
