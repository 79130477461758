import React, { useCallback, useEffect, useState } from 'react'

import { Button, Loader, Radio } from '@pluggyai/ui'
import { FormField, Grid, Segment } from 'semantic-ui-react'

import { formatDate } from '../../../lib/utils/date'
import { Props } from './ItemListItem.types'

import './ItemListItem.css'

const ItemListItem = ({
  item,
  isLoading,
  onUpdateItem,
  isPollingItemUpdate,
}: Props) => {
  const {
    id,
    status,
    connector,
    error,
    executionStatus,
    lastUpdatedAt,
    createdAt,
    requiresHistoricSync,
    updatable,
  } = item

  const [currentRequiresHistoricSync, setCurrentRequiresHistoricSync] =
    useState<boolean>()
  const [runExecution, setRunExecution] = useState(false)

  useEffect(() => {
    // initialize form state
    setCurrentRequiresHistoricSync(requiresHistoricSync)
  }, [requiresHistoricSync])

  useEffect(() => {
    if (isLoading) {
      // already submitting
      return
    }
    // update values to the new state
    setCurrentRequiresHistoricSync(requiresHistoricSync)
  }, [isLoading, requiresHistoricSync])

  const handleRequiresHistoricSyncChange = useCallback(
    (_e: React.FormEvent<HTMLInputElement>) => {
      setCurrentRequiresHistoricSync((previous) => !previous)
    },
    [],
  )

  const handleRunExecutionChange = useCallback(() => {
    setRunExecution((previous) => !previous)
  }, [])

  const handleItemUpdateClick = useCallback(() => {
    if (isLoading) {
      // already submitting
      return
    }
    if (currentRequiresHistoricSync === undefined) {
      console.error('Item data not yet loaded')
      return
    }

    onUpdateItem(item, {
      requiresHistoricSync: currentRequiresHistoricSync,
      runExecution,
    })
  }, [onUpdateItem, isLoading, currentRequiresHistoricSync, item, runExecution])

  const isUpdatable =
    updatable ||
    (!connector.hasMFA &&
      connector.credentials.every((credential) => !credential.mfa))

  return (
    <Segment className={'ItemListItem'} data-id={id}>
      {/* Id | Connector | Status | Actions */}
      <Grid columns={4} stackable>
        <Grid.Row>
          {/* Id */}
          <Grid.Column width={5}>
            <span className={'title'}>{id}</span>
            <br />
            Client id: {item.clientId}
          </Grid.Column>
          {/* Connector */}
          <Grid.Column width={3}>
            {connector.name} (id: {connector.id})
            <br />
            {connector.type}
          </Grid.Column>
          {/* Item Status data */}
          <Grid.Column width={5}>
            {status} - {executionStatus}
            {error && (
              <>
                <br />
                {`Message: '${error.message}'`}
              </>
            )}
            {isPollingItemUpdate && (
              <Loader className={'item-polling'} size={'S'} />
            )}
            <br />
            Created at: {formatDate(new Date(createdAt).toISOString())}
            <br />
            Last execution at:{' '}
            {lastUpdatedAt
              ? formatDate(new Date(lastUpdatedAt).toISOString())
              : '-'}
            <br />
            Updatable? <strong>{isUpdatable ? 'Yes' : 'No'}</strong>
            <br />
            Next update flagged for Historic sync?{' '}
            <strong>{requiresHistoricSync ? 'Yes' : 'No'}</strong>
          </Grid.Column>
          {/* Actions */}
          <Grid.Column width={3}>
            <div className={'item-actions'}>
              <FormField className={'item-requires-historic-sync'}>
                <Radio
                  label={'Requires historic sync'}
                  checked={currentRequiresHistoricSync === true}
                  onClick={handleRequiresHistoricSyncChange}
                />
              </FormField>
              <FormField className={'item-run-execution'}>
                <Radio
                  label={
                    <label>
                      Also run Item Execution
                      {!isUpdatable && (
                        <>
                          <br />
                          (NOTE: requires MFA)
                        </>
                      )}
                    </label>
                  }
                  checked={runExecution}
                  onClick={handleRunExecutionChange}
                />
              </FormField>
              <Button
                primary
                onClick={handleItemUpdateClick}
                loading={isLoading || isPollingItemUpdate}
                disabled={isLoading || isPollingItemUpdate}
              >
                Update
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default React.memo(ItemListItem)
