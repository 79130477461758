import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from '@pluggyai/ui'
import { Icon } from 'semantic-ui-react'

import { ApplicationShareLinkFields } from '../../../../modules/application/types'

import './EmailsInput.css'

type Emails = string[]

type Props = {
  onFieldChange: (
    fieldName: keyof ApplicationShareLinkFields,
    value: Emails,
  ) => void
  value: Emails
  errors?: string[]
  isSubmitting: boolean
}

const EMPTY_DEFAULT_VALUE = ''

const EmailsInput = ({
  onFieldChange,
  value: currentEmails,
  errors,
  isSubmitting,
}: Props) => {
  const { t } = useTranslation()

  const handleValueChange = useCallback(
    (value: Emails) => {
      onFieldChange('emails', value)
    },
    [onFieldChange],
  )

  const handleAddNewEmailInput = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // preventing default & propagation to prevent parent form unwanted 'submit' event
      event.preventDefault()
      event.stopPropagation()
      const newEmails = [...currentEmails, EMPTY_DEFAULT_VALUE]
      handleValueChange(newEmails)
    },
    [currentEmails, handleValueChange],
  )

  const handleValueEmailChange = useCallback(
    (emailValue: string, index: number) => {
      currentEmails[index] = emailValue.trim()
      handleValueChange([...currentEmails])
    },
    [handleValueChange, currentEmails],
  )

  const handleEmailRemove = useCallback(
    (index: number) => {
      let newCurrentEmails: Emails
      if (currentEmails.length === 1) {
        // only 1 available input, don't delete it, just clear it
        currentEmails[0] = EMPTY_DEFAULT_VALUE
        newCurrentEmails = [...currentEmails]
      } else {
        // more than one input available, remove it
        newCurrentEmails = [...currentEmails.filter((_, i) => i !== index)]
      }
      handleValueChange(newCurrentEmails)
    },
    [handleValueChange, currentEmails],
  )

  return (
    <div className={'EmailsInput'}>
      <div className={'emails-input-header field-container'}>
        <h4>{t('applicationShareLink.form.field.emails.header')}</h4>
        <Button
          link
          disabled={isSubmitting}
          onClick={handleAddNewEmailInput}
          type="button"
        >
          {t('applicationShareLink.form.field.emails.action.new')}
        </Button>
      </div>
      {currentEmails.map((email, i) => (
        <div key={`emails-${i}`} className={'add-item-row'}>
          <Input
            label={t('applicationShareLink.form.field.emails.label')}
            type="text"
            onChange={({ value }) => {
              handleValueEmailChange(value, i)
            }}
            value={email}
            error={errors?.[i]}
            disabled={isSubmitting}
          />
          <Icon
            className={'delete-item'}
            name="trash"
            link
            disabled={isSubmitting}
            onClick={() => handleEmailRemove(i)}
          />
        </div>
      ))}
    </div>
  )
}

export default React.memo(EmailsInput)
