import React, { useCallback, useState } from 'react'

import { Button } from '@pluggyai/ui'
import { formatDistanceToNow, isAfter } from 'date-fns'
import { Grid, Segment } from 'semantic-ui-react'

import { formatDate } from '../../../lib/utils/date'
import {
  buildCustomDemoUrlParts,
  buildMetabaseClientExplorerLink,
} from '../../../modules/application/utils'
import { ApplicationShareLinkCreateModal } from '../../ApplicationShareLinkCreateModal'
import { Props } from './ApplicationShareLinkItem.types'

import './ApplicationShareLinkItem.css'

const ApplicationShareLinkItem = ({
  applicationShareLink,
  coreApiApplication,
}: Props) => {
  const {
    id,
    customUrlDomain,
    expiresAt,
    createdAt,
    application: { clientId },
  } = applicationShareLink

  const [
    applicationShareLinkUpdateModalOpen,
    setApplicationShareLinkUpdateModalOpen,
  ] = useState<boolean>(false)
  const handleApplicationShareLinkUpdateModalOpen = useCallback(() => {
    setApplicationShareLinkUpdateModalOpen(true)
  }, [])
  const handleApplicationShareLinkUpdateModalClose = useCallback(() => {
    setApplicationShareLinkUpdateModalOpen(false)
  }, [])

  const isExpired = expiresAt && isAfter(new Date(), new Date(expiresAt))

  const [firstPart, lastPart] = buildCustomDemoUrlParts()
  const customUrl = [firstPart, customUrlDomain, lastPart].join('')

  return (
    <Segment className={'ApplicationShareLinkItem'} data-id={id}>
      {/* Url | CreatedAt/ExpiresAt | Actions */}
      <Grid columns={4} stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <span className={'title'}>{customUrl}</span>
            <br />
            {clientId && (
              <a
                className={'id link'}
                href={buildMetabaseClientExplorerLink(clientId)}
              >
                {clientId}
              </a>
            )}
          </Grid.Column>
          <Grid.Column width={5}>
            {coreApiApplication && (
              <>
                Items: {coreApiApplication.success || 0} Created |{' '}
                {coreApiApplication.errors || 0} Errors
              </>
            )}
            <br />
            Last connection at:{' '}
            {coreApiApplication?.lastUpdate
              ? formatDate(coreApiApplication.lastUpdate)
              : '-'}
          </Grid.Column>
          <Grid.Column width={4}>
            Created at: {formatDate(createdAt)}
            {expiresAt && (
              <span className={`expiresAt ${isExpired ? 'expired' : ''}`}>
                <br />
                {isExpired ? 'Expired' : 'Expires'}{' '}
                {formatDistanceToNow(new Date(expiresAt), {
                  addSuffix: true,
                })}
              </span>
            )}
          </Grid.Column>

          <Grid.Column width={2}>
            <Button primary onClick={handleApplicationShareLinkUpdateModalOpen}>
              Edit
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {applicationShareLinkUpdateModalOpen && (
        <ApplicationShareLinkCreateModal
          currentApplicationShareLink={applicationShareLink}
          onClose={handleApplicationShareLinkUpdateModalClose}
        />
      )}
    </Segment>
  )
}

export default React.memo(ApplicationShareLinkItem)
