import i18n from 'i18next'

import { IncidentFields } from '../../../../modules/incident/types'

const validations: Record<
  keyof IncidentFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  name: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (!value) {
      return i18n.t('incident.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 200
    if (value.length > maxLength) {
      return i18n.t('incident.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }

    return undefined
  },
  body: (fieldName: string, value: unknown): string | undefined => {
    if (typeof value !== 'string') {
      throw new Error('Not a string value')
    }
    if (!value) {
      return i18n.t('incident.form.error.empty', {
        fieldName,
      })
    }
    const maxLength = 500
    if (value.length > maxLength) {
      return i18n.t('incident.form.error.max-length', {
        fieldName,
        length: maxLength,
      })
    }

    return undefined
  },
  impact: (_fieldName: string, _value: unknown): string | undefined => {
    // always correct since it's a select input
    return undefined
  },
  status: (fieldName: string, value: unknown): string | undefined => {
    if (!value) {
      return i18n.t('incident.form.error.empty', {
        fieldName,
      })
    }
    // always correct since it's a select input
    return undefined
  },
  componentStatus: (fieldName: string, value: unknown): string | undefined => {
    if (!value) {
      return i18n.t('incident.form.error.empty', {
        fieldName,
      })
    }
    // always correct since it's a select input
    return undefined
  },
  deliverNotifications: (
    _fieldName: string,
    _value: unknown,
  ): string | undefined => {
    // always correct since it's a boolean input
    return undefined
  },
}

export function validateIncidentField(
  fieldName: keyof IncidentFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(`incident.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
