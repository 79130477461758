import React, { useState, useCallback } from 'react'

import { Button, Input } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { Props } from './MoveApplicationToProductionModal.types'

import './MoveApplicationToProductionModal.css'

const MoveApplicationToProductionModal = (props: Props) => {
  const { trigger, onMoveApplicationToProduction, application } = props

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const handleOnClick = useCallback((event: React.FormEvent<HTMLElement>) => {
    event.stopPropagation()
  }, [])

  const handleOnOpen = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  const handleOnClose = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const handleOnSubmit = useCallback(() => {
    onMoveApplicationToProduction(application)
    handleOnClose()
  }, [application, handleOnClose, onMoveApplicationToProduction])

  return (
    <Modal
      trigger={trigger}
      open={isModalOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      className={'MoveApplicationToProductionModal'}
      onClick={handleOnClick}
      size="mini"
      closeIcon
    >
      <Modal.Header>
        <span className="title">
          Move application <span>{application.name}</span> to production
        </span>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            This will <strong>change the client secret</strong> of the
            application and add auto-sync if it&apos;s enabled in the team.
          </p>
          <div>
            <p>
              To do this, you need to type the application&apos;s name{' '}
              <strong>{application.name}</strong>
            </p>
            <Input
              type="text"
              value={inputValue}
              onChange={({ value }) => setInputValue(value)}
              label="Application name"
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className={'modal-move-application-to-production-actions'}>
          <Button onClick={handleOnClose}>Cancel</Button>
          <Button
            onClick={handleOnSubmit}
            primary
            disabled={inputValue !== application.name}
          >
            Move
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(MoveApplicationToProductionModal)
