import { AxiosResponse } from 'axios'
import { PageResponse } from 'pluggy-js'

import {
  CreateTeamMemberRequest,
  CreateTeamRequest,
  Team,
  UpdateTeamRequest,
  UpdateTeamInvitationRoleRequest,
  UpdateTeamMemberRoleRequest,
} from '../../../modules/team/types'
import { replaceUrlParams } from '../utils'
import { BaseService } from './BaseService'

export class TeamsService extends BaseService {
  async createTeam(
    createTeamFields: CreateTeamRequest,
  ): Promise<AxiosResponse<Team>> {
    return await this.getServiceInstance().post(
      this.urls.POST_TEAM,
      createTeamFields,
    )
  }

  async createTeamMember(
    id: string,
    createTeamMemberFields: CreateTeamMemberRequest,
  ): Promise<AxiosResponse<Team>> {
    const createTeamMemberUrl = replaceUrlParams(this.urls.POST_TEAM_MEMBER, {
      id,
    })
    return await this.getServiceInstance().post(
      createTeamMemberUrl,
      createTeamMemberFields,
    )
  }

  async getTeams(search?: string): Promise<AxiosResponse<PageResponse<Team>>> {
    return await this.getServiceInstance().get(this.urls.GET_TEAMS, {
      params: {
        pageSize: 500,
        search,
      },
    })
  }

  async updateTeam(
    id: string,
    updateTeamFields: Partial<UpdateTeamRequest>,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamUrl = replaceUrlParams(this.urls.PATCH_TEAM, { id })

    return await this.getServiceInstance().patch(
      updateTeamUrl,
      updateTeamFields,
    )
  }

  async deleteTeam(id: string): Promise<AxiosResponse<void>> {
    const deleteTeamUrl = replaceUrlParams(this.urls.DELETE_TEAM, { id })

    return await this.getServiceInstance().delete(deleteTeamUrl)
  }

  async updateTeamMember(
    id: string,
    memberId: string,
    updateTeamMemberFields: UpdateTeamMemberRoleRequest,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamMemberUrl = replaceUrlParams(this.urls.PATCH_TEAM_MEMBER, {
      id,
      memberId,
    })
    return await this.getServiceInstance().patch(
      updateTeamMemberUrl,
      updateTeamMemberFields,
    )
  }

  async deleteTeamMember(
    id: string,
    memberId: string,
  ): Promise<AxiosResponse<Team>> {
    const deleteTeamMemberUrl = replaceUrlParams(this.urls.DELETE_TEAM_MEMBER, {
      id,
      memberId,
    })
    return await this.getServiceInstance().delete(deleteTeamMemberUrl)
  }

  async updateTeamInvitation(
    id: string,
    invitationId: string,
    updateTeamInvitationFields: UpdateTeamInvitationRoleRequest,
  ): Promise<AxiosResponse<Team>> {
    const updateTeamInvitationUrl = replaceUrlParams(
      this.urls.PATCH_TEAM_INVITATION,
      { id, invitationId },
    )
    return await this.getServiceInstance().patch(
      updateTeamInvitationUrl,
      updateTeamInvitationFields,
    )
  }

  async deleteTeamInvitation(
    id: string,
    invitationId: string,
  ): Promise<AxiosResponse<Team>> {
    const deleteTeamInvitationUrl = replaceUrlParams(
      this.urls.DELETE_TEAM_INVITATION,
      { id, invitationId },
    )
    return await this.getServiceInstance().delete(deleteTeamInvitationUrl)
  }
}
