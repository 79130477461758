import { connect } from 'react-redux'

import { grantAccessRequest } from '../../../modules/access/actions'
import CreateApplicationModal from './GrantAccessModal'
import { MapDispatchProps, MapDispatch } from './GrantAccessModal.types'

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onGrant: (email: string, clientId: string) =>
    dispatch(grantAccessRequest(email, clientId)),
})

export default connect(null, mapDispatch)(CreateApplicationModal)
