import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CLEAR_ITEM_ERROR,
  ClearItemErrorAction,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FetchItemFailureAction,
  FetchItemRequestAction,
  FetchItemSuccessAction,
  POLL_ITEM_START,
  POLL_ITEM_STOP,
  StartPollingItemAction,
  StopPollingItemAction,
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UpdateItemFailureAction,
  UpdateItemRequestAction,
  UpdateItemSuccessAction,
} from './actions'
import { Item } from './types'

export type ItemState = {
  data: Item[]
  loading: LoadingState
  error: {
    fetch: string | null
    update: string | null
  }
}

const INITIAL_STATE: ItemState = {
  data: [],
  loading: [],
  error: {
    fetch: null,
    update: null,
  },
}

type ItemReducerAction =
  | FetchItemRequestAction
  | FetchItemSuccessAction
  | FetchItemFailureAction
  | UpdateItemRequestAction
  | UpdateItemSuccessAction
  | UpdateItemFailureAction
  | ClearItemErrorAction
  | StartPollingItemAction
  | StopPollingItemAction

export function itemReducer(
  state: ItemState = INITIAL_STATE,
  action: ItemReducerAction,
): ItemState {
  switch (action.type) {
    case CLEAR_ITEM_ERROR: {
      return {
        ...state,
        error: INITIAL_STATE.error,
      }
    }
    case FETCH_ITEM_REQUEST: {
      return {
        ...state,
        error: {
          ...state.error,
          fetch: null,
        },
        loading: loadingReducer(state.loading, action),
      }
    }
    case UPDATE_ITEM_REQUEST: {
      return {
        ...state,
        error: {
          ...state.error,
          update: null,
        },
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_ITEM_SUCCESS:
    case UPDATE_ITEM_SUCCESS: {
      const {
        payload: { item },
        type,
      } = action
      const isFetch = type === FETCH_ITEM_SUCCESS

      // update or insert item on current state
      const currentItems = [...state.data]
      const i = currentItems.findIndex((_item) => _item.id === item.id)
      if (i > -1) {
        // found -> update item
        currentItems[i] = item
      } else {
        // not found -> insert
        currentItems.push(item)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          ...(isFetch ? { fetch: null } : { update: null }),
        },
        data: currentItems,
      }
    }
    case FETCH_ITEM_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          fetch: action.payload.error,
        },
      }
    }

    case UPDATE_ITEM_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: {
          ...state.error,
          update: action.payload.error,
        },
      }
    }
    case POLL_ITEM_START:
    case POLL_ITEM_STOP: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    default: {
      return state
    }
  }
}
