import React, { useCallback } from 'react'

import { Alert, Popup, Radio } from '@pluggyai/ui'
import {
  addDays,
  clamp,
  endOfDay,
  formatDistanceToNow,
  isAfter,
  isBefore,
} from 'date-fns'
import { FormField, Input as SemanticInput } from 'semantic-ui-react'

import { formatDateForDateInput } from '../../../../lib/utils/date'
import {
  FREE_TRIAL_INITIAL_LENGTH_IN_DAYS,
  FREE_TRIAL_MAXIMUM_LENGTH_AFTER_CREATED_AT_DAYS,
  FREE_TRIAL_MINIMUM_LENGTH_IN_DAYS,
} from '../../../../modules/team/types'
import { isSubscriptionPaid } from '../../../../modules/team/utils'
import { Props } from './FreeTrialField.types'

import './FreeTrialField.css'

const FreeTrialField = ({
  team,
  isSelected,
  onChange,
  freeTrialExpiresAtDate,
}: Props) => {
  const { subscription, createdAt } = team
  const { freeTrialExpiresAt } = subscription

  const currentFreeTrialExpiresAt = freeTrialExpiresAtDate

  const minExpiresAt = endOfDay(
    addDays(new Date(createdAt), FREE_TRIAL_MINIMUM_LENGTH_IN_DAYS),
  )
  const initialFreeTrialExpiresAt = endOfDay(
    addDays(new Date(createdAt), FREE_TRIAL_INITIAL_LENGTH_IN_DAYS),
  )
  const maxExpiresAtSinceNow = endOfDay(
    addDays(new Date(), FREE_TRIAL_MAXIMUM_LENGTH_AFTER_CREATED_AT_DAYS),
  )
  const maxExpiresAtSinceCreatedAt = endOfDay(
    addDays(
      new Date(createdAt),
      FREE_TRIAL_MAXIMUM_LENGTH_AFTER_CREATED_AT_DAYS,
    ),
  )

  const isMaxExpiresAtSinceCreatedAtExceeded = isAfter(
    new Date(),
    maxExpiresAtSinceCreatedAt,
  )

  const handleCurrentFreeTrialExpiresAtChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value: inputDateYyyyMmDd },
      } = event

      if (inputDateYyyyMmDd === '') {
        // invalid date
        return
      }

      const parsedExpiresAt = endOfDay(new Date(inputDateYyyyMmDd))

      const expiresAtLimited = clamp(parsedExpiresAt, {
        start: minExpiresAt,
        end: maxExpiresAtSinceNow,
      })

      onChange(expiresAtLimited)
    },
    [minExpiresAt, maxExpiresAtSinceNow, onChange],
  )

  const isFreeTrialExpired = Boolean(
    currentFreeTrialExpiresAt &&
      isBefore(currentFreeTrialExpiresAt, Date.now()),
  )

  const handleFreeTrialEnableClick = useCallback(() => {
    // set freeTrialExpiresAt to the previously stored value, or to a default calculated (2 weeks)
    const date = freeTrialExpiresAt
      ? new Date(freeTrialExpiresAt)
      : initialFreeTrialExpiresAt

    onChange(date)
  }, [freeTrialExpiresAt, initialFreeTrialExpiresAt, onChange])

  let freeTrialRadioWarningMessage: string | undefined = undefined

  if (!freeTrialExpiresAtDate) {
    freeTrialRadioWarningMessage =
      "This is not the first Team created by it's owner! Prefer updating that Team instead."
  } else if (isMaxExpiresAtSinceCreatedAtExceeded) {
    freeTrialRadioWarningMessage =
      'This team has been created more than 45 days ago.'
  }

  if (isSubscriptionPaid(subscription)) {
    freeTrialRadioWarningMessage = `${
      freeTrialRadioWarningMessage
        ? `${freeTrialRadioWarningMessage}\n\nAlso, `
        : ''
    }This Team is already in a Paid subscription.`
  }

  return (
    <FormField className={'FreeTrialField free-trial-expires-at-field-content'}>
      <Popup
        className={'TrialRadio-Popup'}
        position={'top center'}
        content={
          freeTrialRadioWarningMessage && (
            <Alert
              type={'warning'}
              size={'medium'}
              message={freeTrialRadioWarningMessage}
            />
          )
        }
        disabled={!freeTrialRadioWarningMessage}
        /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
                related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
        trigger={
          <div className={'TrialRadio-wrapper'}>
            <Radio
              label={'Trial'}
              checked={isSelected}
              onClick={handleFreeTrialEnableClick}
              type={'radio'}
            />
          </div>
        }
      />

      <div className={'expires-at-date-picker'}>
        <span>{"will be set back to 'Free' on:"}</span>
        <SemanticInput
          className={'date-picker'}
          type="date"
          onChange={handleCurrentFreeTrialExpiresAtChange}
          value={
            currentFreeTrialExpiresAt
              ? formatDateForDateInput(currentFreeTrialExpiresAt)
              : ''
          }
          min={formatDateForDateInput(minExpiresAt)}
          max={formatDateForDateInput(maxExpiresAtSinceNow)}
          disabled={!isSelected || currentFreeTrialExpiresAt === null}
          error={isFreeTrialExpired}
        />
        <span className={'expires-at-text'}>
          {currentFreeTrialExpiresAt &&
            formatDistanceToNow(new Date(currentFreeTrialExpiresAt), {
              addSuffix: true,
            })}
        </span>
      </div>
    </FormField>
  )
}

export default React.memo(FreeTrialField)
