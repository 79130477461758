import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import {
  ExecutionStatus,
  getErrorResponse,
  isPluggyServerError,
} from 'pluggy-js'
import {
  all,
  call,
  delay,
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects'

import { itemsService } from '../../lib/api/ItemsService'
import { addNotificationAction } from '../notification/actions'
import { NotificationOptions } from '../notification/types'
import {
  FETCH_ITEM_REQUEST,
  fetchItemFailure,
  fetchItemRequest,
  FetchItemRequestAction,
  fetchItemSuccess,
  POLL_ITEM_START,
  POLL_ITEM_STOP,
  startPollingItem,
  StartPollingItemAction,
  stopPollingItem,
  UPDATE_ITEM_REQUEST,
  updateItemFailure,
  UpdateItemRequestAction,
  updateItemSuccess,
} from './actions'
import { Item, UpdateItemRequest } from './types'

function* handleFetchItemRequest(action: FetchItemRequestAction) {
  const {
    payload: { id },
  } = action

  try {
    const { data: item }: AxiosResponse<Item> = yield call(() =>
      itemsService.getItem(id),
    )

    yield put(fetchItemSuccess(item))
  } catch (error) {
    let errorMessage = error.message

    if (isPluggyServerError(error)) {
      const errorResponse = getErrorResponse(error)
      if (errorResponse.message) {
        errorMessage = `${errorResponse.message} (${
          error.response?.status || error.code
        })`
      }
    }

    const message = i18next.t('items.error.fetch', {
      id,
      errorMessage,
    })

    yield put(fetchItemFailure(id, message))
  }
}

function* handleUpdateItemRequest(action: UpdateItemRequestAction) {
  const {
    payload: {
      item: { id },
      updateItemFields: { requiresHistoricSync, runExecution },
    },
  } = action

  // map item form fields to create request body
  const itemFields: UpdateItemRequest = {
    requiresHistoricSync,
    runExecution,
  }

  try {
    // submit update request
    const { data: item }: AxiosResponse<Item> = yield call(() =>
      itemsService.updateItem(id, itemFields),
    )
    yield put(updateItemSuccess(item))
    yield put(
      addNotificationAction({
        title: i18next.t('items.success.update.title'),
        message: i18next.t('items.success.update.message', { id }),
        duration: 4000,
        level: 'succeed',
      }),
    )
    yield put(startPollingItem(item.id))
  } catch (error) {
    let errorMessage = error.message
    if (isPluggyServerError(error)) {
      const errorResponse = getErrorResponse(error)
      if (errorResponse.message) {
        errorMessage = `${errorResponse.message} (${
          error.response?.status || error.code
        })`
      }
    }

    const message = i18next.t('items.error.update.message', {
      id,
      errorMessage,
    })
    yield put(
      addNotificationAction({
        title: i18next.t('items.error.update.title'),
        message,
        duration: 4000,
        level: 'error',
      }),
    )
    yield put(updateItemFailure(message))
    // do a fetch request again to retrieve the Item, in case its data has still been updated
    yield put(fetchItemRequest(id))
  }
}

const ITEM_STATUS_POLL_INTERVAL = 2500 // 2.5 seconds

function getPollTask(itemId: string) {
  return function* pollTask() {
    let shouldStopPolling = false

    while (!shouldStopPolling) {
      let item: Item

      // fetch item request
      try {
        ;({ data: item } = (yield call(() =>
          itemsService.getItem(itemId),
        )) as AxiosResponse<Item>)
      } catch (error) {
        let errorMessage = error.message

        if (isPluggyServerError(error)) {
          const errorResponse = getErrorResponse(error)
          if (errorResponse.message) {
            errorMessage = `${errorResponse.message} (${
              error.response?.status || error.code
            })`
          }
        }

        const itemFetchErrorMessage = i18next.t('items.error.fetch', {
          id: itemId,
          errorMessage,
        })
        console.error(
          'Unexpected error polling item status:',
          error,
          error.response,
        )

        yield put(fetchItemFailure(itemId, itemFetchErrorMessage))
        // stop polling
        yield put(stopPollingItem(itemId))

        yield put(
          addNotificationAction({
            level: 'error',
            title: 'Could not retrieve Item',
            message: itemFetchErrorMessage,
          }),
        )
        break
      }

      yield put(fetchItemSuccess(item))

      if (item.status === 'UPDATING') {
        // still 'UPDATING' status, check again after a delay
        yield delay(ITEM_STATUS_POLL_INTERVAL)
        continue
      }

      // item poll finished, check if resulted in success or error
      const isSuccess = item.status === 'UPDATED'
      const isError = ['LOGIN_ERROR', 'OUTDATED'].includes(item.status)

      const itemNotifcationMessage = `Item id ${item.id} (connector: ${item.connector.name}, id: ${item.connector.id}) finished with status: ${item.status}, execution status: ${item.executionStatus}.`

      const notification: NotificationOptions | null = isSuccess
        ? {
            level: 'succeed',
            title: 'Item sync finished successfully',
            message: itemNotifcationMessage,
          }
        : isError
        ? {
            level: 'warning',
            title: 'Item sync finished with error',
            message: `${itemNotifcationMessage}. Error: '${item.error?.message}'`,
          }
        : null

      if (notification) {
        yield put(addNotificationAction(notification))
      }

      shouldStopPolling =
        item.executionStatus !== ExecutionStatus.WAITING_USER_INPUT &&
        (item.status !== 'WAITING_USER_INPUT' || item.parameter !== null)

      if (shouldStopPolling) {
        // stop polling, unless status 'WAITING_USER_INPUT' but no 'parameter' data
        yield put(stopPollingItem(itemId))
      }
    }
  }
}

function* pollTaskWatcher(action: StartPollingItemAction) {
  const { itemId } = action.payload
  yield race([call(getPollTask(itemId)), take(POLL_ITEM_STOP)])
}

export function* itemSaga() {
  yield all([
    takeEvery(FETCH_ITEM_REQUEST, handleFetchItemRequest),
    takeEvery(UPDATE_ITEM_REQUEST, handleUpdateItemRequest),
    takeEvery(POLL_ITEM_START, pollTaskWatcher),
  ])
}
