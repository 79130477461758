import { PageResponse } from 'pluggy-js'

import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CLEAR_APPLICATION_SHARE_LINK_ERROR,
  ClearApplicationShareLinkErrorAction,
  CREATE_APPLICATION_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SHARE_LINK_FAILURE,
  CREATE_APPLICATION_SHARE_LINK_REQUEST,
  CREATE_APPLICATION_SHARE_LINK_SUCCESS,
  CREATE_APPLICATION_SUCCESS,
  CreateApplicationFailureAction,
  CreateApplicationRequestAction,
  CreateApplicationShareLinkFailureAction,
  CreateApplicationShareLinkRequestAction,
  CreateApplicationShareLinkSuccessAction,
  CreateApplicationSuccessAction,
  FETCH_APPLICATION_FAILURE,
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATIONS_FAILURE,
  FETCH_APPLICATIONS_REQUEST,
  FETCH_APPLICATIONS_SHARE_LINKS_FAILURE,
  FETCH_APPLICATIONS_SHARE_LINKS_REQUEST,
  FETCH_APPLICATIONS_SHARE_LINKS_SUCCESS,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_AUTH_API_APPLICATIONS_FAILURE,
  FETCH_AUTH_API_APPLICATIONS_REQUEST,
  FETCH_AUTH_API_APPLICATIONS_SUCCESS,
  FetchApplicationFailureAction,
  FetchApplicationRequestAction,
  FetchApplicationsFailureAction,
  FetchApplicationsRequestAction,
  FetchApplicationsShareLinksFailureAction,
  FetchApplicationsShareLinksRequestAction,
  FetchApplicationsShareLinksSuccessAction,
  FetchApplicationsSuccessAction,
  FetchApplicationSuccessAction,
  FetchAuthApiApplicationsFailureAction,
  FetchAuthApiApplicationsRequestAction,
  FetchAuthApiApplicationsSuccessAction,
  UPDATE_APPLICATION_SHARE_LINK_FAILURE,
  UPDATE_APPLICATION_SHARE_LINK_REQUEST,
  UPDATE_APPLICATION_SHARE_LINK_SUCCESS,
  UpdateApplicationShareLinkFailureAction,
  UpdateApplicationShareLinkRequestAction,
  UpdateApplicationShareLinkSuccessAction,
} from './actions'
import { Application, ApplicationShareLink, AuthApiApplication } from './types'

export type ApplicationState = {
  data: {
    coreApiApplications: Application[] | null
    authApiApplications: PageResponse<AuthApiApplication> | null
    applicationsShareLinks: ApplicationShareLink[] | null
  }
  loading: LoadingState
  error: string | null
}

export const INITIAL_STATE: ApplicationState = {
  data: {
    coreApiApplications: null,
    authApiApplications: null,
    applicationsShareLinks: null,
  },
  loading: [],
  error: null,
}

export type ApplicationReducerAction =
  | FetchApplicationsRequestAction
  | FetchApplicationsSuccessAction
  | FetchApplicationsFailureAction
  | FetchApplicationRequestAction
  | FetchApplicationSuccessAction
  | FetchApplicationFailureAction
  | CreateApplicationRequestAction
  | CreateApplicationSuccessAction
  | CreateApplicationFailureAction
  | FetchAuthApiApplicationsRequestAction
  | FetchAuthApiApplicationsSuccessAction
  | FetchAuthApiApplicationsFailureAction
  | CreateApplicationShareLinkRequestAction
  | CreateApplicationShareLinkSuccessAction
  | CreateApplicationShareLinkFailureAction
  | FetchApplicationsShareLinksRequestAction
  | FetchApplicationsShareLinksSuccessAction
  | FetchApplicationsShareLinksFailureAction
  | UpdateApplicationShareLinkRequestAction
  | UpdateApplicationShareLinkSuccessAction
  | UpdateApplicationShareLinkFailureAction
  | ClearApplicationShareLinkErrorAction

export function applicationReducer(
  state: ApplicationState = INITIAL_STATE,
  action: ApplicationReducerAction,
): ApplicationState {
  switch (action.type) {
    case FETCH_APPLICATIONS_REQUEST:
    case FETCH_APPLICATION_REQUEST:
    case CREATE_APPLICATION_REQUEST:
    case FETCH_AUTH_API_APPLICATIONS_REQUEST:
    case CREATE_APPLICATION_SHARE_LINK_REQUEST:
    case FETCH_APPLICATIONS_SHARE_LINKS_REQUEST:
    case UPDATE_APPLICATION_SHARE_LINK_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          coreApiApplications: action.payload.applications,
        },
      }
    case FETCH_AUTH_API_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          authApiApplications: action.payload.applications,
        },
      }
    case CREATE_APPLICATION_SHARE_LINK_SUCCESS:
    case UPDATE_APPLICATION_SHARE_LINK_SUCCESS: {
      const {
        payload: { applicationShareLink },
      } = action

      const updatedApplicationsShareLinks = [
        ...(state.data.applicationsShareLinks || []),
      ]

      const index = updatedApplicationsShareLinks.findIndex(
        (applicationShareLink_) =>
          applicationShareLink_.id === applicationShareLink.id,
      )

      if (index >= 0) {
        // is already in state, override it
        updatedApplicationsShareLinks[index] = { ...applicationShareLink }
      } else {
        // is new, add it
        updatedApplicationsShareLinks.push(applicationShareLink)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          applicationsShareLinks: updatedApplicationsShareLinks,
        },
      }
    }
    case FETCH_APPLICATIONS_SHARE_LINKS_SUCCESS: {
      const {
        payload: { applicationsShareLinks },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          applicationsShareLinks,
        },
      }
    }
    case CREATE_APPLICATION_SUCCESS:
    case FETCH_APPLICATION_SUCCESS:
      const { application } = action.payload

      // update or insert application on current state
      const updatedCoreApiApplications_ = [
        ...(state.data.coreApiApplications || []),
      ]
      const i = updatedCoreApiApplications_.findIndex(
        (_application) => _application.id === application.id,
      )
      if (i > -1) {
        // found -> update item
        updatedCoreApiApplications_[i] = application
      } else {
        // not found -> insert
        updatedCoreApiApplications_.push(application)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          coreApiApplications: updatedCoreApiApplications_,
        },
      }
    case CREATE_APPLICATION_FAILURE:
    case FETCH_APPLICATION_FAILURE:
    case FETCH_APPLICATIONS_FAILURE:
    case FETCH_AUTH_API_APPLICATIONS_FAILURE:
    case CREATE_APPLICATION_SHARE_LINK_FAILURE:
    case FETCH_APPLICATIONS_SHARE_LINKS_FAILURE:
    case UPDATE_APPLICATION_SHARE_LINK_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    case CLEAR_APPLICATION_SHARE_LINK_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    default:
      return state
  }
}
