import React, { useCallback, useEffect, useState } from 'react'

import { locations } from '../../modules/routing/locations'
import { ApplicationShareLinkCreateModal } from '../ApplicationShareLinkCreateModal'
import { Page } from '../Page'
import { TeamCreateModal } from '../Teams/TeamCreateModal'
import { TeamUpdateTermsModal } from '../Teams/TeamUpdateTermsModal'
import { ActionItem } from './ActionItem'
import { Props } from './OverviewPage.types'

import './OverviewPage.css'

const OverviewPage = ({ onFetchUsers, onNavigate }: Props) => {
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false)

  const handleOnCreateTeamModalOpen = useCallback(() => {
    setCreateTeamModalOpen(true)
  }, [])

  const handleOnCreateTeamModalClose = useCallback(() => {
    setCreateTeamModalOpen(false)
  }, [])

  // dispatch fetch users request, needed for TeamCreateModal
  useEffect(() => {
    // TODO improve this fetch logic when list becomes too large?
    //  ie. paginate?
    onFetchUsers()
  }, [onFetchUsers])

  const [
    createApplicationShareLinkModalOpen,
    setCreateApplicationShareLinkModalOpen,
  ] = useState(false)

  const handleOnCreateApplicationShareLinkModalOpen = useCallback(() => {
    setCreateApplicationShareLinkModalOpen(true)
  }, [])

  const handleOnCreateApplicationShareLinkModalClose = useCallback(() => {
    setCreateApplicationShareLinkModalOpen(false)
  }, [])

  const [teamUpdateTermsModalOpen, setTeamUpdateTermsModalOpen] =
    useState(false)

  const handleOnTeamUpdateTermsModalOpen = useCallback(() => {
    setTeamUpdateTermsModalOpen(true)
  }, [])

  const handleOnTeamUpdateTermsModalClose = useCallback(() => {
    setTeamUpdateTermsModalOpen(false)
  }, [])

  return (
    <Page className={'OverviewPage'}>
      <h1>{'Hey plugueiro/a!'}</h1>
      <p>What do you need to do today?</p>
      <div className={'actions-list'}>
        <ActionItem
          title={'Onboarding'}
          description={
            'Create a new team and delivery everything ready to the user.'
          }
          onClick={handleOnCreateTeamModalOpen}
        />
        <ActionItem
          title={'Users'}
          description={'Upgrade plans, see teams applications and more.'}
          onClick={() => onNavigate(locations.applications())}
        />
        <ActionItem
          title={'Demo links'}
          description={'Share customizable links to leads!'}
          onClick={handleOnCreateApplicationShareLinkModalOpen}
        />
        <ActionItem
          title={"Customize Client's Terms"}
          description={
            "Customize client's Terms/Privacy texts for Connect widget."
          }
          onClick={handleOnTeamUpdateTermsModalOpen}
        />
      </div>
      {createTeamModalOpen && (
        <TeamCreateModal onClose={handleOnCreateTeamModalClose} />
      )}
      {createApplicationShareLinkModalOpen && (
        <ApplicationShareLinkCreateModal
          onClose={handleOnCreateApplicationShareLinkModalClose}
        />
      )}
      {teamUpdateTermsModalOpen && (
        <TeamUpdateTermsModal onClose={handleOnTeamUpdateTermsModalClose} />
      )}
    </Page>
  )
}

export default React.memo(OverviewPage)
