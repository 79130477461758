import { AxiosResponse } from 'axios'
import { PageResponse } from 'pluggy-js'

import { AuthApiUser } from '../../../modules/user/types'
import { replaceUrlParams } from '../utils'
import { BaseService } from './BaseService'

export class UsersService extends BaseService {
  async getUsers(
    search?: string,
  ): Promise<AxiosResponse<PageResponse<AuthApiUser>>> {
    return await this.getServiceInstance().get(this.urls.GET_USERS, {
      params: { search, pageSize: 500 },
    })
  }

  async resendVerificationEmail(userId: string): Promise<AxiosResponse<void>> {
    const postVerificationEmailUrl = replaceUrlParams(
      this.urls.POST_VERIFICATION_EMAIL,
      { userId },
    )
    return await this.getServiceInstance().post(postVerificationEmailUrl)
  }
}
