import React, { useCallback, useEffect, useMemo } from 'react'

import { Button } from '@pluggyai/ui'

import { Connector } from '../../modules/connector/types'
import { ListSection } from '../ListSection'
import { Page } from '../Page'
import { ConnectorItem } from './ConnectorItem'
import { Props } from './ConnectorsPage.types'

import './ConnectorsPage.css'

const ConnectorsPage = ({
  connectors,
  isLoading,
  error,
  onFetchConnectors,
  onFetchIncidents,
  onUpdateConnectors,
}: Props) => {
  useEffect(() => {
    onFetchConnectors()
  }, [onFetchConnectors])

  useEffect(() => {
    onFetchIncidents()
  }, [onFetchIncidents])

  const connectorsSorted = useMemo(
    () =>
      [...(connectors || [])].sort((item1, item2) =>
        item1.id > item2.id ? 1 : -1,
      ),
    [connectors],
  )

  const handleConnectorsUpdate = useCallback(() => {
    onUpdateConnectors()
  }, [onUpdateConnectors])

  return (
    <Page className="ConnectorsPage">
      <div className={'connectors-actions'}>
        <div className="connectors-update-action">
          <Button link onClick={handleConnectorsUpdate}>
            Resync Connectors Data
          </Button>
        </div>
      </div>

      <ListSection
        title={'Connector'}
        isLoading={!connectors && isLoading}
        data={connectorsSorted}
        className={'ConnectorsList'}
        error={error}
        EmptyPlaceholder={
          <div className={'user-empty-message'}>No Connectors, yet</div>
        }
        renderValue={(connector: Connector) => (
          <ConnectorItem connector={connector} key={connector.id} />
        )}
      />
    </Page>
  )
}

export default React.memo(ConnectorsPage)
