import { initReactI18next } from 'react-i18next'

import i18n, { ResourceLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import EN from './locales/en/translation.json'
import PT from './locales/pt/translation.json'

const isDev = process.env.NODE_ENV === 'development'

const SUPPORTED_LANGUAGES = ['en', 'pt'] as const
export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number]

const FALLBACK_LANGUAGE: SupportedLanguage = 'en'

const resources: Record<SupportedLanguage, ResourceLanguage> = {
  en: {
    translation: EN,
  },
  pt: {
    translation: PT,
  },
}

// helper to update the document HTML 'lang' prop
function updateDocumentLang(lang: string) {
  return document.documentElement.setAttribute('lang', lang)
}

i18n.on('languageChanged', async (lng) => {
  // update root HTML document 'lang' prop
  updateDocumentLang(lng)
})

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['translation'],
    fallbackNS: 'translation',
    fallbackLng: FALLBACK_LANGUAGE,
    debug: isDev,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => isDev && console.log('i18n loaded!'))

// set the current language to the root document HTML
updateDocumentLang(i18n.language)
