import { Auth0ContextInterface } from '@auth0/auth0-react'
import { action } from 'typesafe-actions'

import { AuthResult } from './types'

/// Auth0 Application API login request

export const AUTH0_LOGIN_REQUEST = '[Request] Auth0 Login'
export const AUTH0_LOGIN_SUCCESS = '[Success] Auth0 Login'
export const AUTH0_LOGIN_FAILURE = '[Failure] Auth0 Login'

export const auth0LoginRequest = (
  getIdTokenClaims: Auth0ContextInterface['getIdTokenClaims'],
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) =>
  action(AUTH0_LOGIN_REQUEST, {
    getIdTokenClaims,
    getAccessTokenSilently,
  })
export const auth0LoginSuccess = (authResult: AuthResult) =>
  action(AUTH0_LOGIN_SUCCESS, { authResult })
export const auth0loginFailure = (error: string) =>
  action(AUTH0_LOGIN_FAILURE, { error })

export type Auth0LoginRequestAction = ReturnType<typeof auth0LoginRequest>
export type Auth0LoginSuccessAction = ReturnType<typeof auth0LoginSuccess>
export type Auth0LoginFailureAction = ReturnType<typeof auth0loginFailure>

/// Auth0 Application API Logout request

export const AUTH0_LOGOUT_REQUEST = '[Request] Auth0 Logout'
export const AUTH0_LOGOUT_SUCCESS = '[Success] Auth0 Logout'
export const AUTH0_LOGOUT_FAILURE = '[Failure] Auth0 Logout'

export const auth0LogoutRequest = () => action(AUTH0_LOGOUT_REQUEST)
export const auth0LogoutSuccess = () => action(AUTH0_LOGOUT_SUCCESS)
export const auth0LogoutFailure = (error: string) =>
  action(AUTH0_LOGOUT_FAILURE, { error })

export type Auth0LogoutRequestAction = ReturnType<typeof auth0LogoutRequest>
export type Auth0LogoutSuccessAction = ReturnType<typeof auth0LogoutSuccess>
export type Auth0LogoutFailureAction = ReturnType<typeof auth0LogoutFailure>

/// Pluggy API login request

export const PLUGGY_API_LOGIN_REQUEST = '[Request] PluggyApi Login'
export const PLUGGY_API_LOGIN_SUCCESS = '[Success] PluggyApi Login'
export const PLUGGY_API_LOGIN_FAILURE = '[Failure] PluggyApi Login'

export const pluggyApiLoginRequest = (authResult: AuthResult) =>
  action(PLUGGY_API_LOGIN_REQUEST, { authResult })
export const pluggyApiLoginSuccess = (authResult: AuthResult) =>
  action(PLUGGY_API_LOGIN_SUCCESS, { authResult })
export const pluggyApiLoginFailure = (error: string) =>
  action(PLUGGY_API_LOGIN_FAILURE, { error })

export type PluggyApiLoginRequestAction = ReturnType<
  typeof pluggyApiLoginRequest
>
export type PluggyApiLoginSuccessAction = ReturnType<
  typeof pluggyApiLoginSuccess
>
export type PluggyApiLoginFailureAction = ReturnType<
  typeof pluggyApiLoginFailure
>
