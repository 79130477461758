import React, { useCallback, useEffect, useState } from 'react'

import { Form, SearchBox } from '@pluggyai/ui'
import { usePrevious } from '@pluggyai/ui/dist/utils/hooks/usePrevious'
import { Segment } from 'semantic-ui-react'

import { Props } from './ItemSearchSection.types'

import './ItemSearchSection.css'

const ItemSearchSection = ({ error, isLoading, onFetchItem }: Props) => {
  const [searchItemId, setSearchItemId] = useState('')
  const [searchError, setSearchError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSearchBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchError(null)
      setSearchItemId(event.target.value.trim())
    },
    [],
  )

  const handleClear = useCallback(() => {
    if (isLoading || isSubmitting) {
      // don't allow clear if is loading or submitting
      // TODO: this should work in pluggyai/ui automatically by hiding/disabling the clear icon when SearchBox has the loading state
      return
    }
    setSearchItemId('')
  }, [isLoading, isSubmitting])

  const handleSearchSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading || isSubmitting) {
        // is already loading/submitting, wait
        return
      }

      event.preventDefault()

      // validate fields values
      if (searchItemId.length === 0) {
        setSearchError("Item id can't be empty")
        return
      }

      if (searchItemId.length !== 36) {
        setSearchError('Item id must be an UUID')
        return
      }

      setIsSubmitting(true)
      onFetchItem(searchItemId)
    },
    [isLoading, isSubmitting, searchItemId, onFetchItem],
  )

  const previousLoading = usePrevious(isLoading)
  useEffect(() => {
    const finishedLoading = previousLoading && !isLoading
    if (!isSubmitting || !finishedLoading) {
      return
    }
    // was waiting for search result, and finished -> clear form
    setIsSubmitting(false)
    setSearchError(null)
    setSearchItemId('')
  }, [handleClear, previousLoading, isLoading, isSubmitting])

  return (
    <Segment className={'ItemSearchSection'}>
      <Form className={'item-search-form'} onSubmit={handleSearchSubmit}>
        <SearchBox
          placeholder={'Search Item id'}
          size="medium"
          value={searchItemId}
          onChange={handleSearchBoxChange}
          onClear={handleClear}
          disabled={isLoading || isSubmitting}
          error={Boolean(searchError || error)}
        />
        <div className={'error-message'}>{searchError || error || ''}</div>
      </Form>
    </Segment>
  )
}

export default React.memo(ItemSearchSection)
