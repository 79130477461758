import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST,
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS,
  SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE,
  SubmitMoveApplicationToProductionRequestAction,
  SubmitMoveApplicationToProductionSuccessAction,
  SubmitMoveApplicationToProductionFailureAction,
} from './actions'

export type MoveApplicationToProductionState = {
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: MoveApplicationToProductionState = {
  loading: [],
  error: null,
}

type MoveApplicationToProductionReducerAction =
  | SubmitMoveApplicationToProductionRequestAction
  | SubmitMoveApplicationToProductionSuccessAction
  | SubmitMoveApplicationToProductionFailureAction

export function moveApplicationToProductionReducer(
  state: MoveApplicationToProductionState = INITIAL_STATE,
  action: MoveApplicationToProductionReducerAction,
): MoveApplicationToProductionState {
  switch (action.type) {
    case SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
      }
    case SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }

    default:
      return state
  }
}
