import React, { useEffect, useMemo } from 'react'

import { compareDesc } from 'date-fns'

import {
  Application,
  ApplicationShareLink,
} from '../../modules/application/types'
import { ListSection } from '../ListSection'
import { Page } from '../Page'
import { ApplicationShareLinkItem } from './ApplicationShareLinkItem'
import { Props } from './ApplicationsShareLinksPage.types'

const ApplicationsShareLinksPage = ({
  applicationsShareLinks,
  coreApiApplications,
  isLoading,
  error,
  onFetchApplicationsShareLinks,
  onFetchCoreApiApplications,
}: Props) => {
  useEffect(() => {
    onFetchApplicationsShareLinks()
  }, [onFetchApplicationsShareLinks])

  useEffect(() => {
    onFetchCoreApiApplications()
  }, [onFetchCoreApiApplications])

  const applicationsShareLinksSorted = useMemo(
    () =>
      [...(applicationsShareLinks || [])].sort((item1, item2) =>
        compareDesc(new Date(item1.createdAt), new Date(item2.createdAt)),
      ),
    [applicationsShareLinks],
  )

  const coreApiApplicationsByClientId = useMemo(() => {
    const coreApiApplicationsByClientId_: Record<
      string,
      Application | undefined
    > = {}
    if (!coreApiApplications) {
      return coreApiApplicationsByClientId_
    }

    // build map by client ids
    for (const coreApiApplication of coreApiApplications) {
      coreApiApplicationsByClientId_[coreApiApplication.id] = coreApiApplication
    }

    return coreApiApplicationsByClientId_
  }, [coreApiApplications])

  return (
    <Page className="ApplicationsShareLinksPage">
      <ListSection
        title={'Demo Link'}
        isLoading={!applicationsShareLinks && isLoading}
        data={applicationsShareLinksSorted}
        className={'ApplicationsShareLinksList'}
        error={error}
        EmptyPlaceholder={
          <div className={'user-empty-message'}>No Demo links created, yet</div>
        }
        renderValue={(applicationShareLink: ApplicationShareLink) => (
          <ApplicationShareLinkItem
            applicationShareLink={applicationShareLink}
            coreApiApplication={
              coreApiApplicationsByClientId[
                applicationShareLink.application.clientId
              ] || null
            }
            key={applicationShareLink.id}
          />
        )}
      />
    </Page>
  )
}

export default React.memo(ApplicationsShareLinksPage)
