import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Button, Input, Popup } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import { Grid, Segment } from 'semantic-ui-react'

import {
  CONNECTOR_STATUSES,
  ConnectorStatus,
} from '../../../modules/connector/types'
import { Incident, IncidentComponent } from '../../../modules/incident/types'
import { ListSection } from '../../ListSection'
import { IncidentCreateModal } from '../IncidentCreateModal'
import { IncidentItem } from '../IncidentItem'
import { Props } from './ConnectorItem.types'

import './ConnectorItem.css'

const connectorTypeOptions: Option[] = CONNECTOR_STATUSES.map(
  (status): Option => ({ id: status, name: status }),
)

const ConnectorItem = ({
  connector,
  incidents,
  isLoading,
  onUpdateConnector,
}: Props) => {
  const { id, name, type, health } = connector
  const connectorHealthStatus = health?.status

  const { t } = useTranslation()
  const [connectorStatus, setConnectorStatus] = useState<ConnectorStatus>()

  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    // initialize form state
    setConnectorStatus(connectorHealthStatus)
  }, [connectorHealthStatus])

  useEffect(() => {
    // finished submitting, set submit to false
    if (!submit || isLoading) {
      return
    }
    setSubmit(false)
    // update values to the new state
    setConnectorStatus(connectorHealthStatus)
  }, [isLoading, submit, connectorHealthStatus])

  const handleConnectorTypeChange = useCallback(
    ({ value }: { value: Option | Option[] }): void => {
      // casting 'as Option' to assert it's a single-option dropdown,
      // then cast again to assert the type of the inner value (TODO this should be supported by pluggyai/ui)
      const valueAsOption = value as Option
      const { id: status } = valueAsOption as { id: ConnectorStatus }

      if (status === connectorStatus) {
        // no change
        return
      }

      // update
      setSubmit(true)
      setConnectorStatus(status)
      onUpdateConnector(connector, { status })
    },
    [onUpdateConnector, connector, connectorStatus],
  )

  const connectorStatusOptionValue: Option = useMemo(
    () => ({ id: connectorStatus, name: connectorStatus }),
    [connectorStatus],
  )

  const openIncidents = useMemo(
    () =>
      incidents.filter(
        ({ status }) => status !== 'resolved' && status !== 'completed',
      ),
    [incidents],
  )

  const [incidentCreateModalOpen, setIncidentCreateModalOpen] =
    useState<boolean>(false)
  const handleIncidentCreateModalOpen = useCallback(() => {
    setIncidentCreateModalOpen(true)
  }, [])
  const handleIncidentCreateModalClose = useCallback(() => {
    setIncidentCreateModalOpen(false)
  }, [])

  const statusPageComponentStatus: IncidentComponent['status'] | null =
    openIncidents.length > 0
      ? openIncidents
          .map((incident) =>
            incident.components.find(
              (component) => String(component.connectorId) === String(id),
            ),
          )
          .filter((component): component is IncidentComponent =>
            Boolean(component),
          )[0]?.status
      : null

  return (
    <Segment className={'ConnectorItem'} data-id={id}>
      {/* Id | Name | Type | Actions */}
      <Grid columns={3} stackable>
        <Grid.Row>
          {/* Id */}
          <Grid.Column width={2}>
            <span className={'title'}>{id}</span>
          </Grid.Column>
          {/* Name */}
          <Grid.Column width={4}>{name}</Grid.Column>
          {/* Type */}
          <Grid.Column width={4}>{type}</Grid.Column>
          {/* Actions */}
          <Grid.Column width={6}>
            <div className={'connector-actions'}>
              <div className={'connector-status-action'}>
                <span>Status:</span>
                <Input
                  label={'Status'}
                  className={'connector-status'}
                  type="select"
                  options={connectorTypeOptions}
                  onChange={handleConnectorTypeChange}
                  value={connectorStatusOptionValue}
                  disabled={isLoading}
                />
                <Popup
                  className={'CreateIncidentButton-Popup'}
                  position={'top center'}
                  content={
                    'There is already an Open incident for this Connector'
                  }
                  disabled={openIncidents.length === 0}
                  /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
                        related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
                  trigger={
                    <div className={'CreateIncidentButton-wrapper'}>
                      <Button
                        secondary
                        disabled={openIncidents.length > 0}
                        onClick={handleIncidentCreateModalOpen}
                      >
                        Create Incident
                      </Button>
                    </div>
                  }
                />
              </div>

              <div className={'connector-incidents-action'}>
                <span>
                  {openIncidents.length > 0
                    ? `${openIncidents.length} Open Incident`
                    : 'No incidents'}
                </span>
              </div>
              {statusPageComponentStatus && (
                <div className={'connector-component-status'}>
                  <span>StatusPage Component status: </span>
                  <strong>{statusPageComponentStatus}</strong>
                </div>
              )}
              <div className={'connector-incidents-alert'}>
                {connectorHealthStatus !== 'ONLINE' &&
                  openIncidents.length === 0 && (
                    <Alert
                      type={'warning'}
                      size={'medium'}
                      message={t('connector.incident.alert.missing-incident', {
                        connectorHealthStatus,
                      })}
                    />
                  )}
                {connectorHealthStatus === 'ONLINE' &&
                  openIncidents.length > 0 && (
                    <Alert
                      type={'warning'}
                      size={'medium'}
                      message={t(
                        'connector.incident.alert.inconsistent-connector-status',
                        {
                          connectorHealthStatus,
                          openIncidentsCount: openIncidents.length,
                        },
                      )}
                    />
                  )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {openIncidents.length > 0 && (
        <ListSection
          title={'Incident'}
          isLoading={false}
          data={openIncidents}
          className={'ConnectorIncidents'}
          EmptyPlaceholder={
            <div className={'user-empty-message'}>No Incidents</div>
          }
          renderValue={(incident: Incident) => (
            <IncidentItem
              incident={incident}
              connector={connector}
              key={incident.id}
            />
          )}
        />
      )}
      {incidentCreateModalOpen && (
        <IncidentCreateModal
          connector={connector}
          onClose={handleIncidentCreateModalClose}
        />
      )}
    </Segment>
  )
}

export default React.memo(ConnectorItem)
