import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { AnyAction, applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import createSagasMiddleware from 'redux-saga'

import { analyticsMiddleware, initAnalytics } from './analytics/utils'
import { auth0LoginSuccess } from './auth/actions'
import { retrieveAuthResult } from './auth/storage'
import { AuthResult } from './auth/types'
import { createRootReducer } from './reducer'
import { rootSaga } from './sagas'

const isDev = process.env.NODE_ENV === 'development'
const anyWindow = window as Window &
  typeof globalThis & {
    // ignore eslint warning because 'any' type has been defined by Redux
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (_: Record<string, unknown>) => any
  }

const composeEnhancers =
  isDev && anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

const sagasMiddleware = createSagasMiddleware()
const loggerMiddleware = createLogger({
  collapsed: () => true,
  predicate: (_: unknown, action: AnyAction) =>
    isDev || action.type.includes('Failure'),
})

// init Segment analytics instance
initAnalytics()

const middleware = applyMiddleware(
  sagasMiddleware,
  routerMiddleware(history),
  loggerMiddleware,
  analyticsMiddleware(),
)

const enhancer = composeEnhancers(middleware)
const store = createStore(rootReducer, enhancer)

sagasMiddleware.run(rootSaga)

if (isDev) {
  const _window = window as typeof window & { getState: typeof store.getState }
  _window.getState = store.getState
}

// retrieve auth data from storage -> dispatch initial server login request (ie. 'POST /login')
const authResult: AuthResult | null = retrieveAuthResult()

if (authResult !== null) {
  // TODO: review/uncomment once refresh token is implemented -
  //  otherwise, the app just stucks when jwt expires which breaks it.
  //  This way, we can at least prompt the user to login again.
  // if (!isJwtExpired(auth0result.accessToken)) {
  store.dispatch(auth0LoginSuccess(authResult))
  // }
}

export { store }
