import { AxiosResponse } from 'axios'

import { Application, UpdateApplication } from '../../modules/application/types'
import { BaseService } from './BaseService'
import { replaceUrlParams } from './utils'

class ApplicationService extends BaseService {
  async getApplication(id: string): Promise<AxiosResponse<Application>> {
    const url = replaceUrlParams(this.urls.GET_APPLICATION, { id })
    return this.getServiceInstance().get(url)
  }

  async createApplication(name: string): Promise<AxiosResponse<Application>> {
    return this.getServiceInstance().post(this.urls.POST_APPLICATION, { name })
  }

  async updateApplication(
    client: UpdateApplication,
  ): Promise<AxiosResponse<Application>> {
    const url = replaceUrlParams(this.urls.PATCH_APPLICATION, {
      id: client.id,
    })
    return this.getServiceInstance().patch(url, client)
  }
}

export const applicationService = new ApplicationService()
