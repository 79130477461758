import { action } from 'typesafe-actions'

import { MoveApplicationToProductionFields } from './types'

/**
 * Move Application to Production Request Submit
 */
export const SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST =
  '[Request] Submit Move Application to Production'
export const SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS =
  '[Success] Submit Move Application to Production'
export const SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE =
  '[Failure] Submit Move Application to Production'

export const submitMoveApplicationToProductionRequest = (
  fields: MoveApplicationToProductionFields,
) => action(SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_REQUEST, fields)
export const submitMoveApplicationToProductionSuccess = () =>
  action(SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_SUCCESS)
export const submitMoveApplicationToProductionFailure = (error: string) =>
  action(SUBMIT_MOVE_APPLICATION_TO_PRODUCTION_FAILURE, { error })

export type SubmitMoveApplicationToProductionRequestAction = ReturnType<
  typeof submitMoveApplicationToProductionRequest
>
export type SubmitMoveApplicationToProductionSuccessAction = ReturnType<
  typeof submitMoveApplicationToProductionSuccess
>
export type SubmitMoveApplicationToProductionFailureAction = ReturnType<
  typeof submitMoveApplicationToProductionFailure
>
