import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { Auth0Provider } from '@auth0/auth0-react'
import { ConnectedRouter } from 'connected-react-router'

import { Routes } from './components/Routes'
import { Toasts } from './components/Toasts'
import './lib/i18n'
import { getAuth0Confguration, getCallbackUrl } from './modules/auth/utils'
import { history, store } from './modules/store'
import './themes'

import './index.css'

const { AUTH0_API_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } =
  getAuth0Confguration()

const permissions: string[] = [
  'manage:teams',
  'manage:users',
  'manage:applications',
  'manage:teams:subscription:revoke',
  'read:users:data:all',
  'write:users:data:all',
]

// list client desired Auth0 Application API permissions in the 'scope',
// so server API can confirm which Auth0 access_token permissions have been requested
const scope = permissions.join(' ')

const component = (
  <Provider store={store}>
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_API_AUDIENCE}
      redirectUri={getCallbackUrl()}
      scope={scope}
    >
      <ConnectedRouter history={history}>
        <Routes />
        <Toasts />
      </ConnectedRouter>
    </Auth0Provider>
  </Provider>
)

ReactDOM.render(component, document.getElementById('root'))
