import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'

import { Subscription } from '../team/types'
import { AuthApiUser } from '../user/types'

export type Application = {
  id: string
  name: string
  enabled: boolean | null
  subscription: Subscription
  countries?: string
  success?: number
  errors?: number
  lastUpdate?: string
  createdAt: Date
  updatedAt: Date
}

export type UpdateApplication = {
  id: string
  enabled: boolean | null | undefined
}

const ENVIRONMENT_TYPES = ['DEMO', 'DEVELOPMENT', 'PRODUCTION'] as const
type EnvironmentType = (typeof ENVIRONMENT_TYPES)[number]

export type AuthApiApplication = {
  id: string
  clientId: string
  name: string
  title: string
  shortDescription: string
  allowedOrigins: string[]
  logoImageUrl: string
  primaryColor: string
  enabled: boolean
  environment: EnvironmentType
  ownedByUserId: string | null
  ownedByTeamId: string | null
  subscription: Subscription
  createdBy: AuthApiUser
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export type CreateAuthApiApplicationRequest = {
  name: string
  environment: 'DEMO' | 'DEVELOPMENT' | 'PRODUCTION'
  shortDescription: string
  allowedOrigins: string[]
  ownedByUserId?: string | null
  ownedByTeamId?: string
  subscription?: Subscription
}

export type ApplicationShareLink = {
  id: string
  application: AuthApiApplication
  applicationId: string
  customUrlDomain: string
  emails: string[] | null
  emailDomain: string | null
  expiresAt: string | null
  createdAt: string
  updatedAt: string
}

export const EXPIRES_AT_OPTIONS = [
  'Never',
  '7 days',
  '1 month',
  '3 months',
] as const
export type ExpiresAtOption = (typeof EXPIRES_AT_OPTIONS)[number]

export function mapExpiresAtOptionToDate(
  expiresAtOption: ExpiresAtOption,
): Date | null {
  const now = new Date()

  if (expiresAtOption === '7 days') {
    return addDays(now, 7)
  }
  if (expiresAtOption === '1 month') {
    return addMonths(now, 1)
  }
  if (expiresAtOption === '3 months') {
    return addMonths(now, 3)
  }
  return null
}

export type ApplicationShareLinkFields = {
  customUrlDomain: string
  emails?: string[]
  emailDomain?: string
  expiresAtOption?: ExpiresAtOption
  expiresAt?: string | null
}

export type CreateApplicationShareLinkRequest = {
  customUrlDomain: string
  emails: string[] | null
  emailDomain: string | null
  expiresAt: Date | null
}

export type UpdateApplicationShareLinkRequest =
  Partial<CreateApplicationShareLinkRequest>

export type ApplicationShareLinkFieldsErrors = {
  customUrlDomain?: string
  emails?: string[]
  emailDomain?: string
  expiresAtOption?: string
  expiresAt?: string
}
