import { connect } from 'react-redux'

import {
  FETCH_ITEM_REQUEST,
  UPDATE_ITEM_REQUEST,
  updateItemRequest,
} from '../../../modules/item/actions'
import {
  isLoadingTypeByItemId,
  isPollingItemUpdate,
} from '../../../modules/item/selectors'
import { Item, UpdateItemRequest } from '../../../modules/item/types'
import { RootState } from '../../../modules/reducer'
import ItemListItem from './ItemListItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ItemListItem.types'

const mapState = (
  state: RootState,
  { item: { id: itemId } }: OwnProps,
): MapStateProps => ({
  isLoading:
    isLoadingTypeByItemId(state, UPDATE_ITEM_REQUEST, itemId) ||
    isLoadingTypeByItemId(state, FETCH_ITEM_REQUEST, itemId),
  isPollingItemUpdate: isPollingItemUpdate(state, itemId),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateItem: (item: Item, updateItemFields: UpdateItemRequest) =>
    dispatch(updateItemRequest(item, updateItemFields)),
})

export default connect(mapState, mapDispatch)(ItemListItem)
