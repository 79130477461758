import axios, { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { PageResponse } from 'pluggy-js'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { applicationService } from '../../lib/api/ApplicationService'
import { ApplicationService } from '../../lib/api/auth-api/ApplicationService'
import { ApplicationShareLinkService } from '../../lib/api/auth-api/ApplicationShareLinkService'
import { getAuth0AccessToken } from '../auth/selectors'
import { addNotificationAction } from '../notification/actions'
import { SHARED_DEMO_FULL_COMPLETE_SUBSCRIPTION } from '../team/types'
import {
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SHARE_LINK_REQUEST,
  createApplicationFailure,
  CreateApplicationRequestAction,
  createApplicationShareLinkFailure,
  CreateApplicationShareLinkRequestAction,
  createApplicationShareLinkSuccess,
  createApplicationSuccess,
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATIONS_SHARE_LINKS_REQUEST,
  FETCH_AUTH_API_APPLICATIONS_REQUEST,
  fetchApplicationFailure,
  FetchApplicationRequestAction,
  fetchApplicationsShareLinksFailure,
  fetchApplicationsShareLinksSuccess,
  fetchApplicationSuccess,
  fetchAuthApiApplicationsFailure,
  FetchAuthApiApplicationsRequestAction,
  fetchAuthApiApplicationsSuccess,
  UPDATE_APPLICATION_SHARE_LINK_REQUEST,
  updateApplicationShareLinkFailure,
  UpdateApplicationShareLinkRequestAction,
  updateApplicationShareLinkSuccess,
} from './actions'
import {
  Application,
  ApplicationShareLink,
  AuthApiApplication,
  CreateApplicationShareLinkRequest,
  CreateAuthApiApplicationRequest,
  mapExpiresAtOptionToDate,
} from './types'
import { buildCustomDemoUrl } from './utils'

function* handleFetchAuthApiApplicationsRequest(
  action: FetchAuthApiApplicationsRequestAction,
) {
  try {
    const { search } = action.payload
    const accessToken: string = yield select(getAuth0AccessToken)
    const applicationsService = new ApplicationService(accessToken)
    const {
      data: applications,
    }: AxiosResponse<PageResponse<AuthApiApplication>> = yield call(() =>
      applicationsService.getApplications(undefined, search),
    )
    yield put(fetchAuthApiApplicationsSuccess(applications))
  } catch (error) {
    yield put(fetchAuthApiApplicationsFailure(error.message))
  }
}

function* handleFetchApplicationRequest(action: FetchApplicationRequestAction) {
  try {
    const { id } = action.payload
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.getApplication(id),
    )

    yield put(fetchApplicationSuccess(application))
  } catch (error) {
    yield put(fetchApplicationFailure(error.message))
  }
}

function* handleCreateApplicationRequest(
  action: CreateApplicationRequestAction,
) {
  try {
    const { name } = action.payload
    const { data: application }: AxiosResponse<Application> = yield call(() =>
      applicationService.createApplication(name),
    )
    // TODO : obfuscate application secret?
    yield put(createApplicationSuccess(application))
  } catch (error) {
    yield put(createApplicationFailure(error.message))
  }
}

function* handleCreateApplicationShareLinkRequest(
  action: CreateApplicationShareLinkRequestAction,
) {
  const {
    payload: { applicationShareLinkFields },
  } = action

  const { customUrlDomain, emails, emailDomain, expiresAtOption } =
    applicationShareLinkFields

  // map applicationShareLinkFields to CreateApplicationShareLinkRequest object
  const emailsFiltered = emails
    ?.map((email) => email.trim())
    .filter((email) => email.trim().length > 0)

  const createApplicationShareLinkFields: CreateApplicationShareLinkRequest = {
    customUrlDomain,
    emailDomain: emailDomain || null,
    emails: emailsFiltered && emailsFiltered.length > 0 ? emailsFiltered : null,
    expiresAt: expiresAtOption
      ? mapExpiresAtOptionToDate(expiresAtOption)
      : null,
  }

  // get accessToken
  const accessToken: string = yield select(getAuth0AccessToken)
  if (!accessToken) {
    yield put(createApplicationShareLinkFailure('Missing authentication token'))
    return
  }

  // first, create new application
  let application: AuthApiApplication

  try {
    const applicationsService = new ApplicationService(accessToken)

    // create auth-api 'DEMO' application
    const createApplicationRequest: CreateAuthApiApplicationRequest = {
      name: `Shared Demo Application '${customUrlDomain}'`,
      environment: 'DEMO',
      shortDescription:
        'An instance of a shared Demo Application for testing Pluggy',
      allowedOrigins: [],
      // explicitly create the application without any User nor Team owner
      ownedByUserId: null,
      // use subscription FULL + all available products & features
      subscription: SHARED_DEMO_FULL_COMPLETE_SUBSCRIPTION,
    }
    const applicationAxiosResponse: AxiosResponse<AuthApiApplication> =
      yield call(() =>
        applicationsService.createApplication(createApplicationRequest),
      )

    ;({ data: application } = applicationAxiosResponse)
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }
    errorMessage = `Could not create application for applicationShareLink: ${errorMessage} ${
      status ? `(${status})` : ''
    }`
    yield put(createApplicationShareLinkFailure(errorMessage))
    return
  }

  // now, create an applicationShareLink for the created application
  try {
    const applicationSharelinksService = new ApplicationShareLinkService(
      accessToken,
    )

    const { data: applicationShareLink }: AxiosResponse<ApplicationShareLink> =
      yield call(() =>
        applicationSharelinksService.createApplicationShareLink(
          application.id,
          createApplicationShareLinkFields,
        ),
      )

    yield put(createApplicationShareLinkSuccess(applicationShareLink))

    yield put(
      addNotificationAction({
        title: i18next.t('applicationShareLink.success.create.title'),
        message: i18next.t('applicationShareLink.success.create.message', {
          customDemoUrl: buildCustomDemoUrl(customUrlDomain),
        }),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }
    errorMessage = `Could not create application share link: ${errorMessage} ${
      status ? `(${status})` : ''
    }`
    // TODO since this failed, we should delete the created application, for cleanup
    yield put(createApplicationShareLinkFailure(errorMessage))
    return
  }
}

function* handleFetchApplicationShareLinkRequest() {
  // get accessToken
  const accessToken: string = yield select(getAuth0AccessToken)
  if (!accessToken) {
    yield put(
      fetchApplicationsShareLinksFailure('Missing authentication token'),
    )
    return
  }

  try {
    const applicationSharelinksService = new ApplicationShareLinkService(
      accessToken,
    )

    const {
      data: applicationShareLinks,
    }: AxiosResponse<ApplicationShareLink[]> = yield call(() =>
      applicationSharelinksService.getApplicationsShareLinks(),
    )

    yield put(fetchApplicationsShareLinksSuccess(applicationShareLinks))
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }
    errorMessage = `Could not retrieve Demo links: ${errorMessage} ${
      status ? `(${status})` : ''
    }`
    yield put(fetchApplicationsShareLinksFailure(errorMessage))
    return
  }
}

function* handleUpdateApplicationShareLinkRequest(
  action: UpdateApplicationShareLinkRequestAction,
) {
  const {
    payload: { applicationId, applicationShareLinkFields },
  } = action

  const {
    customUrlDomain,
    emails,
    emailDomain,
    expiresAtOption,
    expiresAt: expiresAtInputString,
  } = applicationShareLinkFields

  // map applicationShareLinkFields to CreateApplicationShareLinkRequest object
  const emailsFiltered = emails
    ?.map((email) => email.trim())
    .filter((email) => email.trim().length > 0)

  let expiresAt: Date | null = null

  if (expiresAtInputString) {
    expiresAt = new Date(expiresAtInputString)
  } else if (expiresAtOption) {
    expiresAt = mapExpiresAtOptionToDate(expiresAtOption)
  }

  const createApplicationShareLinkFields: CreateApplicationShareLinkRequest = {
    customUrlDomain,
    emailDomain: emailDomain || null,
    emails: emailsFiltered && emailsFiltered.length > 0 ? emailsFiltered : null,
    expiresAt,
  }

  const accessToken: string = yield select(getAuth0AccessToken)
  if (!accessToken) {
    yield put(updateApplicationShareLinkFailure('Missing authentication token'))
    return
  }

  // now, create an applicationShareLink for the created application
  try {
    const applicationSharelinksService = new ApplicationShareLinkService(
      accessToken,
    )

    const { data: applicationShareLink }: AxiosResponse<ApplicationShareLink> =
      yield call(() =>
        applicationSharelinksService.updateApplicationShareLink(
          applicationId,
          createApplicationShareLinkFields,
        ),
      )

    yield put(updateApplicationShareLinkSuccess(applicationShareLink))

    yield put(
      addNotificationAction({
        title: i18next.t('applicationShareLink.success.update.title'),
        message: i18next.t('applicationShareLink.success.update.message', {
          customDemoUrl: buildCustomDemoUrl(customUrlDomain),
        }),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    let errorMessage: string = error.message
    let status: string | undefined = undefined
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data) {
        ;({ message: errorMessage } = error.response.data as {
          message: string
        })
      }
      ;({ status } = error)
    }
    errorMessage = `Could not update application share link: ${errorMessage} ${
      status ? `(${status})` : ''
    }`
    yield put(updateApplicationShareLinkFailure(errorMessage))
    return
  }
}
export function* applicationSaga() {
  yield all([
    takeEvery(
      FETCH_AUTH_API_APPLICATIONS_REQUEST,
      handleFetchAuthApiApplicationsRequest,
    ),
    takeEvery(FETCH_APPLICATION_REQUEST, handleFetchApplicationRequest),
    takeEvery(CREATE_APPLICATION_REQUEST, handleCreateApplicationRequest),
    takeEvery(
      CREATE_APPLICATION_SHARE_LINK_REQUEST,
      handleCreateApplicationShareLinkRequest,
    ),
    takeEvery(
      FETCH_APPLICATIONS_SHARE_LINKS_REQUEST,
      handleFetchApplicationShareLinkRequest,
    ),
    takeEvery(
      UPDATE_APPLICATION_SHARE_LINK_REQUEST,
      handleUpdateApplicationShareLinkRequest,
    ),
  ])
}
