import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CLEAR_CONNECTOR_ERROR,
  ClearConnectorErrorAction,
  FETCH_CONNECTORS_FAILURE,
  FETCH_CONNECTORS_REQUEST,
  FETCH_CONNECTORS_SUCCESS,
  FetchConnectorsFailureAction,
  FetchConnectorsRequestAction,
  FetchConnectorsSuccessAction,
  UPDATE_CONNECTOR_FAILURE,
  UPDATE_CONNECTOR_REQUEST,
  UPDATE_CONNECTOR_SUCCESS,
  UpdateConnectorFailureAction,
  UpdateConnectorRequestAction,
  UpdateConnectorSuccessAction,
  UpdateConnectorsRequestAction,
  UpdateConnectorsSuccessAction,
  UpdateConnectorsFailureAction,
  UPDATE_CONNECTORS_REQUEST,
} from './actions'
import { Connector } from './types'

export type ConnectorState = {
  data: Connector[] | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ConnectorState = {
  data: null,
  loading: [],
  error: null,
}

type ConnectorReducerAction =
  | FetchConnectorsRequestAction
  | FetchConnectorsSuccessAction
  | FetchConnectorsFailureAction
  | UpdateConnectorRequestAction
  | UpdateConnectorSuccessAction
  | UpdateConnectorFailureAction
  | ClearConnectorErrorAction
  | UpdateConnectorsRequestAction
  | UpdateConnectorsSuccessAction
  | UpdateConnectorsFailureAction

export function connectorReducer(
  state: ConnectorState = INITIAL_STATE,
  action: ConnectorReducerAction,
): ConnectorState {
  switch (action.type) {
    case CLEAR_CONNECTOR_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case FETCH_CONNECTORS_REQUEST:
    case UPDATE_CONNECTOR_REQUEST:
    case UPDATE_CONNECTORS_REQUEST: {
      return {
        ...state,
        error: null,
        loading: loadingReducer(state.loading, action),
      }
    }
    case UPDATE_CONNECTOR_SUCCESS: {
      const { connector } = action.payload

      // update or insert connector on current state
      const currentConnectors = [...(state.data || [])]
      const i = currentConnectors.findIndex(
        (_connector) => _connector.id === connector.id,
      )
      if (i > -1) {
        // found -> update item
        currentConnectors[i] = connector
      } else {
        // not found -> insert
        currentConnectors.push(connector)
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: currentConnectors,
      }
    }
    case FETCH_CONNECTORS_SUCCESS:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: action.payload.connectors,
      }
    case FETCH_CONNECTORS_FAILURE:
    case UPDATE_CONNECTOR_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default: {
      return state
    }
  }
}
