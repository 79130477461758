import { connect } from 'react-redux'

import { ConnectorId } from '../../../../lib/ConnectorId'
import {
  CREATE_INCIDENT_REQUEST,
  createIncidentRequest,
  updateIncidentRequest,
} from '../../../modules/incident/actions'
import {
  getError,
  getLoading,
  isLoadingType,
} from '../../../modules/incident/selectors'
import { Incident, IncidentFields } from '../../../modules/incident/types'
import { RootState } from '../../../modules/reducer'
import IncidentCreateModal from './IncidentCreateModal'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './IncidentCreateModal.types'

const mapState = (state: RootState): MapStateProps => ({
  error: getError(state),
  isLoading: isLoadingType(getLoading(state), CREATE_INCIDENT_REQUEST),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateIncident: (
    createIncidentFields: IncidentFields,
    connectorId: ConnectorId,
  ) => dispatch(createIncidentRequest(createIncidentFields, connectorId)),
  onUpdateIncident: (
    incident: Incident,
    updateIncidentFields: IncidentFields,
    connectorId: ConnectorId,
  ) =>
    dispatch(
      updateIncidentRequest(incident, updateIncidentFields, connectorId),
    ),
})

export default connect(mapState, mapDispatch)(IncidentCreateModal)
