import React, { useCallback } from 'react'

import { Props } from './ActionItem.types'

import './ActionItem.css'

const ActionItem = ({ title, description, onClick }: Props) => {
  // implement keyDown handler for accessibility purposes,
  // since this is a <div> with role='button'
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key !== 'Enter') {
        // ignore key
        return
      }
      // trigger onClick too
      onClick()
    },
    [onClick],
  )
  return (
    <div
      className={'ActionItem'}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  )
}

export default React.memo(ActionItem)
