import i18n from 'i18next'

import { CustomizationFields } from '../../../modules/customization/types'

const TERMS_MAX_LENGTH = 30_000 // 30kb

function validateTermsOfUseField(
  fieldName: string,
  value: unknown,
): string | undefined {
  // value can be null, or string
  if (value === null) {
    return undefined
  }

  if (typeof value !== 'string') {
    throw new Error('Not a string value')
  }
  if (value.length === 0) {
    return i18n.t('customization.form.error.empty', {
      fieldName,
    })
  }
  if (value.length > TERMS_MAX_LENGTH) {
    return i18n.t('customization.form.error.max-length', {
      fieldName,
      length: TERMS_MAX_LENGTH,
    })
  }
  return undefined
}

const validations: Record<
  keyof CustomizationFields,
  (fieldName: string, value: unknown) => string | undefined
> = {
  termsAndConditions: validateTermsOfUseField,
  privacyPolicy: validateTermsOfUseField,
}

export function validateCustomizationField(
  fieldName: keyof CustomizationFields,
  value: unknown,
): string | undefined {
  const fieldLabel = i18n.t(`customization.form.field.${fieldName}.label`)
  return validations[fieldName](fieldLabel, value)
}
