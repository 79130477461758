import React, { useCallback, useState } from 'react'

import { Button } from '@pluggyai/ui'

import { Team } from '../../modules/team/types'
import { ListSection } from '../ListSection'
import { TeamCreateModal } from './TeamCreateModal'
import { TeamListItem } from './TeamItem'
import { Props } from './Teams.types'

import './Teams.css'

const Teams = ({ user, application, teams, error, isLoading }: Props) => {
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false)

  const handleOnCreateTeamModalOpen = useCallback(() => {
    setCreateTeamModalOpen(true)
  }, [])

  const handleOnCreateTeamModalClose = useCallback(() => {
    setCreateTeamModalOpen(false)
  }, [])

  return (
    <>
      <ListSection
        className={'Teams'}
        data={teams || []}
        isLoading={!teams && isLoading}
        error={error}
        title={'Team'}
        renderValue={(team: Team) => <TeamListItem key={team.id} team={team} />}
        NewComponentButton={
          !user &&
          !application && (
            <Button primary onClick={handleOnCreateTeamModalOpen}>
              Create Team
            </Button>
          )
        }
        EmptyPlaceholder={
          (user || application) && (
            <div className={'user-empty-message'}>
              {user ? 'User' : 'Application'}{' '}
              <b>{user ? user.name : application?.name}</b> doesn&apos;t belong
              to any team
            </div>
          )
        }
      />
      {createTeamModalOpen && (
        <TeamCreateModal onClose={handleOnCreateTeamModalClose} />
      )}
    </>
  )
}

export default React.memo(Teams)
