import { action } from 'typesafe-actions'

import { Team } from '../team/types'
import { Customization, CustomizationFields } from './types'

/// Fetch Customization request

export const FETCH_CUSTOMIZATION_REQUEST = '[Request] Fetch Customization'
export const FETCH_CUSTOMIZATION_SUCCESS = '[Success] Fetch Customization'
export const FETCH_CUSTOMIZATION_FAILURE = '[Failure] Fetch Customization'

export const fetchCustomizationRequest = (team: Team) =>
  action(FETCH_CUSTOMIZATION_REQUEST, { team })
export const fetchCustomizationSuccess = (
  team: Team,
  customization: Customization,
) => action(FETCH_CUSTOMIZATION_SUCCESS, { team, customization })
export const fetchCustomizationFailure = (team: Team, error: string) =>
  action(FETCH_CUSTOMIZATION_FAILURE, { team, error })

export type FetchCustomizationRequestAction = ReturnType<
  typeof fetchCustomizationRequest
>
export type FetchCustomizationSuccessAction = ReturnType<
  typeof fetchCustomizationSuccess
>
export type FetchCustomizationFailureAction = ReturnType<
  typeof fetchCustomizationFailure
>

/// Update Customization request

export const UPDATE_CUSTOMIZATION_REQUEST = '[Request] Update Customization'
export const UPDATE_CUSTOMIZATION_SUCCESS = '[Success] Update Customization'
export const UPDATE_CUSTOMIZATION_FAILURE = '[Failure] Update Customization'

export const updateCustomizationRequest = (
  team: Team,
  updateCustomizationFields: CustomizationFields,
) =>
  action(UPDATE_CUSTOMIZATION_REQUEST, {
    team,
    updateCustomizationFields,
  })
export const updateCustomizationSuccess = (
  team: Team,
  customization: Customization,
) => action(UPDATE_CUSTOMIZATION_SUCCESS, { team, customization })
export const updateCustomizationFailure = (team: Team, error: string) =>
  action(UPDATE_CUSTOMIZATION_FAILURE, { team, error })

export type UpdateCustomizationRequestAction = ReturnType<
  typeof updateCustomizationRequest
>
export type UpdateCustomizationSuccessAction = ReturnType<
  typeof updateCustomizationSuccess
>
export type UpdateCustomizationFailureAction = ReturnType<
  typeof updateCustomizationFailure
>
