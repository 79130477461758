import React from 'react'

import { AuthApiUser } from '../../modules/user/types'
import { ListSection } from '../ListSection'
import { UserListItem } from './UserItem'
import { Props } from './Users.types'

import './Users.css'

const Users = ({ users, error, isLoading }: Props) => {
  return (
    <ListSection
      className={'Users'}
      data={users || []}
      isLoading={!users && isLoading}
      error={error}
      title={'User'}
      renderValue={(user: AuthApiUser) => (
        <UserListItem key={user.id} user={user} />
      )}
    />
  )
}

export default React.memo(Users)
