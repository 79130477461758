import { AnyAction } from 'redux'

import { AuthApiApplication } from '../application/types'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { TeamState } from './reducer'
import { Team } from './types'

export const getState = (state: RootState): TeamState => state.team as TeamState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getTeamByApplication = (
  state: RootState,
  application: AuthApiApplication,
): Team | null =>
  getData(state)?.results.find(
    (team) => team.id === application.ownedByTeamId,
  ) || null
