import { AuthResult } from './types'

const AUTH_STORAGE_KEY = 'auth'

export function retrieveAuthResult(): AuthResult | null {
  const authJson = localStorage.getItem(AUTH_STORAGE_KEY)
  if (!authJson) {
    return null
  }
  return JSON.parse(authJson)
}

export function storeAuthResult(authResult: AuthResult): void {
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authResult))
}

export function clearAuthResult(): void {
  localStorage.removeItem('auth')
}
