import i18next from 'i18next'

import { FULL_PRODUCTS, Subscription, Team, TeamRole } from './types'

export function isTeamActionsRequired(team: Team): boolean {
  return team.cnpj === null
}

const teamRolesI18nKeyMap: Record<TeamRole, string> = {
  OWNER: 'team.teamRole.owner',
  ADMIN: 'team.teamRole.admin',
  MEMBER: 'team.teamRole.member',
}

export function mapTeamRoleValueToI18nText(teamRoleValue: TeamRole): string {
  const teamRoleFilterKey = teamRolesI18nKeyMap[teamRoleValue]
  return i18next.t(teamRoleFilterKey)
}

/**
 * Helper to get a representation of the subscription value as a readable string.
 *
 * @param subscription
 */
export function mapSubscriptionToString(subscription: Subscription): string {
  const { products, plan, production } = subscription

  if (plan === 'FREE') {
    return 'Free'
  }

  if (plan === 'FREE_PLUS') {
    return 'Free+'
  }

  if (plan === 'TRIAL') {
    return 'Trial (all products & features)'
  }

  const subscriptionString = 'Full'

  const extraProducts = products.filter(
    (product) => !FULL_PRODUCTS.includes(product),
  )

  const withProductsString =
    extraProducts.length > 0
      ? ` (with: ${extraProducts.sort().join(', ')})`
      : ''

  const inProductionString = production ? ' - In Production' : ''

  return `${subscriptionString}${withProductsString}${inProductionString}`
}

/**
 * Helper to check if a subscription is Paid, which means plan FULL or superior
 *
 * @param subscription
 */
export function isSubscriptionPaid(subscription: Subscription): boolean {
  // is paid if is in 'FULL' or superior subscription
  return subscription.plan === 'FULL' || subscription.plan === 'FULL_PLUS'
}
