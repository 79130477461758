import { connect } from 'react-redux'

import {
  fetchApplicationsRequest,
  fetchAuthApiApplicationsRequest,
} from '../../modules/application/actions'
import { RootState } from '../../modules/reducer'
import { fetchTeamsRequest } from '../../modules/team/actions'
import { fetchUsersRequest } from '../../modules/user/actions'
import ApplicationsPage from './ApplicationsPage'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ApplicationsPage.types'

const mapState = (_state: RootState): MapStateProps => ({
  // nothing here, yet
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchTeams: (search?: string) => dispatch(fetchTeamsRequest(search)),
  onFetchUsers: (search?: string) => dispatch(fetchUsersRequest(search)),
  onFetchAuthApiApplications: (search?: string) =>
    dispatch(fetchAuthApiApplicationsRequest(search)),
  onFetchApplications: () => dispatch(fetchApplicationsRequest()),
})

export default connect(mapState, mapDispatch)(ApplicationsPage)
