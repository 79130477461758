import React, { useCallback, useEffect, useState } from 'react'

import { Popup } from '@pluggyai/ui'
import { Button, Grid, Segment, Table } from 'semantic-ui-react'

import { buildMetabaseClientExplorerLink } from '../../../modules/application/utils'
import { Teams } from '../../Teams'
import { GrantAccessModal } from '../GrantAccess'
import { Props } from './ApplicationItem.types'

import './ApplicationItem.css'

const ApplicationItem = ({
  application,
  access,
  onFetchAccess,
  onRevokeAccess,
}: Props) => {
  const [isShowingDetail, setIsShowingDetail] = useState<boolean>(false)

  const handleToggleDetail = useCallback(() => {
    setIsShowingDetail(!isShowingDetail)
  }, [isShowingDetail, setIsShowingDetail])

  useEffect(() => {
    if (!isShowingDetail) {
      return
    }
    onFetchAccess(application.id)
  }, [onFetchAccess, isShowingDetail, application])

  const [displayTeamsList, setDisplayTeamsList] = useState<boolean>(false)

  const handleTeamsListToggle = useCallback(() => {
    setDisplayTeamsList((value) => !value)
  }, [])

  return (
    <Segment className={'ApplicationItem'} key={application.id}>
      <Grid columns={4}>
        <Grid.Row>
          <Grid.Column width={7}>
            {application.name}
            <br />
            <a
              className={'link'}
              href={buildMetabaseClientExplorerLink(application.id)}
            >
              {application.id}
            </a>
          </Grid.Column>
          <Grid.Column width={3}>
            <div className={'application-actions'}>
              <Popup
                className={'ToggleTeams-Popup'}
                position={'top center'}
                content={"This application doesn't exist in Dashboard"}
                /* note: wrapping children into a div to properly hide/show the Popup even if 'disabled'
                        related issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/1413 */
                trigger={
                  <div className={'ToggleTeams-wrapper'}>
                    <Button primary size="mini" onClick={handleTeamsListToggle}>
                      {displayTeamsList ? 'Hide Team' : 'View Team'}
                    </Button>
                  </div>
                }
              />
              <Button
                primary
                inverted
                size="mini"
                onClick={() => handleToggleDetail()}
              >
                Access Details
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isShowingDetail && (
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>Email</Table.HeaderCell>
              <Table.HeaderCell width={6}>
                Actions
                <GrantAccessModal
                  trigger={
                    <Button primary size="mini" className="btn-add">
                      {' '}
                      + Access
                    </Button>
                  }
                  clientId={application.id}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {access.map((user, index) => (
              <Table.Row key={index}>
                <Table.Cell width={6}>{user.email}</Table.Cell>
                <Table.Cell width={6}>
                  <Button
                    primary
                    size="mini"
                    onClick={() => onRevokeAccess(user.id)}
                  >
                    Revoke
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {displayTeamsList && <Teams application={application} />}
    </Segment>
  )
}

export default React.memo(ApplicationItem)
