import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { IncidentsState } from './reducer'
import { Incident } from './types'

export const getState = (state: RootState): IncidentsState =>
  state.incident as IncidentsState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType = (state: LoadingState, type: string): boolean =>
  state.some((action: AnyAction) => action.type === type)

export const getIncidentsByConnectorId = (
  state: RootState,
  connectorId: number,
): Incident[] =>
  (getData(state) || []).filter((incident) =>
    incident.components.find(
      (component) => component.connectorId === connectorId,
    ),
  )
