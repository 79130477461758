import { AxiosResponse } from 'axios'

import { Item, UpdateItemRequest } from '../../modules/item/types'
import { BaseService } from './BaseService'
import { replaceUrlParams } from './utils'

class ItemsService extends BaseService {
  async getItem(id: string): Promise<AxiosResponse<{ results: Item[] }>> {
    const url = replaceUrlParams(this.urls.GET_ITEM, {
      id,
    })
    return await this.getServiceInstance().get(url)
  }

  async updateItem(
    id: string,
    updateValues: UpdateItemRequest,
  ): Promise<AxiosResponse<Item>> {
    const url = replaceUrlParams(this.urls.PATCH_ITEM, {
      id,
    })
    return await this.getServiceInstance().patch(url, updateValues)
  }
}

export const itemsService = new ItemsService()
