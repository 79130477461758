import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, Radio } from '@pluggyai/ui'
import { Option } from '@pluggyai/ui/dist/components/Dropdown/Dropdown.types'
import {
  addDays,
  addMonths,
  clamp,
  format,
  formatDistanceToNow,
} from 'date-fns'
import { Form, Input as SemanticInput } from 'semantic-ui-react'

import {
  ApplicationShareLinkFields,
  EXPIRES_AT_OPTIONS,
  ExpiresAtOption,
} from '../../../modules/application/types'
import { buildCustomDemoUrlParts } from '../../../modules/application/utils'
import type { Props } from './ApplicationShareLinkCreateForm.types'
import { EmailsInput } from './EmailsInput'
import {
  buildShareLinkTypeOption,
  buildShareLinkTypeOptions,
  ShareLinkTypeOption,
} from './utils'

import './ApplicationShareLinkCreateForm.css'

const expiresAtOptions: Option[] = EXPIRES_AT_OPTIONS.map(
  (value: ExpiresAtOption): Option => ({
    id: value,
    // TODO improve pluggyai/ui "select" to accept option with only 'id' field (if no 'name' set, it should work)
    name: value,
  }),
)

const [demoUrlFirstPart, demoUrlLastPart] = buildCustomDemoUrlParts()

const ApplicationShareLinkCreateForm = ({
  className,
  errors,
  fields,
  isLoading,
  onFieldChange,
  onSubmit,
  values,
  initialValue,
}: Props) => {
  const { t } = useTranslation()

  const isFieldIncluded = useCallback(
    (field: keyof ApplicationShareLinkFields) => {
      const valueOfField = values[field] as
        | ApplicationShareLinkFields[keyof ApplicationShareLinkFields]
        | null
      if (valueOfField === null) {
        // was set null -> hide it
        return false
      }
      if (!fields) {
        return true
      }

      return fields.includes(field)
    },
    [fields, values],
  )

  const [shareLinkTypeOptions, defaultShareLinkTypeOption] = useMemo(
    buildShareLinkTypeOptions,
    [],
  )

  const [shareLinkType, setShareLinkType] = useState<Option>()

  const handleShareLinkTypeChange = useCallback(
    ({ value }: { value: Option | Option[] }): void => {
      const valueAsOption = value as Option
      const { id } = valueAsOption as { id: ShareLinkTypeOption }
      if (id === 'EVERYONE') {
        // hide both options
        onFieldChange('emails', null)
        onFieldChange('emailDomain', null)
      }
      if (id === 'EMAILS_LIST') {
        // hide 'emailDomain', keep 'emails'
        onFieldChange('emailDomain', null)
        onFieldChange('emails', [''])
      }
      if (id === 'EMAIL_DOMAIN') {
        // hide 'emails', keep 'emailDomain'
        onFieldChange('emails', null)
        onFieldChange('emailDomain', '')
      }
      setShareLinkType(valueAsOption)
    },
    [onFieldChange],
  )

  useEffect(() => {
    if (shareLinkType) {
      return
    }
    // initialize default value
    handleShareLinkTypeChange({ value: defaultShareLinkTypeOption })
  }, [shareLinkType, handleShareLinkTypeChange, defaultShareLinkTypeOption])

  // if is Update of an existing value, map initialValue to the current form & update UI state
  const isUpdate = useRef(false)

  useEffect(() => {
    if (!initialValue) {
      return
    }
    isUpdate.current = true

    const {
      customUrlDomain,
      emailDomain: initialEmailDomain,
      emails: initialEmails,
      expiresAt,
    } = initialValue

    // map initialValue to form values & trigger form updates
    onFieldChange('customUrlDomain', customUrlDomain)

    onFieldChange(
      'expiresAt',
      expiresAt && format(new Date(expiresAt), 'yyyy-MM-dd'),
    )

    let initialShareLinkType: ShareLinkTypeOption
    if (initialEmailDomain) {
      initialShareLinkType = 'EMAIL_DOMAIN'
      onFieldChange('emailDomain', initialEmailDomain)
    } else if (initialEmails) {
      initialShareLinkType = 'EMAILS_LIST'
      onFieldChange('emails', initialEmails)
    } else {
      initialShareLinkType = 'EVERYONE'
    }
    const initialShareLinkTypeOption: Option =
      buildShareLinkTypeOption(initialShareLinkType)
    setShareLinkType(initialShareLinkTypeOption)
  }, [initialValue, onFieldChange])

  const today = new Date()
  const tomorrow = addDays(today, 1)
  const minExpiresAt = tomorrow
  const maxExpiresAt = addMonths(today, 3)

  const handleExpiresAtOptionClick = useCallback(
    // initialize expiresAt value to min possible value
    () => onFieldChange('expiresAt', format(minExpiresAt, 'yyyy-MM-dd')),
    [onFieldChange, minExpiresAt],
  )

  const handleExpiresAtChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event
      const expiresAtLimited = clamp(new Date(value), {
        start: minExpiresAt,
        end: maxExpiresAt,
      })
      const expiresAtValue = format(expiresAtLimited, 'yyyy-MM-dd')
      onFieldChange('expiresAt', expiresAtValue)
    },
    [onFieldChange, minExpiresAt, maxExpiresAt],
  )

  return (
    <Form
      className={`ApplicationShareLinkCreateForm ${className || ''}`}
      onSubmit={onSubmit}
    >
      {isFieldIncluded('customUrlDomain') && (
        <div className={'custom-url-container'}>
          <span className={'custom-url-container-part'}>
            {demoUrlFirstPart}
          </span>
          <Input
            label={t('applicationShareLink.form.field.customUrlDomain.label')}
            type="text"
            onChange={({ value }) => onFieldChange('customUrlDomain', value)}
            value={values.customUrlDomain}
            error={errors.customUrlDomain}
            disabled={isLoading}
          />
          <span className={'custom-url-container-part'}>{demoUrlLastPart}</span>
        </div>
      )}
      <div className={'field-container'}>
        <h4>{t('applicationShareLink.form.field.shareLinkType.header')}</h4>
        <Input
          label={t('applicationShareLink.form.field.shareLinkType.header')}
          type="select"
          options={shareLinkTypeOptions}
          onChange={handleShareLinkTypeChange}
          value={shareLinkType}
          disabled={isLoading}
        />
      </div>
      <div className={'field-container'}>
        <h4>{t('applicationShareLink.form.field.expiresAtOption.header')}</h4>
        {!isUpdate.current ? (
          /* select suggested options */
          <Input
            label={t('applicationShareLink.form.field.expiresAtOption.header')}
            type="select"
            options={expiresAtOptions}
            onChange={({
              value,
            }: {
              name?: string
              value: Option | Option[]
            }) => onFieldChange('expiresAtOption', (value as Option).id)}
            /*
              TODO: in pluggy/ui it should accept passing the 'option.id' (or alternatively, 'option.name' if specified)
               fields directly and it should work, instead of having to rebuild an Option object
             */
            value={{
              id: values.expiresAtOption,
              name: values.expiresAtOption,
            }}
            error={errors.expiresAtOption}
            disabled={isLoading}
          />
        ) : (
          /* date-picker with input text */
          <div className={'expires-at-field-content'}>
            <Radio
              label={'Never'}
              checked={values.expiresAt === null}
              onClick={() => onFieldChange('expiresAt', null)}
              type={'radio'}
            />
            <Radio
              label={'Specify'}
              checked={values.expiresAt !== null}
              onClick={handleExpiresAtOptionClick}
              type={'radio'}
            />
            <div className={'expires-at-date-picker'}>
              <SemanticInput
                className={'date-picker'}
                type="date"
                onChange={handleExpiresAtChange}
                value={
                  typeof values.expiresAt === 'string'
                    ? format(new Date(values.expiresAt), 'yyyy-MM-dd')
                    : ''
                }
                min={format(minExpiresAt, 'yyyy-MM-dd')}
                max={format(addMonths(today, 3), 'yyyy-MM-dd')}
                disabled={isLoading || values.expiresAt === null}
                // TODO: handle displaying errors.expiresAt actual text like the rest of pluggy Inpugit
                error={Boolean(errors.expiresAt)}
              />
              <span className={'expires-at-text'}>
                {values.expiresAt
                  ? formatDistanceToNow(new Date(values.expiresAt), {
                      addSuffix: true,
                    })
                  : ''}
              </span>
            </div>
          </div>
        )}
      </div>
      {isFieldIncluded('emailDomain') && (
        <div className={'field-container'}>
          <h4>{t('applicationShareLink.form.field.emailDomain.header')}</h4>
          <div className={'custom-url-container'}>
            <span className={'custom-url-container-part'}>anyone @</span>
            <Input
              label={t('applicationShareLink.form.field.emailDomain.label')}
              type="text"
              onChange={({ value }) => onFieldChange('emailDomain', value)}
              value={values.emailDomain || ''}
              error={errors.emailDomain}
              disabled={isLoading}
            />
          </div>
        </div>
      )}
      {isFieldIncluded('emails') && (
        <EmailsInput
          value={values.emails || ['']}
          errors={errors.emails}
          isSubmitting={isLoading}
          onFieldChange={onFieldChange}
        />
      )}
      {/* Hidden input to allow submitting with "enter" key */}
      <input type="submit" style={{ display: 'none' }} />
    </Form>
  )
}
export default React.memo(ApplicationShareLinkCreateForm)
