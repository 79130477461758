import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { AUTH0_LOGIN_REQUEST, PLUGGY_API_LOGIN_REQUEST } from './actions'
import { AuthState } from './reducer'
import { AuthResult } from './types'

export const getState = (state: RootState): AuthState => state.auth
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type,
) => state.some((action: AnyAction) => action.type === type)

export const isLoggingIn = (state: RootState) =>
  isLoadingType(getLoading(state), AUTH0_LOGIN_REQUEST)

export const getAuth = (state: RootState): AuthResult | null =>
  getData(state).auth

export const isCreatingPluggyApiKey = (state: RootState) =>
  isLoadingType(getLoading(state), PLUGGY_API_LOGIN_REQUEST)

export const getAuth0AccessToken = (state: RootState): string | null =>
  getData(state).auth?.accessToken || null

const IMPERSONATE_READ_PERMISSION = 'read:users:data:all'

export const isImpersonateAllowed = (state: RootState): boolean =>
  getAuth(state)?.scope?.includes(IMPERSONATE_READ_PERMISSION) || false

const UPGRADE_APPLICATION_PERMISSION = 'write:users:data:all'

export const isUpgradeApplicationAllowed = (state: RootState): boolean =>
  getAuth(state)?.scope?.includes(UPGRADE_APPLICATION_PERMISSION) || false
