import { AxiosResponse } from 'axios'
import { endOfDay, format } from 'date-fns'
import i18next from 'i18next'
import { PageResponse } from 'pluggy-js'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { TeamsService } from '../../lib/api/auth-api/TeamsService'
import { fetchAuthApiApplicationsRequest } from '../application/actions'
import { getAuth0AccessToken } from '../auth/selectors'
import { addNotificationAction } from '../notification/actions'
import {
  CREATE_TEAM_MEMBER_REQUEST,
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  createTeamFailure,
  createTeamMemberFailure,
  CreateTeamMemberRequestAction,
  createTeamMemberSuccess,
  CreateTeamRequestAction,
  createTeamSuccess,
  CreateTeamSuccessAction,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  deleteTeamFailure,
  deleteTeamMemberFailure,
  DeleteTeamMemberRequestAction,
  deleteTeamMemberSuccess,
  DeleteTeamRequestAction,
  deleteTeamSuccess,
  DeleteTeamSuccessAction,
  FETCH_TEAMS_REQUEST,
  fetchTeamsFailure,
  FetchTeamsRequestAction,
  fetchTeamsSuccess,
  UPDATE_TEAM_MEMBER_ROLE_REQUEST,
  UPDATE_TEAM_REQUEST,
  updateTeamFailure,
  updateTeamMemberRoleFailure,
  UpdateTeamMemberRoleRequestAction,
  updateTeamMemberRoleSuccess,
  UpdateTeamRequestAction,
  updateTeamSuccess,
} from './actions'
import {
  CreateTeamMemberRequest,
  CreateTeamRequest,
  isTeamMember,
  Team,
  UpdateTeamRequest,
} from './types'
import { mapTeamRoleValueToI18nText } from './utils'

function* handleFetchTeamsRequest(action: FetchTeamsRequestAction) {
  try {
    const { search } = action.payload
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamsService(accessToken)
    const { data: teams }: AxiosResponse<PageResponse<Team>> = yield call(() =>
      teamService.getTeams(search),
    )

    yield put(fetchTeamsSuccess(teams))
  } catch (error) {
    const errorMessage = i18next.t('teams.error.fetch')
    yield put(fetchTeamsFailure(errorMessage))
  }
}

function* handleCreateTeamRequest(action: CreateTeamRequestAction) {
  const { createTeamFields } = action.payload

  // map team form fields to create request body
  const membersFilled = createTeamFields.members.filter(
    // exclude empty emails
    (invite) => invite.email.length > 0,
  )

  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamsService(accessToken)

  const teamFields: CreateTeamRequest = {
    name: createTeamFields.name,
    imageUrl: createTeamFields.imageUrl || undefined,
    members: membersFilled,
    cnpj: createTeamFields.cnpj || undefined,
  }

  try {
    // submit create request
    const { data: team }: AxiosResponse<Team> = yield call(() =>
      teamService.createTeam(teamFields),
    )
    yield put(createTeamSuccess(team))
  } catch (error) {
    const errorMessage = i18next.t('team.error.create')
    yield put(createTeamFailure(errorMessage))
  }
}

function* handleUpdateTeamRequest(action: UpdateTeamRequestAction) {
  const {
    team: { id, subscription, integrations },
    updateTeamFields,
    updateReason,
  } = action.payload

  if (
    !updateTeamFields.integrations?.iniciadorPayments?.clientId ||
    !updateTeamFields.integrations.iniciadorPayments.clientSecret
  ) {
    delete updateTeamFields.integrations?.iniciadorPayments
  }

  // map team form fields to create request body
  const teamFields: UpdateTeamRequest = {}

  if (updateTeamFields.iniciadorCredentials) {
    teamFields.iniciadorCredentials = updateTeamFields.iniciadorCredentials
  }

  if (updateTeamFields.integrations) {
    teamFields.integrations = {
      ...integrations,
      ...updateTeamFields.integrations,
    }
  }

  if (updateTeamFields.subscription) {
    teamFields.subscription = {
      ...subscription,
      ...updateTeamFields.subscription,
    }
  }

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamsService(accessToken)
    // submit update request
    const { data: team }: AxiosResponse<Team> = yield call(() =>
      teamService.updateTeam(id, teamFields),
    )
    yield put(updateTeamSuccess(team, updateReason))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.update.title'),
        message: i18next.t('team.success.update.message'),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.update')
    yield put(updateTeamFailure(errorMessage))
  }
}

function* handleDeleteTeamRequest(action: DeleteTeamRequestAction) {
  const { team } = action.payload
  const { id } = team

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamsService(accessToken)
    // submit delete request
    yield call(() => teamService.deleteTeam(id))
    yield put(deleteTeamSuccess(team))
  } catch (error) {
    const errorMessage = i18next.t('team.error.delete')
    yield put(deleteTeamFailure(errorMessage))
  }
}

function* handleCreateTeamMemberRequest(action: CreateTeamMemberRequestAction) {
  const { id, createTeamMembersFields } = action.payload
  const count = createTeamMembersFields.length

  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    const teamService = new TeamsService(accessToken)
    // submit create member requests
    let updatedTeam: Team | undefined = undefined
    for (const createTeamMemberFields of createTeamMembersFields) {
      // map member form fields to create request body
      const teamFields: CreateTeamMemberRequest = {
        email: createTeamMemberFields.email,
        role: createTeamMemberFields.role,
      }
      const { data: team }: AxiosResponse<Team> = yield call(() =>
        teamService.createTeamMember(id, teamFields),
      )
      updatedTeam = team
    }
    if (!updatedTeam) {
      throw new Error('Unexpectedly updatedTeam is not defined')
    }
    yield put(createTeamMemberSuccess(updatedTeam))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.add-member.title'),
        message: i18next.t('team.success.add-member.message', { count }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.add-member')
    yield put(createTeamMemberFailure(errorMessage))
  }
}

function* handleUpdateTeamMemberRoleRequest(
  action: UpdateTeamMemberRoleRequestAction,
) {
  const { team, teamMember, role } = action.payload
  const { email } = teamMember
  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamsService(accessToken)

  if (isTeamMember(teamMember)) {
    // submit update team member request
    try {
      const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
        teamService.updateTeamMember(team.id, teamMember.id, { role }),
      )
      yield put(
        addNotificationAction({
          title: i18next.t('team.success.update-member-role.title'),
          message: i18next.t('team.success.update-member-role.message', {
            value: mapTeamRoleValueToI18nText(role),
            email,
          }),
          level: 'succeed',
        }),
      )
      yield put(updateTeamMemberRoleSuccess(updatedTeam))
    } catch (error) {
      const errorMessage = i18next.t('team.error.update-member-role', {
        value: role,
        email,
      })
      yield put(updateTeamMemberRoleFailure(errorMessage))
    }
    return
  }

  // submit update team invitation request
  try {
    const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
      teamService.updateTeamInvitation(team.id, teamMember.id, { role }),
    )
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.update-invitation-role.title'),
        message: i18next.t('team.success.update-invitation-role.message', {
          value: mapTeamRoleValueToI18nText(role),
          email,
        }),
        level: 'succeed',
      }),
    )
    yield put(updateTeamMemberRoleSuccess(updatedTeam))
  } catch (error) {
    const errorMessage = i18next.t('team.error.update-invitation-role', {
      value: role,
      email,
    })
    yield put(updateTeamMemberRoleFailure(errorMessage))
  }
}

function* handleDeleteTeamMemberRequest(action: DeleteTeamMemberRequestAction) {
  const { team, teamMember } = action.payload
  const { email } = teamMember

  const accessToken: string = yield select(getAuth0AccessToken)
  const teamService = new TeamsService(accessToken)

  if (isTeamMember(teamMember)) {
    // submit delete team member request
    try {
      const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
        teamService.deleteTeamMember(team.id, teamMember.id),
      )
      yield put(deleteTeamMemberSuccess(updatedTeam))
      yield put(
        addNotificationAction({
          title: i18next.t('team.success.delete-member.title'),
          message: i18next.t('team.success.delete-member.message', {
            email,
          }),
          level: 'succeed',
        }),
      )
    } catch (error) {
      const errorMessage = i18next.t('team.error.delete-member', {
        email,
      })
      yield put(deleteTeamMemberFailure(errorMessage))
    }
    return
  }

  // submit delete team invitation request
  try {
    const { data: updatedTeam }: AxiosResponse<Team> = yield call(() =>
      teamService.deleteTeamInvitation(team.id, teamMember.id),
    )

    yield put(deleteTeamMemberSuccess(updatedTeam))
    yield put(
      addNotificationAction({
        title: i18next.t('team.success.delete-invitation.title'),
        message: i18next.t('team.success.delete-invitation.message', {
          email,
        }),
        level: 'succeed',
      }),
    )
  } catch (error) {
    const errorMessage = i18next.t('team.error.delete-invitation', {
      email,
    })
    yield put(deleteTeamMemberFailure(errorMessage))
  }
}

function* handleCreateTeamSuccess(action: CreateTeamSuccessAction) {
  const { team } = action.payload
  const {
    id,
    name,
    subscription: { plan, freeTrialExpiresAt },
  } = team

  const subscriptionDescription =
    plan === 'TRIAL'
      ? `'TRIAL' (until: ${
          freeTrialExpiresAt
            ? format(endOfDay(new Date(freeTrialExpiresAt)), 'yyyy-MM-dd')
            : '?????'
        })`
      : `'${plan}'`

  yield put(
    addNotificationAction({
      title: i18next.t('team.success.create.title'),
      message: i18next.t('team.success.create.message', {
        name,
        id,
        subscriptionDescription,
      }),
      level: 'succeed',
    }),
  )
  // request Team auth-api Applications related data
  // TODO support fetching by teamId? (so we don't request all applications?)
  yield put(fetchAuthApiApplicationsRequest())
}

function* handleDeleteTeamSuccess(action: DeleteTeamSuccessAction) {
  const { team } = action.payload
  const { name } = team
  yield put(
    addNotificationAction({
      title: i18next.t('team.success.delete.title'),
      message: i18next.t('team.success.delete.message', { name }),
      level: 'succeed',
    }),
  )
}

export function* teamSaga() {
  yield all([
    takeEvery(FETCH_TEAMS_REQUEST, handleFetchTeamsRequest),
    takeEvery(CREATE_TEAM_REQUEST, handleCreateTeamRequest),
    takeEvery(UPDATE_TEAM_REQUEST, handleUpdateTeamRequest),
    takeEvery(DELETE_TEAM_REQUEST, handleDeleteTeamRequest),
    takeEvery(CREATE_TEAM_SUCCESS, handleCreateTeamSuccess),
    takeEvery(DELETE_TEAM_SUCCESS, handleDeleteTeamSuccess),
    takeEvery(CREATE_TEAM_MEMBER_REQUEST, handleCreateTeamMemberRequest),
    takeEvery(DELETE_TEAM_MEMBER_REQUEST, handleDeleteTeamMemberRequest),
    takeEvery(
      UPDATE_TEAM_MEMBER_ROLE_REQUEST,
      handleUpdateTeamMemberRoleRequest,
    ),
  ])
}
