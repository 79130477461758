import { connect } from 'react-redux'

import { push } from 'connected-react-router'

import { fetchUsersRequest } from '../../modules/user/actions'
import OverviewPage from './OverviewPage'
import { MapDispatch, MapDispatchProps } from './OverviewPage.types'

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchUsers: () => dispatch(fetchUsersRequest()),
  onNavigate: (path: string) => dispatch(push(path)),
})

export default connect(null, mapDispatch)(OverviewPage)
