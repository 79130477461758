import React, { ComponentType } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { useAuth0 } from '@auth0/auth0-react'
import { Dispatch } from 'redux'
import { Button } from 'semantic-ui-react'

import {
  auth0LogoutRequest,
  Auth0LogoutRequestAction,
  pluggyApiLoginRequest,
  PluggyApiLoginRequestAction,
} from '../../modules/auth/actions'
import {
  getData,
  getError,
  isCreatingPluggyApiKey,
  isLoggingIn,
} from '../../modules/auth/selectors'
import { AuthResult } from '../../modules/auth/types'
import { RootState } from '../../modules/reducer'

const isLocaldev = process.env.NODE_ENV === 'development'
const isDev = window.location.href.includes('.dev')

type Props = {
  isLoggingIn: boolean
  isCreatingPluggyApiKey: boolean
  pluggyApiKey: string | null
  authError: string | null
  onAuth0Logout: typeof auth0LogoutRequest
  onPluggyApiLoginRequest: typeof pluggyApiLoginRequest
}

type MapStateProps = Pick<
  Props,
  'isLoggingIn' | 'isCreatingPluggyApiKey' | 'pluggyApiKey' | 'authError'
>

type MapDispatchProps = Pick<Props, 'onAuth0Logout' | 'onPluggyApiLoginRequest'>

type MapDispatch = Dispatch<
  Auth0LogoutRequestAction | PluggyApiLoginRequestAction
>

const mapState = (state: RootState): MapStateProps => {
  return {
    isLoggingIn: isLoggingIn(state),
    isCreatingPluggyApiKey: isCreatingPluggyApiKey(state),
    pluggyApiKey: getData(state).auth?.apiKey || null,
    authError: getError(state),
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onAuth0Logout: () => dispatch(auth0LogoutRequest()),
  onPluggyApiLoginRequest: (auth: AuthResult) =>
    dispatch(pluggyApiLoginRequest(auth)),
})

const connector = connect(mapState, mapDispatch)

// Util to infer the props of the component with connect() call
type PropsFromRedux = ConnectedProps<typeof connector>

/**
 * Helper HOC to wrap components that require combined Auth0 + own API authentication.
 *
 * Use this in replacement of '@auth0/auth0-react' withAuthenticationRequired().
 * TODO: implement customizable options (ie. allow using custom loaders/error components, etc).
 *
 * @param Component
 */
const withCustomAuthenticationRequired = (
  Component: ComponentType<PropsFromRedux>,
) => {
  function WrappedComponent(props: PropsFromRedux) {
    const {
      isLoggingIn: isLoggingInProp,
      isCreatingPluggyApiKey: isCreatingPluggyApiKeyProp,
      pluggyApiKey: pluggyApiKeyProp,
      authError,
      onAuth0Logout,
      onPluggyApiLoginRequest,
    } = props

    const {
      isLoading: isAuth0Loading,
      isAuthenticated,
      error: auth0Error,
      loginWithRedirect,
      user,
      getAccessTokenSilently,
    } = useAuth0()

    if (auth0Error) {
      if (isLocaldev || isDev) {
        console.error('auth0 error:', auth0Error)
      }
      return <div>Oops... {auth0Error.message}</div>
    }

    if (isAuth0Loading) {
      return <div>Loading...</div>
    }

    // TODO: implement some way to invalidate/cleanup obsolete/revoked 'apiKey' values
    // TODO2: should also check the AuthResult 'expiresAt' date for invalidation
    if (!isAuthenticated) {
      // not authenticated with auth0
      loginWithRedirect().catch((error_) =>
        console.error('loginWithRedirect() call failed:', error_),
      )

      return <div>Redirecting to login page...</div>
    }

    if (authError) {
      if (isLocaldev || isDev) {
        console.error('Pluggy API auth error:', authError)
      }

      return (
        <div>
          <p>Pluggy API authentication failed: {authError}</p>
          <Button onClick={onAuth0Logout}>Logout</Button>
        </div>
      )
    }

    if (!isLoggingInProp && !isCreatingPluggyApiKeyProp && !pluggyApiKeyProp) {
      // is authenticated with Auth0, but there is no pluggyApiKey loading nor it's prop in place
      // trigger a request for a new pluggy apiKey
      getAccessTokenSilently().then((accessToken) => {
        onPluggyApiLoginRequest({ accessToken, profile: user } as AuthResult)
      })

      return <div>Loading...</div>
    }

    // is authenticated with auth0!
    // check pluggy-api apiKey
    if (isLoggingInProp || isCreatingPluggyApiKeyProp || !pluggyApiKeyProp) {
      return <div>Loading...</div>
    }

    // is authorized!
    return <Component {...props} />
  }

  // return redux-connected HOC wrapped component
  return connector(WrappedComponent)
}

export { withCustomAuthenticationRequired }
