import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import { connectorsService } from '../../lib/api/ConnectorsService'
import { addNotificationAction } from '../notification/actions'
import {
  FETCH_CONNECTORS_REQUEST,
  fetchConnectorsFailure,
  FetchConnectorsRequestAction,
  fetchConnectorsSuccess,
  UPDATE_CONNECTOR_REQUEST,
  updateConnectorFailure,
  UpdateConnectorRequestAction,
  updateConnectorSuccess,
  UPDATE_CONNECTORS_REQUEST,
  UPDATE_CONNECTORS_SUCCESS,
  UPDATE_CONNECTORS_FAILURE,
  UpdateConnectorsRequestAction,
  updateConnectorsSuccess,
  updateConnectorsFailure,
  fetchConnectorsRequest,
} from './actions'
import { Connector, UpdateConnectorRequest } from './types'

function* handleFetchConnectorsRequest(_action: FetchConnectorsRequestAction) {
  try {
    const {
      data: { results: connectors },
    }: AxiosResponse<{ results: Connector[] }> = yield call(() =>
      connectorsService.getConnectors(),
    )

    yield put(fetchConnectorsSuccess(connectors))
  } catch (error) {
    const errorMessage = i18next.t('connector.error.fetch', {
      errorMessage: error.message,
    })
    yield put(fetchConnectorsFailure(errorMessage))
  }
}

function* handleUpdateConnectorRequest(action: UpdateConnectorRequestAction) {
  const {
    connector: { id },
    updateConnectorFields,
  } = action.payload

  // map connector form fields to create request body
  const connectorFields: UpdateConnectorRequest = {
    status: updateConnectorFields.status,
  }

  try {
    // submit update request
    const { data: connector }: AxiosResponse<Connector> = yield call(() =>
      connectorsService.updateConnector(id, connectorFields),
    )
    const { name } = connector
    yield put(updateConnectorSuccess(connector))
    yield put(
      addNotificationAction({
        title: i18next.t('connector.success.update.title'),
        message: i18next.t('connector.success.update.message', { id, name }),
        duration: 4000,
        level: 'succeed',
      }),
    )
  } catch (error) {
    const message = i18next.t('connector.error.update.message', {
      id,
      errorMessage: error.message,
    })
    yield put(
      addNotificationAction({
        title: i18next.t('connector.error.update.title'),
        message,
        duration: 4000,
        level: 'error',
      }),
    )
    yield put(updateConnectorFailure(message))
  }
}

function* handleUpdateConnectorsRequest(
  _action: UpdateConnectorsRequestAction,
) {
  try {
    const {
      data: { count },
    } = yield call(() => connectorsService.updateConnectors())
    yield put(updateConnectorsSuccess(count))
  } catch (error) {
    let errorMessage = 'Failed to update connectors'
    if (error.response?.body) {
      try {
        const { error: message } = JSON.parse(error.response.body) as {
          error?: string
        }
        errorMessage = `${errorMessage}. Message: '${message}', status: ${error.response.status}`
      } catch {
        errorMessage = `${errorMessage}. Response Body: ${error.response.body}`
      }
      error.message = errorMessage
    } else {
      error.message = `${errorMessage}: ${error.message}`
    }

    const message = i18next.t('connector.error.update.message', {
      errorMessage: error.message,
    })

    yield put(
      addNotificationAction({
        title: i18next.t('connector.error.update.title'),
        message,
        duration: 4000,
        level: 'error',
      }),
    )

    yield put(updateConnectorsFailure(error.message))
  }
}

function* handleUpdateConnectorsSuccess() {
  yield put(
    addNotificationAction({
      title: i18next.t('connectors.success.update.title'),
      message: i18next.t('connectors.success.update.message'),
      duration: 4000,
      level: 'succeed',
    }),
  )
  // fetch updated connectors data
  yield put(fetchConnectorsRequest())
}

function* handleUpdateConnectorsFailure() {
  yield put(
    addNotificationAction({
      title: i18next.t('connectors.error.update.title'),
      message: i18next.t('connectors.error.update.message'),
      duration: 4000,
      level: 'error',
    }),
  )
}

export function* connectorSaga() {
  yield all([
    takeEvery(FETCH_CONNECTORS_REQUEST, handleFetchConnectorsRequest),
    takeEvery(UPDATE_CONNECTOR_REQUEST, handleUpdateConnectorRequest),
    takeEvery(UPDATE_CONNECTORS_REQUEST, handleUpdateConnectorsRequest),
    takeEvery(UPDATE_CONNECTORS_SUCCESS, handleUpdateConnectorsSuccess),
    takeEvery(UPDATE_CONNECTORS_FAILURE, handleUpdateConnectorsFailure),
  ])
}
