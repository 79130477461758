import { connect } from 'react-redux'

import { isImpersonateAllowed } from '../../../modules/auth/selectors'
import { RootState } from '../../../modules/reducer'
import TeamListItem from './TeamListItem'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './TeamListItem.types'

const mapState = (state: RootState): MapStateProps => ({
  isImpersonateAllowed: isImpersonateAllowed(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet...
})

export default connect(mapState, mapDispatch)(TeamListItem)
