import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Button } from '@pluggyai/ui'
import { Modal } from 'semantic-ui-react'

import { isStringsArray } from '../../../lib/utils/validation'
import {
  IncidentFields,
  IncidentFieldsErrors,
} from '../../../modules/incident/types'
import { IncidentCreateForm, validateIncidentField } from './IncidentCreateForm'
import { Props } from './IncidentCreateModal.types'

import './IncidentCreateModal.css'

const initialIncidentFields = (): IncidentFields => ({
  name: '',
  status: undefined,
  body: '',
  impact: undefined,
  componentStatus: undefined,
  deliverNotifications: false,
})

const IncidentCreateModal = ({
  currentIncident,
  connector,
  isLoading,
  error,
  onClose,
  onCreateIncident,
  onUpdateIncident,
}: Props) => {
  const [submit, setSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<IncidentFieldsErrors>({})
  const [incidentValues, setIncidentValues] = useState(initialIncidentFields())

  const close = useCallback(() => {
    setFormErrors({})
    setIncidentValues(initialIncidentFields())
    onClose()
  }, [onClose])

  const handleClose = useCallback(() => {
    close()
  }, [close])

  const handleValuesChange = useCallback(
    (fieldName: keyof IncidentFields, value: unknown) => {
      // clear field error, if any
      setFormErrors((previousFormErrors: IncidentFieldsErrors) => {
        const newFormErrors = { ...previousFormErrors }
        delete newFormErrors[fieldName]
        return newFormErrors
      })

      // stop submitting, if it was happening
      setSubmit(false)

      // set field value
      setIncidentValues((values) => ({
        ...values,
        [fieldName]: value,
      }))
    },
    [],
  )

  const {
    id: connectorId,
    name: connectorName,
    type: connectorType,
  } = connector

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      if (isLoading) {
        // is already loading/submitting, wait
        return
      }

      event.preventDefault()

      // validate fields values
      // TODO: each field should "register" and define it's own validator? to support custom types per field
      const currentFormErrors: IncidentFieldsErrors = {}
      let errorsCount = 0
      for (const [field, value] of Object.entries(incidentValues)) {
        let fieldError: string | string[] | undefined
        if (isStringsArray(value)) {
          const valueJoined = value.join(',')
          const errorsJoined = validateIncidentField(
            field as keyof IncidentFields,
            valueJoined,
          )
          const fieldErrors = errorsJoined?.split(',')
          if (fieldErrors?.some((fieldError_) => fieldError_.length > 0)) {
            errorsCount++
          }

          fieldError = fieldErrors
        } else {
          fieldError = validateIncidentField(
            field as keyof IncidentFields,
            value,
          )

          if (fieldError) {
            errorsCount++
          }
        }

        if (fieldError) {
          // using 'as any' here as a hack to support both 'string' or 'string[]'
          // fieldError values.
          // TODO rework/improve this, maybe using separated validations per field
          currentFormErrors[field as keyof IncidentFields] = fieldError as any
        }
      }

      setFormErrors(currentFormErrors)
      if (errorsCount > 0) {
        // got errors, can't proceed
        return
      }

      setSubmit(true)

      if (currentIncident) {
        // is updating an existing value
        onUpdateIncident(currentIncident, incidentValues, connectorId)
        return
      }

      onCreateIncident(incidentValues, connectorId)
    },
    [
      currentIncident,
      incidentValues,
      isLoading,
      onCreateIncident,
      onUpdateIncident,
      connectorId,
    ],
  )

  useEffect(() => {
    // check if we are done, and close modal if no errors
    const isDone = submit && error === null && !isLoading
    if (isDone) {
      handleClose()
    }
  }, [error, isLoading, submit, handleClose])

  return (
    <Modal
      className={'IncidentCreateModal'}
      onClose={handleClose}
      open={true}
      closeIcon
      size={'small'}
    >
      <Modal.Header>
        {currentIncident ? 'Update' : 'Open'} Incident
        <p>
          {currentIncident ? 'Update' : 'Open a new'} Incident for Connector{' '}
          <strong>
            {connectorName} ({connectorId} - {connectorType})
          </strong>
        </p>
        {error !== null && (
          <Alert size={'medium'} type={'error'} message={error} />
        )}
      </Modal.Header>
      <Modal.Content>
        <IncidentCreateForm
          initialValue={currentIncident}
          connectorId={connectorId}
          onSubmit={handleSubmit}
          onFieldChange={handleValuesChange}
          values={incidentValues}
          errors={formErrors}
          isLoading={isLoading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSubmit} loading={isLoading}>
          {currentIncident ? 'Update' : 'Create'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default React.memo(IncidentCreateModal)
