import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  GRANT_ACCESS_FAILURE,
  GRANT_ACCESS_REQUEST,
  GRANT_ACCESS_SUCCESS,
  FETCH_ACCESS_FAILURE,
  FETCH_ACCESS_REQUEST,
  FETCH_ACCESS_SUCCESS,
  REVOKE_ACCESS_FAILURE,
  REVOKE_ACCESS_REQUEST,
  REVOKE_ACCESS_SUCCESS,
  FetchAccessFailureAction,
  FetchAccessSuccessAction,
  FetchAccessRequestAction,
  GrantAccessFailureAction,
  GrantAccessRequestAction,
  GrantAccessSuccessAction,
  RevokeAccessFailureAction,
  RevokeAccessRequestAction,
  RevokeAccessSuccessAction,
} from './actions'
import { Access } from './types'

export type AccessState = {
  data: Record<string, Access>
  loading: LoadingState
  error: string | null
}

export const INITIAL_STATE: AccessState = {
  data: {},
  loading: [],
  error: null,
}

export type AccessReducerAction =
  | FetchAccessFailureAction
  | FetchAccessSuccessAction
  | FetchAccessRequestAction
  | GrantAccessFailureAction
  | GrantAccessRequestAction
  | GrantAccessSuccessAction
  | RevokeAccessFailureAction
  | RevokeAccessRequestAction
  | RevokeAccessSuccessAction

export function accessReducer(
  state: AccessState = INITIAL_STATE,
  action: AccessReducerAction,
): AccessState {
  switch (action.type) {
    case GRANT_ACCESS_REQUEST:
    case REVOKE_ACCESS_REQUEST:
    case FETCH_ACCESS_REQUEST:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    case REVOKE_ACCESS_SUCCESS:
      const { id } = action.payload
      const data = { ...state.data }
      delete data[id]
      return {
        ...state,
        data,
        loading: loadingReducer(state.loading, action),
      }
    case FETCH_ACCESS_SUCCESS:
      const { access: accesses } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          ...accesses.reduce((accessObject, access) => {
            accessObject[access.id] = access
            return accessObject
          }, {} as Record<string, Access>),
        },
      }
    case GRANT_ACCESS_SUCCESS:
      const { access } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [access.id]: access,
        },
      }
    case REVOKE_ACCESS_FAILURE:
    case FETCH_ACCESS_FAILURE:
    case GRANT_ACCESS_FAILURE:
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    default:
      return state
  }
}
