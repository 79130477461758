import axios, { AxiosInstance } from 'axios'

import { urls, UrlsMap } from './constants/urls'

export class BaseService {
  protected urls: UrlsMap
  private service: AxiosInstance | undefined

  constructor(protected accessToken: string) {
    this.urls = urls
  }

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      this.service = axios.create({
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
    }
    return this.service
  }
}
