const currentLocationUrl = window.location.href
const isStageDev =
  currentLocationUrl.includes('.dev') ||
  currentLocationUrl.includes('.vercel.app') ||
  currentLocationUrl.includes('localhost')

export function buildCustomDemoUrlParts(): [string, string] {
  const lastUrlPart = `.demo.pluggy.${isStageDev ? 'dev' : 'ai'}`
  return ['https://', lastUrlPart]
}

export function buildCustomDemoUrl(customUrlDomain: string): string {
  const [firstPart, lastPart] = buildCustomDemoUrlParts()
  return [firstPart, customUrlDomain, lastPart].join('')
}

/**
 * Helper to create link to Metabase Client Explorer from an application clientId
 *
 * @param clientId
 */
export function buildMetabaseClientExplorerLink(clientId: string): string {
  return `https://metabase.pluggy.work/dashboard/53-client-explorer?date_filter=past30days&client_id=${clientId}`
}
