import { action } from 'typesafe-actions'

import { Access } from './types'

/// Fetch Access request

export const FETCH_ACCESS_REQUEST = '[Request] Fetch Access'
export const FETCH_ACCESS_SUCCESS = '[Success] Fetch Access'
export const FETCH_ACCESS_FAILURE = '[Failure] Fetch Access'

export const fetchAccessRequest = (clientId: string) =>
  action(FETCH_ACCESS_REQUEST, { clientId })
export const fetchAccessSuccess = (access: Access[]) =>
  action(FETCH_ACCESS_SUCCESS, { access })
export const fetchAccessFailure = (error: string) =>
  action(FETCH_ACCESS_FAILURE, { error })

export type FetchAccessRequestAction = ReturnType<typeof fetchAccessRequest>
export type FetchAccessSuccessAction = ReturnType<typeof fetchAccessSuccess>
export type FetchAccessFailureAction = ReturnType<typeof fetchAccessFailure>

/// Revoke access to demo request

export const REVOKE_ACCESS_REQUEST = '[Request] Revoke Access'
export const REVOKE_ACCESS_SUCCESS = '[Success] Revoke Access'
export const REVOKE_ACCESS_FAILURE = '[Failure] Revoke Access'

export const revokeAccessRequest = (id: number) =>
  action(REVOKE_ACCESS_REQUEST, { id })
export const revokeAccessSuccess = (id: number) =>
  action(REVOKE_ACCESS_SUCCESS, { id })
export const revokeAccessFailure = (error: string) =>
  action(REVOKE_ACCESS_FAILURE, { error })

export type RevokeAccessRequestAction = ReturnType<typeof revokeAccessRequest>
export type RevokeAccessSuccessAction = ReturnType<typeof revokeAccessSuccess>
export type RevokeAccessFailureAction = ReturnType<typeof revokeAccessFailure>

/// Create Application request

export const GRANT_ACCESS_REQUEST = '[Request] Grant Access'
export const GRANT_ACCESS_SUCCESS = '[Success] Grant Access'
export const GRANT_ACCESS_FAILURE = '[Failure] Grant Access'

export const grantAccessRequest = (email: string, clientId: string) =>
  action(GRANT_ACCESS_REQUEST, { email, clientId })
export const grantAcessSuccess = (access: Access) =>
  action(GRANT_ACCESS_SUCCESS, { access })
export const grantAccessFailure = (error: string) =>
  action(GRANT_ACCESS_FAILURE, { error })

export type GrantAccessRequestAction = ReturnType<typeof grantAccessRequest>
export type GrantAccessSuccessAction = ReturnType<typeof grantAcessSuccess>
export type GrantAccessFailureAction = ReturnType<typeof grantAccessFailure>
